/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
// TODO: address the liting  https://marley.atlassian.net/browse/HMB-5243
import _ from 'lodash';
import { v4 } from 'uuid';
import moment from 'moment';
import { handleCaseCacheItem } from '@app/cache/caseCache';
import { updateFirstUnreadMessage } from '@app/helpers/unread-message';
import { closeForm } from './ui';
import { userType, caseType } from '../models/marleyTypes';
import {
  ADD_JOB,
  API_ERROR,
  SET_CASE_SELECTOR,
  ACTIVE_JOB,
  SET_ACTIVE_CHAT,
  PINNED_JOB,
  UNPINNED_JOB,
  CLEAN_SEND_SURVEY,
  WELCOME_EMAIL_REQUEST_CLEAN,
  SET_EDIT_MODE,
  WELCOME_EMAIL_IS_SENT,
  RESEND_WELCOME_EMAIL_FOR_JOB_ERROR,
  UPDATE_CHAT_MESSAGES,
  PRESENT_MESSAGE,
  CHAT_SEND_FILE_STARTED,
  CHAT_SEND_FILE_COMPLETED,
  UPDATE_JOB,
  SNACKBAR_SHOW,
  SHOW_SEND_FILE_MODAL,
  PUT_JOB,
  FETCH_CASE_TYPES,
  UPDATE_JOB_CALLBACK,
  BULK_UPDATE_JOB,
  READ_NOTE_MENTION,
  UNSUBSCRIBE_FROM_TOPICS,
  ADD_REPLY,
  DELETE_REPLY,
  FETCH_FILTERED_OPERATOR_IDS,
  PATCH_JOB,
  PUT_JOB_NEEDS_ACTION_RESPONSE,
  UPDATE_JOB_SEARCH,
  SET_EXPAND_SEARCH_BAR,
  UPDATE_SEARCH_RESULTS_COUNT,
  UPDATE_TOTAL_CHAT_COUNT,
  REDACT_IMAGE,
  VIEW_REDACTED_IMAGE,
  EXIT_ACTIVE_WORKFLOW,
} from '../constants/actions';
import {
  SEND_SURVEY_PROXY,
  GET_CHATS,
  CREATE_JOB,
  PIN_JOB_URL,
  CREATE_NOTE,
  GET_NOTES,
  DELETE_NOTE,
  UNPIN_JOB_URL,
  GET_CHAT_MEDIA,
  WELCOME_MAIL,
  GET_JOB,
  SET_JOB_LANGUAGE,
  GET_SIGNED_URL_FOR_MEDIA_GET,
  GET_SHORT_CODE_MEDIA,
  SEND_MESSAGE_TO_CHAT,
  CREATION_SOURCE,
  PUT_JOB_ENDPOINT,
  PUT_JOB_NEEDS_ACTION_RESPONSE_ENDPOINT,
  RESEND_WELCOME_EMAIL,
  CREATE_CASE,
  GET_CASE_TYPES,
  GET_CHAT_MESSAGES_PAGE_V2,
  BULK_UPDATE_CASES_ENDPOINT,
  READ_NOTE_MENTION_ENDPOINT,
  ADD_REPLY_ENDPOINT,
  DELETE_REPLY_ENDPOINT,
  GET_FILTERED_OPERATOR_IDS,
  PATCH_JOB_ENDPOINT,
  REDACT_IMAGE_ENDPOINT,
  PATCH_LAST_VIEWED_MESSAGE,
  PATCH_MARK_CHAT_UNREAD,
  PATCH_MARK_MESSAGE_UNREAD,
  PUT_ACTIVE_WORKFLOW,
} from '../constants/endpoints';

import {
  apiCall, doRequest, validateAuthErrorAndDispatchDeauth,
} from './api';

import { apiRequest, dispatchRequest } from './api/api';
import * as apiErrors from '../constants/apierrors';
import Chat from '../models/chat';
import Job from '../models/job';
import Message from '../models/message';
import { formatDate } from '../helpers/datetime';
import { buildUrlQuery } from '../helpers/format';
import { getScheduledMessages, updateMessage } from './chat';
import { CHAT_VIEW_TOPIC } from '../constants/websocket';
import { FOCUSED_OPERATOR } from '../constants/permissions';
import { BROADCAST_CHANNEL } from '../constants/general';
import { uploadMedia } from './media';

const MESSAGE_REQUEST_LIMIT = 100;
export const UP = 'UP';
export const DOWN = 'DOWN';

export const cleanSendSurvey = () => ({ type: CLEAN_SEND_SURVEY });
export const cleanWelcomeEmailRequest = () => ({ type: WELCOME_EMAIL_REQUEST_CLEAN });

// eslint-disable-next-line no-use-before-define
export const assignUser = (data) => updateJob(data);

export const setCaseLanguage = ({ caseId, languagePreference }) => {
  const args = {
    endpoint: SET_JOB_LANGUAGE(caseId),
    method: 'PUT',
    action: 'MODIFIED_JOB_LANGUAGE',
    body: { languagePreference },
    process: ({ body }) => (body),
  };
  return apiCall({ args });
};

// export const openJob = id => ({ type: OPEN_JOB, payload: id })

export const setCaseSelector = (selectorId) => ({ type: SET_CASE_SELECTOR, payload: selectorId });

export const loadLastMessagePage = (getStore) => {
  const store = getStore();
  const { _id: organizationId } = store.auth?.user?.organization || {};
  const { id: chatId } = store?.jobs?.activeChat || {};
  if (!chatId) {
    console.warn('tried to load messages with no active chat');
    return [];
  }
  return doRequest({
    endpoint: GET_CHAT_MESSAGES_PAGE_V2(organizationId, chatId, '', UP, MESSAGE_REQUEST_LIMIT),
  }).then(
    (chatResponse) => _.map(chatResponse.chat, (message) => new Message(message)),
  );
};

export const sendSurvey = (job) => {
  const {
    referenceId, _id: jobId, type = 'case',
  } = job;
  const args = {
    endpoint: SEND_SURVEY_PROXY,
    method: 'POST',
    body: {
      jobId,
    },
    action: 'SEND_SURVEY',
    process: ({ body }) => body,
    callback: ({ dispatch }) => {
      dispatch({ type: SNACKBAR_SHOW, payload: { text: `Survey has been sent to ${type.toLowerCase()} ${referenceId}` } });
    },
    errorCallback: ({ dispatch, error }) => {
      dispatch({ type: SNACKBAR_SHOW, payload: { isError: true, text: _.get(error, 'message') } });
    },
  };

  return apiCall({ args });
};

export function newJobCreated(job, userId) {
  const payload = {
    job: new Job(job, userId),
    operatorId: userId,
  };
  return { type: ADD_JOB, payload };
}

export function createJob(customerId) {
  return (dispatch, getStore) => {
    const opts = {
      endpoint: CREATE_JOB,
      method: 'POST',
      body: { customerId },
    };
    return doRequest(opts)
      .then((body) => {
        const userId = getStore().auth.user._id;
        dispatch(newJobCreated(body, userId));
        // todo: avoid extra requests for new job creation
        // eslint-disable-next-line no-undef
        dispatch(openJob(body._id));
      })
      .catch((error) => {
        validateAuthErrorAndDispatchDeauth(error, dispatch);
        dispatch({ type: API_ERROR, payload: apiErrors.JOB_CREATE_ERROR });
      });
  };
}

export function addCase(type, template) {
  return { type: `NEW_${type}`, payload: new Job(template) };
}

export function createCase(type, data) {
  const addSource = ['POLICY', 'CLAIM', 'CASE'];
  const alteredData = { ...data };
  if (!alteredData.metaData) {
    alteredData.metaData = {};
  }
  delete alteredData.metadataReferenceId;
  delete alteredData.type;
  delete alteredData.endUserNumber;

  if (addSource.indexOf(type) > -1) alteredData.creationSource = CREATION_SOURCE;
  const args = {
    endpoint: CREATE_CASE,
    method: 'POST',
    body: alteredData,
    action: `ADD_${type}`,
    callback: ({ body, dispatch }) => {
      dispatch(closeForm(userType));
      dispatch(closeForm(caseType));
      return addCase(type, body);
    },
  };
  return apiCall({ args });
}

// used in the pollbox middleware to handle jobs live update
export function updateJob(data) {
  return (dispatch, getStore) => {
    const operatorId = getStore().auth.user._id;
    const { permissions } = getStore().auth;
    const job = new Job(data);
    return dispatch({ type: UPDATE_JOB, payload: { job, operatorId, permissions } }); // remove on final
  };
}

export const patchJob = (id, update) => apiCall({
  args: {
    endpoint: PATCH_JOB_ENDPOINT(id),
    method: 'PATCH',
    action: PATCH_JOB,
    body: update,
  },
});

export function handleJobNeedsActionResponse(jobId, chatId, responseType) {
  const args = {
    endpoint: PUT_JOB_NEEDS_ACTION_RESPONSE_ENDPOINT(jobId, chatId, responseType),
    method: 'PUT',
    action: PUT_JOB_NEEDS_ACTION_RESPONSE,
  };
  return apiCall({ args });
}

export const buildCaseUpdateBody = (job) => ({
  marleyNumber: job.marleyNumber,
  marleyNumberStatus: job.marleyNumberStatus,
  metaData: job.metaData,
  caseTypeId: job.caseTypeId,
  metadataReferenceId: job.metadataReferenceId,
  reference: _.get(job, `metaData.${job.metadataReferenceId}`),
  referenceId: _.get(job, `metaData.${job.metadataReferenceId}`),
  operatorId: _.get(job, 'operatorId.id', job.operatorId),
  customerId: _.get(job, 'customerId.id', job.customerId),
  inTranslationMode: job.inTranslationMode,
  languagePreference: job.languagePreference,
  isActive: job.isActive,
  isOpen: job.isOpen,
  secondaryOperatorIds: job.secondaryOperatorIds,
  type: job.type,
  branding: job.branding,
  privacy: _.get(job, 'privacy', 'public'),
  needsAttentionMessageCount: _.get(job, 'needsAttentionMessageCount', 0),
  isTotalLoss: job.isTotalLoss,
  releaseIssues: job.releaseIssues,
});

// used to start update (PUT) job request
export function jobPutRequest(job, isReopeningJob = null) {
  const jobId = job.id;
  const body = buildCaseUpdateBody(job);
  const args = {
    endpoint: PUT_JOB_ENDPOINT(jobId),
    method: 'PUT',
    action: PUT_JOB,
    body,
    callback: ({ body, dispatch }) => {
      dispatch(closeForm(userType));
      dispatch(closeForm(caseType));
      // eslint-disable-next-line no-use-before-define
      dispatch(updateActiveJob(body._id));
      if (typeof isReopeningJob === 'boolean') {
        dispatch({ type: SNACKBAR_SHOW, payload: { isError: false, text: `Successfully ${isReopeningJob ? 're-opened' : 'closed'} case.` } });
      }
      return { payload: new Job(body), type: UPDATE_JOB_CALLBACK };
    },
    errorCallback: ({ dispatch }) => {
      if (typeof isReopeningJob === 'boolean') {
        dispatch({ type: SNACKBAR_SHOW, payload: { isError: true, text: `Could not ${isReopeningJob ? 're-open' : 'close'} case. Please try again.` } });
      }
    },
  };
  return apiCall({ args });
}

export function jobBulkUpdateRequest(jobs, action) {
  const numCases = jobs.length;
  const body = jobs.map((job) => {
    const caseUpdateBody = buildCaseUpdateBody(job);
    caseUpdateBody._id = job._id;
    return caseUpdateBody;
  });
  const args = {
    endpoint: BULK_UPDATE_CASES_ENDPOINT,
    method: 'PUT', // may be updated to PATCH on version 2.0
    action: BULK_UPDATE_JOB,
    body,
    process: () => ({ body }),
    preApiCallback: ({ dispatch }) => {
      dispatch({ type: 'SNACKBAR_SHOW', payload: { text: `${action.preUpdateText} ${numCases} case${numCases > 1 ? 's' : ''} in progress...` } });
    },
    // eslint-disable-next-line no-shadow
    callback: ({ body, dispatch }) => {
      dispatch({ type: 'SNACKBAR_SHOW', payload: { text: `${numCases} case${numCases > 1 ? 's' : ''} ha${numCases > 1 ? 've' : 's'} been ${action.postUpdateText}` } });
      return { payload: body, type: 'UPDATE_JOB_CALLBACK' };
    },
    errorCallback: ({ dispatch, error }) => {
      dispatch({ type: SNACKBAR_SHOW, payload: { isError: true, text: _.get(error, 'message') } });
    },
  };
  return apiCall({ args });
}

const updateActiveJob = (jobId) => async (dispatch, getStore) => {
  const store = getStore();
  const activeJob = _.get(store.jobs, 'activeJob', {});
  // eslint-disable-next-line no-use-before-define
  if (activeJob._id === jobId) { dispatch(setActiveJob(jobId)); }
};

export const processJobs = (jobs, userId = false) => jobs.map((job) => new Job(job, userId));

export const clearActiveJob = (caseId) => ({ type: 'CLEAR_ACTIVE_JOB', payload: caseId });

const handleChatSocketSetup = async (socket, prevChatId, currChatId, dispatch, getStore) => {
  // nested function
  const orgId = _.get(getStore(), 'auth.user.organizationId');
  const prevChatTopic = CHAT_VIEW_TOPIC(orgId, prevChatId);

  await global.socketBuildPromise;

  if (prevChatId) {
    console.log('Unsubscribing from TOPICS: ', prevChatTopic);
    // TODO: remove if else conditions when feature flagging is over
    dispatch({ type: UNSUBSCRIBE_FROM_TOPICS, payload: { topics: [prevChatTopic] } });
  }
};

export const setActiveChat = (jobId) => async (dispatch, getStore) => {
  try {
    const job = await doRequest({ endpoint: GET_CHATS(jobId) });
    // todo: currently we pick the very first chat,
    // once we will have one to many relation, it should be changed
    const chatId = job.chatList[0]._id;
    const { organizationId } = job;
    const chatResponse = await doRequest({
      endpoint: GET_CHAT_MESSAGES_PAGE_V2(organizationId, chatId, '', UP, MESSAGE_REQUEST_LIMIT),
    });

    const store = getStore();
    const authId = _.get(store, 'auth.user._id');

    const viewedChats = _.get(store, 'jobs.viewedChats') || new Set([]);
    const activeJob = _.get(store, 'jobs.activeJob');

    const isUnreadStateRefactorEnabled = process.env.UNREAD_STATE_REFACTOR_ENABLED;
    const hasUnreadMessages = isUnreadStateRefactorEnabled ? activeJob.unreadMessageCount > 0 : !viewedChats.has(jobId);
    if (authId && hasUnreadMessages) {
      const channel = new BroadcastChannel(BROADCAST_CHANNEL);
      const chat = chatResponse?.chat;
      const lastMessage = chat[0];
      channel.postMessage({
        authId, jobId, messageId: lastMessage?._id, shouldAutoRead: true,
      });
    }

    const chatMedia = await doRequest({ endpoint: GET_CHAT_MEDIA(chatId) });
    // todo: revisit this logic

    // unsub from previous chat
    const socket = _.get(store, 'socket.socketObject');
    const previousChatId = _.get(store, 'jobs.previousActiveChat');
    handleChatSocketSetup(socket, previousChatId, chatId, dispatch, getStore);

    const recipientId = _.get(job, 'customer.id') === authId
      ? _.get(job, 'operatorIds.0') : _.get(job, 'customer.id');

    let messages = _.orderBy(chatResponse.chat, ['createdAt']);

    if (isUnreadStateRefactorEnabled) {
      let lastViewedMessageId = activeJob?.lastViewedMessage?.messageId;

      if (!lastViewedMessageId && activeJob?.usersLastViewedMessage && authId) {
        lastViewedMessageId = activeJob?.usersLastViewedMessage[authId]?.messageId;
      }

      messages = updateFirstUnreadMessage(messages, lastViewedMessageId, authId);
    }

    const chat = new Chat({
      _id: chatId,
      jobId,
      senderId: authId,
      recipientId,
      messages,
      media: chatMedia,
    });

    const type = SET_ACTIVE_CHAT;
    const { _id: operatorId } = getStore().auth.user;
    const { permissions } = getStore().auth;
    dispatch({ type, payload: { chat, operatorId, permissions } });
  } catch (error) {
    console.error(error);
    validateAuthErrorAndDispatchDeauth(error, dispatch);
    dispatch({ type: API_ERROR, payload: 'Setting Active Chat Error' });
  }
};

export const setActiveJob = (jobId) => async (dispatch, getStore) => {
  try {
    const activeJobArgs = {
      endpoint: GET_JOB(jobId),
      action: ACTIVE_JOB,
      process: ({ body }) => {
        const authId = getStore().auth?.user?._id;
        const permissions = getStore().auth?.permissions || [];
        const { job } = body || {};
        const { operatorId, secondaryOperatorIds } = job || {};
        const opId = typeof operatorId === 'string' ? operatorId : operatorId?._id;
        const isOnJob = opId === authId || secondaryOperatorIds.includes(authId);
        const isFocusedOperator = permissions.includes(FOCUSED_OPERATOR);
        const hasAccessToJob = isOnJob || !isFocusedOperator;
        // ultimately this logic should move to the backend - then the route would return
        // a 403 FORBIDDEN response that would result in the same update to the store
        if (!hasAccessToJob) {
          throw new Error({
            name: 'Forbidden',
          });
        }
        dispatch(setActiveChat(jobId));
        return body.job && new Job(body.job);
      },
    };

    dispatch(apiCall({ args: activeJobArgs }));
  } catch (error) {
    validateAuthErrorAndDispatchDeauth(error, dispatch);
    dispatch({ type: API_ERROR, payload: apiErrors.JOB_GET_ERROR });
  }
};

export function pinJob(jobId) {
  return (dispatch) => {
    const opts = {
      endpoint: PIN_JOB_URL(jobId),
      method: 'PUT',
    };
    return doRequest(opts)
      .then(() => {
        dispatch({ type: PINNED_JOB, payload: jobId });
      })
      .catch((error) => {
        validateAuthErrorAndDispatchDeauth(error, dispatch);
        dispatch({ type: API_ERROR, payload: apiErrors.JOB_GET_ERROR });
      });
  };
}

export function unpinJob(jobId) {
  return (dispatch) => {
    const opts = {
      endpoint: UNPIN_JOB_URL(jobId),
      method: 'PUT',
    };
    return doRequest(opts)
      .then(() => {
        dispatch({ type: UNPINNED_JOB, payload: jobId });
      })
      .catch((error) => {
        validateAuthErrorAndDispatchDeauth(error, dispatch);
        dispatch({ type: API_ERROR, payload: apiErrors.JOB_GET_ERROR });
      });
  };
}

export function sendWelcomeMail(id, type) {
  const body = { origin: 'web-app' };
  body[`${type}Id`] = id;
  const args = {
    endpoint: RESEND_WELCOME_EMAIL(type),
    method: 'POST',
    process: (t) => t,
    body,
    action: 'SEND_WELCOME_EMAIL',
  };
  return apiCall({ args });
}

export function setEditMode() {
  return { type: SET_EDIT_MODE };
}

export function resendWelcomeMail(data) {
  return (dispatch) => {
    const opts = {
      endpoint: WELCOME_MAIL,
      method: 'POST',
      body: data,
    };
    return doRequest(opts)
      .then(() => {
        dispatch({ type: WELCOME_EMAIL_IS_SENT });
      })
      .catch((error) => {
        validateAuthErrorAndDispatchDeauth(error, dispatch);
        dispatch({ type: RESEND_WELCOME_EMAIL_FOR_JOB_ERROR, payload: apiErrors.SEND_WELCOME_MESSAGE_ERROR });
      });
  };
}

export const getNotes = (caseId) => {
  const args = {
    endpoint: GET_NOTES(caseId),
    method: 'GET',
    action: 'LOAD_NOTES',
    process: ({ body }) => body,
  };

  return apiCall({ args });
};

export const readNoteMention = (caseId, userId, noteId, replyId = null) => {
  const body = {
    userId,
    replyId,
  };
  const args = {
    endpoint: READ_NOTE_MENTION_ENDPOINT(caseId, noteId),
    method: 'DELETE',
    body,
    process: ({ body }) => body,
    callback: ({ dispatch }) => {
      dispatch({
        type: READ_NOTE_MENTION,
        payload: { userId, noteId, replyId },
      });
    },
  };

  return apiCall({ args });
};

export const deleteNote = (ids) => {
  const { chatId } = ids;
  const body = {
    chatId,
  };
  const args = {
    endpoint: DELETE_NOTE(ids),
    method: 'PUT',
    action: 'DELETE_NOTE',
    body,
    process: ({ body }) => body,
  };

  return apiCall({ args });
};

export const createNote = ({
  caseId, noteText, unreadNoteMentions, plainText,
}) => {
  const body = {
    caseId,
    noteText,
    creationSource: CREATION_SOURCE,
    unreadNoteMentions,
    plainText,
  };

  const args = {
    endpoint: CREATE_NOTE(caseId),
    method: 'POST',
    action: 'CREATE_NOTE',
    body,
    process: ({ body }) => body,
  };

  return apiCall({ args });
};

export const addReply = ({
  caseId,
  noteId,
  replyText,
  unreadNoteMentions,
  plainText,
}) => {
  const body = {
    caseId,
    noteId,
    replyText,
    unreadNoteMentions,
    plainText,
  };

  const args = {
    endpoint: ADD_REPLY_ENDPOINT(caseId, noteId),
    method: 'POST',
    action: ADD_REPLY,
    body,
    process: ({ body }) => body,
  };

  return apiCall({ args });
};

export const deleteReply = (ids) => {
  const { chatId } = ids;
  const body = {
    chatId,
  };
  const args = {
    endpoint: DELETE_REPLY_ENDPOINT(ids),
    method: 'PUT',
    action: DELETE_REPLY,
    body,
    process: ({ body }) => body,
  };

  return apiCall({ args });
};

export const queueMessage = (args) => dispatchRequest(
  apiRequest.create.message.queue(args),
  {
    type: 'CREATE_SCHEDULED_MESSAGE',
    successCallback: () => (dispatch) => {
      dispatch(getScheduledMessages(args.chatId));
      dispatch({
        type: 'SNACKBAR_SHOW',
        payload: {
          text: `Message scheduled for ${formatDate(Date.parse(new Date(args.eventTime)), 'date-time')}`,
        },
      });
    },
  },
);

export const sendMessage = ({
  chatId, jobId, message,
}) => async (dispatch, getStore) => {
  const data = {
    chatId,
    jobId,
    messageBody: message,
    messageId: v4(),
    creationSource: CREATION_SOURCE,
  };
  const thisActiveChat = getStore().jobs.activeChat;
  const thisActiveJob = getStore().jobs.activeJob;
  const customer = _.get(thisActiveJob, 'customer', {});
  const userLanguagePreference = _.get(customer, 'languagePreference', 'en');
  const userInTranslationMode = _.get(customer, 'inTranslationMode', false);
  const jobLanguagePreference = _.get(thisActiveJob, 'languagePreference', 'en');
  const jobInTranslationMode = _.get(thisActiveJob, 'inTranslationMode', false);
  const languagePreference = (jobInTranslationMode && jobLanguagePreference !== null)
    ? jobLanguagePreference : userLanguagePreference;
  const inTranslationMode = (jobInTranslationMode || userInTranslationMode) && (languagePreference !== 'en');

  const opts = {
    endpoint: SEND_MESSAGE_TO_CHAT(chatId),
    method: 'POST',
    body: data,
  };
  // Don't want to await doRequest so that the UI updates with the operator message instantly
  doRequest(opts).then(
    (msgResponse) => dispatch(updateMessage({ newMessage: msgResponse })),
  );
  data.isInbound = false;
  data.createdAt = moment().toISOString();
  data._id = data.messageId;
  data.body = data.messageBody;
  if (!thisActiveChat.isLastMessageInLog) {
    const messages = await loadLastMessagePage(getStore);
    dispatch({ type: UPDATE_CHAT_MESSAGES, payload: { messages } });
  } else {
    data.author = getStore().auth.user;
    if (inTranslationMode) {
      data.additionalInfo = {
        translatedMessage: 'Translating...',
      };
    }
    dispatch({
      type: PRESENT_MESSAGE,
      payload: {
        message: { ...new Message(data), bodyPrefix: `${data?.author?.firstName} ${data?.author?.lastName[0]}.` },
      },
    });
  }
};

export const VALID_MESSAGING_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/gif',
  'video/mp4',
  'audio/mp4',
  'text/vcard',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
];

export const MESSAGING_FILE_MEGABYTE_LIMIT = 25;

// Wraps uploadMedia and deals with getting the link to provide in an outbound media message
export const uploadFile = (file, toast, toastIdRef) => async (dispatch) => {
  try {
    const { key } = await uploadMedia(file, VALID_MESSAGING_FILE_TYPES, MESSAGING_FILE_MEGABYTE_LIMIT) || {};
    if (!key) {
      throw new Error('No object key to build upload link with');
    }
    return { key };
  } catch (e) {
    const error = {
      title: 'File Not Allowed',
      description: 'For more information on the files we support, please visit the help doc: https://himarley.zendesk.com/hc/en-us/articles/360050427254-Outbound-Media',
      status: 'error',
      duration: 5000,
    };
    if (e?.message === 'EntityTooLarge') {
      error.title = 'File Too Large';
      error.description = `File must be under ${MESSAGING_FILE_MEGABYTE_LIMIT} MB.`;
    } else if (e?.message === 'Invalid file type') {
      error.title = 'Format Not Allowed';
      error.description = 'Files must be JPEG/JPG, PNG, GIF, MP4, VCARD, PDF, DOCX, or XLSX formats.';
    }
    if (toastIdRef?.current) {
      toast.update(toastIdRef.current, error);
    }
    dispatch({ type: CHAT_SEND_FILE_COMPLETED });
    console?.error(e);
  }
};

export const setChatSendFileCompleted = () => (dispatch) => {
  dispatch({ type: CHAT_SEND_FILE_COMPLETED });
};

export const sendFile = (key, file, toast, toastIdRef) => async (dispatch) => {
  dispatch({ type: CHAT_SEND_FILE_STARTED });
  try {
    if (!key) {
      throw new Error('No object key to build upload link with');
    }
    // get short code for upload to send to customer in message
    const signedGETUrl = await doRequest({
      endpoint: GET_SIGNED_URL_FOR_MEDIA_GET(key),
      method: 'GET',
    });
    if (signedGETUrl?.virusScanStatus === 'VIRUS_SCAN_QUEUED') {
      return null;
    }
    // construct final link and put in message
    if (!signedGETUrl?.shortCode) throw new Error('No shortcode on response');
    const finalLink = `${window.location.origin}/${GET_SHORT_CODE_MEDIA(signedGETUrl.shortCode)}`;
    dispatch({ type: CHAT_SEND_FILE_COMPLETED, payload: `Link to file ${file.name}: ${finalLink}` });

    if (toastIdRef?.current) {
      toast.update(toastIdRef.current, {
        description: 'Upload 100% · File Ready to Send',
        status: 'success',
        duration: 5000,
      });
    }
  } catch (e) {
    console?.error(e);

    if (e?.virusScanStatus === 'VIRUS_SCAN_INFECTED') {
      if (toastIdRef?.current) {
        toast.update(toastIdRef.current, {
          title: 'File Blocked',
          description: 'Our system detected a virus and blocked the file.',
          status: 'error',
          duration: 5000,
        });
      }
    } else if (toastIdRef?.current) {
      toast.update(toastIdRef.current, {
        title: 'File Send Failed',
        description: 'Failed to send file. Please try again.',
        status: 'error',
        duration: 5000,
      });
    }
    dispatch({ type: CHAT_SEND_FILE_COMPLETED });
  }
};

export const showSendFileModal = (show, file) => ({
  type: SHOW_SEND_FILE_MODAL,
  payload: {
    show,
    file,
  },
});

export function fetchCaseTypes() {
  const args = {
    endpoint: GET_CASE_TYPES,
    method: 'GET',
    process: (t) => t,
    action: FETCH_CASE_TYPES,
  };
  return apiCall({ args });
}

export const getFilteredOperatorIds = (data) => {
  const query = buildUrlQuery(data);
  const endPoint = `${GET_FILTERED_OPERATOR_IDS}?${query}`;
  const args = {
    endpoint: endPoint,
    method: 'GET',
    process: (t) => t,
    action: FETCH_FILTERED_OPERATOR_IDS,
  };
  return apiCall({ args });
};

export const updateSearch = ({ search, searchDisplayValue }) => (dispatch) => {
  dispatch({
    type: UPDATE_JOB_SEARCH,
    payload: {
      search,
      searchDisplayValue,
    },
  });
};

export const setExpandInboxSearchBar = (value) => (dispatch) => {
  dispatch({
    type: SET_EXPAND_SEARCH_BAR,
    payload: {
      expandInboxSearchBar: value,
    },
  });
};

export const updateSearchResultsCount = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_SEARCH_RESULTS_COUNT,
    payload: {
      searchResultsCount: value,
    },
  });
};

export const updateTotalChatCount = (value) => (dispatch) => {
  dispatch({
    type: UPDATE_TOTAL_CHAT_COUNT,
    payload: {
      totalChatCount: value,
    },
  });
};

export function redactImage(messageId, key, fileUrl, redactionData) {
  const args = {
    endpoint: REDACT_IMAGE_ENDPOINT(key),
    method: 'PUT',
    body: { messageId },
    process: (t) => t,
    action: '',
    callback: ({ dispatch }) => {
      dispatch({ type: REDACT_IMAGE, payload: { fileUrl, messageId, redactionData } });
      dispatch({ type: SNACKBAR_SHOW, payload: { text: 'Successfully redacted image.' } });
    },
    errorCallback: ({ dispatch }) => {
      dispatch({ type: SNACKBAR_SHOW, payload: { isError: true, text: 'Error redacting image. Please try again.' } });
    },
  };
  return apiCall({ args });
}

export const viewRedactedImage = (messageId, authUser) => (dispatch) => {
  dispatch({ type: VIEW_REDACTED_IMAGE, payload: { messageId, authUser } });
};

export const updateLastViewedMessage = (queryClient, jobId, messageId) => (dispatch, getStore) => {
  let viewedMessageId = messageId;
  if (!messageId) {
    const messages = getStore()?.jobs?.activeChat?.messages;
    viewedMessageId = messages[messages.length - 1]?.id;
  }

  const { _id: currentUserId } = getStore()?.auth?.user || {};
  const { _id: activeJobId } = getStore()?.jobs?.activeJob || {};
  if (activeJobId === jobId) {
    const args = {
      endpoint: PATCH_LAST_VIEWED_MESSAGE(jobId),
      method: 'PATCH',
      action: 'UPDATE_LAST_VIEWED_MESSAGE',
      body: { messageId: viewedMessageId },
      process: (t) => t,
      callback: ({ body }) => {
        const store = getStore();
        dispatch({ type: 'MARK_CHAT_READ', payload: { chatId: jobId } });
        dispatch({ type: 'UPDATE_UNREAD_MESSAGES', payload: { jobId, messageId: viewedMessageId, currentUserId } });
        handleCaseCacheItem(body, 'read-chat', queryClient, store);
      },
      errorCallback: () => {
        dispatch({ type: SNACKBAR_SHOW, payload: { isError: true, text: 'Error marking chat read. Please try again.' } });
      },
    };
    return apiCall({ args })(dispatch, getStore);
  }
  return null;
};

/**
 * Similar to updateLastViewedMessage, but calculates the message id of the last viewed message when the id
 * for the message to mark as read is provided.
 */
export const markMessageUnread = (queryClient, jobId, messageId) => (dispatch, getStore) => {
  const args = {
    endpoint: PATCH_MARK_MESSAGE_UNREAD(jobId),
    method: 'PATCH',
    action: 'MARK_MESSAGE_UNREAD',
    body: { messageId },
    process: (t) => t,
    callback: ({ body }) => {
      const store = getStore();
      const lastViewedMessageId = body?.usersLastViewedMessage?.[store?.auth?.user?._id]?.messageId;
      dispatch({ type: 'UPDATE_UNREAD_MESSAGES', payload: { jobId, messageId: lastViewedMessageId } });
      handleCaseCacheItem(body, 'mark-message-unread', queryClient, store);
    },
    errorCallback: () => {
      dispatch({ type: SNACKBAR_SHOW, payload: { isError: true, text: 'Mark Unread isn’t supported for automated messages.' } });
    },
  };
  return apiCall({ args })(dispatch, getStore);
};

/**
 * Similar to markMessageUnread, but calculates the last applicable message to mark as read on a chat
 * and sets the last viewed message accordingly
 */
export const markChatUnread = (queryClient, jobId) => (dispatch, getStore) => {
  const args = {
    endpoint: PATCH_MARK_CHAT_UNREAD(jobId),
    method: 'PATCH',
    action: 'MARK_LATEST_APPLICABLE_MESSAGE_ON_CHAT_UNREAD',
    process: (t) => t,
    callback: ({ body }) => {
      const store = getStore();
      const lastViewedMessageId = body?.usersLastViewedMessage?.[store?.auth?.user?._id]?.messageId;
      dispatch({ type: 'UPDATE_UNREAD_MESSAGES', payload: { jobId, messageId: lastViewedMessageId } });
      handleCaseCacheItem(body, 'mark-chat-unread', queryClient, store);
    },
    errorCallback: () => {
      dispatch({ type: SNACKBAR_SHOW, payload: { isError: true, text: 'Mark Unread isn’t supported for automated messages.' } });
    },
  };
  return apiCall({ args })(dispatch, getStore);
};

export const endActiveWorkflow = ({ jobId }) => (dispatch, getStore) => {
  const args = {
    endpoint: PUT_ACTIVE_WORKFLOW(jobId),
    method: 'PUT',
    action: EXIT_ACTIVE_WORKFLOW,
    body: { activeWorkflow: null },
    callback: ({ body }) => {
      const store = getStore();
      const activeCaseId = body?._id;
      const currentActiveJobId = store?.jobs?.activeJob?._id;
      if (activeCaseId === currentActiveJobId) {
        updateJob(body)(dispatch, getStore);
      }
    },
    errorCallback: ({ error }) => {
      validateAuthErrorAndDispatchDeauth(error, dispatch);
    },
  };
  return apiCall({ args })(dispatch, getStore);
};
