import { extendTheme } from '@chakra-ui/react';

import { alertTheme } from './alert';
import { badgeTheme } from './badge';
import { buttonTheme } from './button';
import { checkboxTheme } from './checkbox';
import { colors } from './colors';
import { formLabelTheme } from './form-label';
import { headingTheme } from './heading';
import { iconTheme } from './icon';
import { inputTheme } from './input';
import { linkTheme } from './link';
import { selectTheme } from './select';
import { spinnerTheme } from './spinner';
import { textTheme } from './text';

export const theme = extendTheme({
  colors,
  components: {
    Alert: alertTheme,
    Badge: badgeTheme,
    Button: buttonTheme,
    Checkbox: checkboxTheme,
    FormLabel: formLabelTheme,
    Heading: headingTheme,
    Icon: iconTheme,
    Input: inputTheme,
    Select: selectTheme,
    Spinner: spinnerTheme,
    Text: textTheme,
    Link: linkTheme,
  },
  bodyText: {
    color: 'gray.900',
  },
  fonts: {
    body: '"Poppins", "Helvetica", sans-serif',
    heading: '"Poppins", "Helvetica", sans-serif',
  },
  styles: {
    global: () => ({
      body: {
        color: 'gray.900',
      },
      button: {
        'padding-inline-end': '6px',
        'padding-inline-start': '6px',
      },
      ':where(img, svg, video, canvas, audio, iframe, embed, object)': {
        display: 'unset',
      },
    }),
  },
});

export default theme;
