import {
  LockIcon,
  UnlockIcon,
  FolderOpenIcon,
  FilesBoxIcon,
  SearchIcon,
  ChatDotsIcon,
  DocumentIcon,
  CheckCircleIcon,
  CheckmarksIcon,
  UserIcon,
} from '@himarley/unity';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearActiveJob,
  sendSurvey,
  cleanSendSurvey,
  jobPutRequest,
} from '@app/actions/job';
import completeReleaseIssues from '@app/actions/job-ts';
import { toggleConfirmation } from '@app/actions/ui';
import {
  setReleaseIssueModalOpen,
  setReleaseIssueModalError,
} from '@app/actions/ui-ts';
import { updateEndUserOptStatus } from '@app/actions/users';
import AlertDialog, { useAlertDialog } from '@app/components/chakra/alert-dialog';
import Menu from '@app/components/chakra/menu';
import { DROPDOWN_LABEL_MARK_ISSUES_COMPLETE } from '@app/constants/integration-constants';
import { userOptInStatus, OptInStatus } from '@app/constants/opt-in-status';
import { FEATURE_FLAG_TOTAL_LOSS_ASSIST, REDACTION_VIEW_ACCESS } from '@app/constants/permissions';
import { lookNumberConfig, getLookerEnvironment } from '@app/helpers/analytics';
import { isReleaseIssueOpen } from '@app/helpers/network-partners-utils';
import { localToRemoteUrl } from '@app/helpers/urls';
import { StateType } from '@app/types/reducer-state';

import SurveyModal from './SurveyModal/survey-modal';
import ReleaseIssuesModal from '../../Form/ReleaseIssues/ReleaseIssuesModal';
import LargeLookContent from '../../LargeLookContent/large-look-content';
import chatModuleSelector from '../Chat/selector';

const ChatActionsMenu = () => {
  const {
    releaseIssues = [],
    isReleaseIssueModalOpen = false,
    selectedReleaseIssue = '',
    jobId,
    chatPdfLink,
    assignedOperator,
    isClosed,
    customerPdfLink,
    job,
    isOpenIssuesRemaining,
    lockingUserProgress = {},
  } = useSelector(chatModuleSelector);
  const { recipient, permissions } = useSelector((state: StateType) => ({
    recipient: state?.jobs?.activeJob?.customer || {},
    permissions: state?.auth?.permissions || [],
  }));
  const recipientId = recipient?.id || recipient?._id;
  const isUpdatingLockStatus = recipientId
    ? lockingUserProgress[recipientId] === true
    : false;
  const filteredReleaseIssues = useMemo(
    () => releaseIssues.filter((issue: unknown) => isReleaseIssueOpen(issue)),
    [releaseIssues],
  );
  const dispatch = useDispatch();
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [showLargeLook, setShowLargeLook] = useState(false);
  const { isDialogOpen: isLockoutDialogOpen, toggleDialog: toggleLockoutDialog } = useAlertDialog();

  type ThunkAppDispatch = ThunkDispatch<unknown, void, Action>;

  const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();

  const handleToggleOpenCase = useCallback(() => {
    const theJob = { ...job, isOpen: isClosed };
    const isReopeningJob = isClosed;
    dispatch(jobPutRequest(theJob, isReopeningJob));
    if (!isClosed) {
      dispatch(clearActiveJob(job.id));
      window.history.pushState(null, '', localToRemoteUrl('/chats'));
    }
  }, [dispatch, job, isClosed]);

  const closeSendSurvey = () => {
    dispatch(cleanSendSurvey());
    setShowSurveyModal(false);
  };

  const toggleUserLocked = useCallback(
    (locked: boolean) => {
      dispatch(
        updateEndUserOptStatus({
          _id: recipient?.id || recipient?._id,
          jobId,
          status: locked ? OptInStatus.LockedOut : OptInStatus.OptedIn,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          isUpdatingLockStatus: true,
        }),
      );
      if (isLockoutDialogOpen) {
        toggleLockoutDialog();
      }
    },
    [
      dispatch,
      isLockoutDialogOpen,
      jobId,
      recipient?._id,
      recipient?.id,
      toggleLockoutDialog,
    ],
  );

  const operatorHasAuditLogAccess = useMemo(
    () => permissions.includes('AUDIT_LOG'),
    [permissions],
  );
  const operatorHasUnredactedAccess = useMemo(
    () => permissions.includes(REDACTION_VIEW_ACCESS),
    [permissions],
  );
  const isTotalLossEnabled = useMemo(
    () => permissions.includes(FEATURE_FLAG_TOTAL_LOSS_ASSIST),
    [permissions],
  );
  const isTwoWayEnabled = process.env.COPART_TWO_WAY_ENABLED;
  const shouldShowMarkIssuesComplete = isTotalLossEnabled && isTwoWayEnabled;
  const isRedactionOrgEnabled = useMemo(
    () => permissions.includes('FEATURE_FLAG_UI_REDACTION'),
    [permissions],
  );
  const recipientStatus = {
    blocked: recipient?.blocked ?? false,
    locked: recipient?.locked ?? false,
    verified: recipient?.verified ?? false,
  };
  const isPendingOptIn = userOptInStatus(recipientStatus) === OptInStatus.PendingOptIn;
  const menuItems = useMemo(() => {
    const caseAction = [
      {
        id: `${!isClosed ? 'chatMenu-closecase' : 'chatMenu-reopencase'}`,
        label: !isClosed ? 'Close Case' : 'Re-open Case',
        leftIcon: !isClosed ? FilesBoxIcon : FolderOpenIcon,
        onClick: () => handleToggleOpenCase(),
      },
    ];
    const auditLogButtons = [
      {
        id: 'chatMenu-auditLog',
        leftIcon: SearchIcon,
        label: 'Audit Log',
        onClick: () => setShowLargeLook(true),
      },
    ];
    const unredactedTrascriptButtons = [
      {
        id: 'chatMenu-casetrans',
        leftIcon: DocumentIcon,
        label: 'Case Transcript',
        onClick: () => window.open(localToRemoteUrl(chatPdfLink)),
      },
      {
        id: 'chatMenu-usertrans',
        leftIcon: UserIcon,
        label: 'Customer Transcript',
        onClick: () => window.open(localToRemoteUrl(customerPdfLink)),
      },
    ];
    const redactionActions = [
      {
        id: 'chatMenu-casetrans-redacted',
        leftIcon: LockIcon,
        label: 'Redacted Case Transcript',
        onClick: () => window.open(localToRemoteUrl(`${chatPdfLink}&redacted=true`)),
      },
      {
        id: 'chatMenu-usertrans-redacted',
        label: 'Redacted Customer Transcript',
        leftIcon: LockIcon,
        onClick: () => window.open(localToRemoteUrl(`${customerPdfLink}&redacted=true`)),
      },
    ];
    const copartActions = [
      {
        id: 'chatMenu-resolve-release-issues',
        label: DROPDOWN_LABEL_MARK_ISSUES_COMPLETE,
        leftIcon: CheckCircleIcon,
        onClick: () => dispatch(setReleaseIssueModalOpen({ isOpen: true })),
      },
    ];
    const resendWelcomeAction = [
      {
        id: 'chatMenu-resendWelcome',
        leftIcon: ChatDotsIcon,
        label: 'Resend Opt-In Text',
        onClick: () => dispatch(
          toggleConfirmation({
            id: 'resendwelcome',
            isOpen: true,
            selectedId: jobId,
          }),
        ),
      },
    ];
    const sendSurveyAction = [
      {
        id: 'chatMenu-sendSurvey',
        leftIcon: CheckmarksIcon,
        label: 'Send Survey',
        onClick: () => setShowSurveyModal(true),
      },
    ];
    const lockUnlockAction = [
      {
        id: `${
          recipient?.locked
            ? 'chatMenu-unlockCustomer'
            : 'chatMenu-lockCustomer'
        }`,
        leftIcon: recipient?.locked ? UnlockIcon : LockIcon,
        label: `${recipient?.locked ? 'Unlock Customer' : 'Lock Out Customer'}`,
        onClick: () => {
          if (recipient?.locked) {
            toggleUserLocked(false);
          } else {
            toggleLockoutDialog();
          }
        },
        isDisabled:
          isUpdatingLockStatus || (!recipient?.locked && isPendingOptIn),
      },
    ];
    return [
      ...(!isRedactionOrgEnabled || operatorHasUnredactedAccess
        ? unredactedTrascriptButtons
        : []),
      ...(isRedactionOrgEnabled ? redactionActions : []),
      ...(operatorHasAuditLogAccess ? auditLogButtons : []),
      ...(isOpenIssuesRemaining && shouldShowMarkIssuesComplete
        ? copartActions
        : []),
      ...(!recipient.verified ? resendWelcomeAction : []),
      ...(recipient.verified && assignedOperator ? sendSurveyAction : []),
      ...lockUnlockAction,
      ...caseAction,
    ];
  }, [
    isClosed,
    recipient?.locked,
    recipient.verified,
    isUpdatingLockStatus,
    isPendingOptIn,
    isRedactionOrgEnabled,
    operatorHasUnredactedAccess,
    operatorHasAuditLogAccess,
    isOpenIssuesRemaining,
    shouldShowMarkIssuesComplete,
    assignedOperator,
    handleToggleOpenCase,
    chatPdfLink,
    customerPdfLink,
    dispatch,
    jobId,
    toggleUserLocked,
    toggleLockoutDialog,
  ]);

  const lookNumber = lookNumberConfig[getLookerEnvironment()].auditLog;
  const appThunkDispatch = useAppThunkDispatch();

  const handleSubmitReleaseIssues = async (data: unknown) => {
    try {
      await appThunkDispatch(completeReleaseIssues({ jobId, data })).unwrap();
      dispatch(setReleaseIssueModalOpen({ isOpen: !isReleaseIssueModalOpen }));
    } catch (error) {
      dispatch(setReleaseIssueModalError());
    }
  };

  return (
    <div className="chat-component-header">
      {showLargeLook && (
        <LargeLookContent
          caseId={jobId}
          closeLook={() => setShowLargeLook(false)}
          lookNumber={lookNumber}
        />
      )}
      <SurveyModal
        closeSendSurvey={() => closeSendSurvey()}
        sendSurvey={() => dispatch(sendSurvey(job))}
        isAssignedJob={!!assignedOperator}
        showSurvey={showSurveyModal}
      />
      <ReleaseIssuesModal
        show={isReleaseIssueModalOpen}
        setShow={() => dispatch(
          setReleaseIssueModalOpen({ isOpen: !isReleaseIssueModalOpen }),
        )}
        releaseIssueData={filteredReleaseIssues}
        onSubmitReleaseIssues={handleSubmitReleaseIssues}
        selectedReleaseIssue={selectedReleaseIssue}
      />
      {isLockoutDialogOpen && (
        <AlertDialog
          id="lockout-customer"
          data-test="lockout-customer-dialog"
          title="Confirm Lock Out"
          isDialogOpen={isLockoutDialogOpen}
          toggleDialog={toggleLockoutDialog}
          cancelText="Cancel"
          confirmText="Lock Out Customer"
          onConfirm={() => toggleUserLocked(true)}
          confirmColor="red"
        >
          Locking out a customer will block them from sending messages in all cases.
        </AlertDialog>
      )}
      <Menu options={menuItems} defaultLabel="Actions" id="actions-menu" />
    </div>
  );
};

export default ChatActionsMenu;
