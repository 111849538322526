import { Alert, AlertDescription } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { getBrowserCheckCookie, saveBrowserCheckCookie } from '@app/helpers/cookies';

import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import ErrorSplashScreen from './ErrorBoundary/ErrorSplashScreen';
import { Routes } from './Routes/Routes';

const IEWarning = () => (
  <Alert data-test="ie-warning" justifyContent="center" status="info">
    <AlertDescription>
      Hey there, it looks like you&apos;re using an unsupported browser, please visit
      {' '}
      <a href="https://chat.marley.ai">chat.marley.ai</a>
      {' '}
      using your Chrome browser.
    </AlertDescription>
  </Alert>
);

const Root = () => {
  const [isChrome, setIsChrome] = useState(getBrowserCheckCookie() || (!!(
    window as unknown as { chrome: boolean }
  ).chrome));

  useEffect(() => {
    saveBrowserCheckCookie(true);
    if (isChrome) setIsChrome(true);
  }, [isChrome]);

  const ua = window.navigator.userAgent;
  const isIE = ua.indexOf('MSIE') > -1 || ua.indexOf('Trident') > -1;

  return (
    <div className="root">
      <ErrorBoundary errorMessage={<ErrorSplashScreen />}>
        <BrowserRouter>
          {isIE ? <IEWarning /> : <Routes />}
        </BrowserRouter>
      </ErrorBoundary>
    </div>
  );
};

export default Root;
