/* This is a custom hook that returns the styles for the persistent scrollbar */

export const useCustomStyles = () => {
  const scrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: 2,
      borderRadius: 'md',
      backgroundColor: 'white',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'gray.200',
      borderRadius: 'md',
      _hover: {
        backgroundColor: 'gray.300',
      },
    },
  };

  const formControlStyles = {
    '&:hover .chakra-menu__menu-button': {
      borderColor: 'gray.300',
    },
  };

  const menuButtonStyles = {
    borderColor: 'gray.200',
    _hover: {
      bg: 'none',
      cursor: 'text',
    },
    _active: {
      bg: 'none',
      cursor: 'text',
      borderColor: 'blue.500 !important',
      borderWidth: '2px !important',
    },
  };

  return { scrollbarStyles, formControlStyles, menuButtonStyles };
};

export default useCustomStyles;
