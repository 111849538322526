/* eslint-disable max-len */
export const formatInit = (action) => `REQUEST_${action}_INIT`;
export const formatSuccess = (action) => `REQUEST_${action}_SUCCESS`;
export const formatError = (action) => `REQUEST_${action}_ERROR`;

export const CREATION_SOURCE = 'WebApp';

const GET_MAP = {
  CLAIM: 'claim',
  POLICY: 'policy',
  USER: 'user/userlist',
  OPERATOR: 'user/userlist?role=operator',
  CASE: 'job/joblist',
  GROUP: 'groups',
  QUEUE: 'queue',
  TEMPLATE: 'msgtemplate',
  SUBGROUP: 'groups',
};
export const GET_ITEMS = (type, query) => {
  let baseUrl = `/api/${GET_MAP[type]}`;
  if (query) {
    baseUrl += (baseUrl.indexOf('?')) > -1 ? '&' : '?';
    baseUrl += Object.keys(query).map((p) => (Array.isArray(query[p]) ? `${p}[]=${query[p].join(`&${p}[]=`)}`
      : `${encodeURIComponent(p)}=${encodeURIComponent(query[p])}`)).join('&');
  }
  return baseUrl;
};

const POST_MAP = {
  TEMPLATE: 'msgtemplate',
  POLICY: 'policy',
  CLAIM: 'claim',
  CASE: 'job',
  USER: 'user/create-user',
  GROUP: 'groups',
  QUEUE: 'queue',
};

// Generic
export const CREATE_ITEM = (type) => `/api/${POST_MAP[type]}`;
export const UPDATE_ITEM = (type) => `/api/${POST_MAP[type]}`;
export const CHECK_USER = (phone) => `/api/profile/verifyphonenumber/${phone}`;

// Other
export const SEARCH_JOBS = '/api/search';
export const DESCRIBE_SERVICE = (serviceName) => `/api/describeService/${serviceName}`;
export const REOPEN_METRIC = '/dev/case';
export const CLOSE_METRIC = '/dev/case';

// Health Check
export const HEALTH_CHECK_ENDPOINT = '/api/health';

// Authentication
export const GET_USER_BY_EMAIL_ENDPOINT = (email) => `/api/user/email/${email}`;
export const GET_USER_BY_OKTA_USER_ID_ENDPOINT = (oktaUserId) => `/api/user/oktaUserId/${oktaUserId}`;

export const CHANGE_PASSWORD_ENDPOINT = '/api/auth/changepassword';
export const RESET_PASSWORD_ENDPOINT = '/api/auth/resetpassword';

// User
export const GENERIC_USER_GET = '/api/user/genericGet';
export const GENERIC_USER_SET = '/api/user/genericSet';
export const DELETE_USER = (userId) => `/api/user/${userId}`;
export const GET_USER_PROPERTIES = '/api/user/userproperties';
export const GET_USERS = '/api/user/userlist';
export const UPDATE_USER = '/api/user';
export const CREATE_USER = '/api/user/create-user';
export const CREATE_USERS = '/api/user/create-users';
export const UPDATE_OPERATORS_UNIQUE_IDS = '/api/user/update-operators-unique-ids';
export const USER_PROPERTIES = '/api/user/userproperties';
export const GET_PINNED_JOBS = '/api/user/pinnedjobs';
export const PIN_JOB_URL = (id) => `/api/user/pinjob/${id}`;
export const UNPIN_JOB_URL = (id) => `/api/user/unpinjob/${id}`;
export const MARK_OPERATOR_ACTIVE = '/api/user/set-operator-active';
export const GET_USER_LANGUAGE = (userId) => `/api/user/languagepreference/${userId}`;
export const GET_OPERATORS_BY_ORG = (orgId) => `/api/user/operators/${orgId}`;
export const PUT_AUTO_ASSIGN = (oktaUserId) => `/api/user/${oktaUserId}/isAutoAssign`;
export const GET_BULK_UPLOAD_USERS_CSV_TEMPLATE = '/api/user/csv-template';
export const GET_CHANGE_UNIQUE_IDS_CSV_TEMPLATE = '/api/user/change-unique-ids-template';
export const GET_USERS_TO_EXPORT = '/api/user/users-to-export';
export const GET_LIST_OF_TITLES = '/api/user/titlelist';
export const GET_OPERATORS_INFO_BY_ID = '/api/user/operators-info';
export const GET_ACTIVE_OPERATORS_COUNT = '/api/user/user-count';

// End Users
export const CREATE_END_USER = '/api/endusers';
export const UPDATE_END_USER_OPT_STATUS = (endUserId) => `/api/endusers/${endUserId}/opt-in`;
export const QUERY_END_USERS = '/api/endusers/query';

// Jobs
export const CREATE_CASE = '/api/job';
export const COMPLETE_RELEASE_ISSUES = (jobId) => `/api/job/${jobId}/events`;
export const GET_CUSTOMER_FEEDBACK = (userId) => `/api/job/${encodeURI(userId)}/surveyallscore`;
export const RESEND_WELCOME_EMAIL_FOR_JOB_URL = '/api/job/welcome-mail';
export const GET_JOBLIST = '/api/job/joblist';
export const GET_JOB = (id) => `/api/job/joblist/${id}`;
export const GET_CHATS = (jobId) => `/api/job/${encodeURI(jobId)}/chatlist`;
export const CREATE_JOB = '/api/job';
export const CHAT_BOT_TAKE_OVER_ENDPOINT = (jobId) => `/api/job/${jobId}/chat-bot-take-over`;
export const RESEND_WELCOME_EMAIL = (type) => `/api/${type === 'case' ? 'job' : type}/welcome-mail`;
export const SEND_SURVEY_PROXY = '/api/job/sendsurvey';
export const SET_JOB_LANGUAGE = (jobId) => `/api/job/translate/${jobId}`;
export const SET_USER_LANGUAGE = (userId) => `/api/job/translate/${userId}`;
export const PUT_JOB_ENDPOINT = (jobId) => `/api/job/${jobId}`;
export const PATCH_JOB_ENDPOINT = (id) => `/api/job/${id}`;
export const PUT_JOB_NEEDS_ACTION_RESPONSE_ENDPOINT = (jobId, chatId, responseType) => `/api/job/${jobId}/chat/${chatId}/needsActionResponse/${responseType}`;
export const GET_CASE_TYPES = '/api/case-types';
export const BULK_UPDATE_CASES_ENDPOINT = '/api/job/bulk/update';
export const READ_NOTE_MENTION_ENDPOINT = (jobId, noteId) => `/api/job/joblist/${jobId}/notes/${noteId}/read-note-mention`;
export const GET_FILTERED_OPERATOR_IDS = '/api/job/filteredoperatorids';
export const PATCH_LAST_VIEWED_MESSAGE = (jobId) => `/api/job/${jobId}/lastViewedMessage`;
export const PATCH_MARK_CHAT_UNREAD = (jobId) => `/api/job/${jobId}/markChatUnread`;
export const PATCH_MARK_MESSAGE_UNREAD = (jobId) => `/api/job/${jobId}/markMessageUnread`;
export const PUT_ACTIVE_WORKFLOW = (jobId) => `/api/job/${jobId}/active-workflow`;

// Notes
export const CREATE_NOTE = (caseId) => `/api/job/${encodeURI(caseId)}/notes`;
export const GET_NOTES = (caseId) => `/api/job/${encodeURI(caseId)}/notes`;
export const DELETE_NOTE = ({ caseId, noteId }) => `/api/job/${encodeURI(caseId)}/notes/${encodeURI(noteId)}`;
export const ADD_REPLY_ENDPOINT = (caseId, noteId) => `/api/job/${encodeURI(caseId)}/notes/${encodeURI(noteId)}`;
export const DELETE_REPLY_ENDPOINT = ({ caseId, noteId, replyId }) => `/api/job/${encodeURI(caseId)}/notes/${encodeURI(noteId)}/replies/${encodeURI(replyId)}`;

// Profile
export const PROFILE = '/api/profile';
export const PROFILE_BY_ID = (userId) => `/api/profile/${userId}`;
export const PROFILE_IMAGE = '/api/profile/image';
export const PROFILE_AUTOREPLY = '/api/profile/replyes';

// OOO Replies
export const GET_USER_AUTOREPLY = (userId) => `/api/auto-reply/user/${userId}`;
export const CREATE_USER_AUTOREPLY = (userId) => `/api/auto-reply/user/${userId}`;

// Claims
export const WELCOME_MAIL = '/api/claim/welcome-mail';

// Queue
export const GET_QUEUELIST = '/api/queue/queuelist';

// Chats
export const CREATE_CHAT = '/api/chat/new';
export const SEND_FILE = (chatId) => `/api/chat/${chatId}/file`;
export const FORWARD_MEDIA = (chatId) => `/api/chat/${chatId}/forward-media`;
export const ADD_USER_TO_CHAT = (chatId) => `/api/chat/${encodeURI(chatId)}/adduser`;
export const REMOVE_USER_FROM_CHAT = (chatId) => `/api/chat/${encodeURI(chatId)}/removeuser`;
export const GET_CHAT_MESSAGES_PAGE_V2 = (orgId, chatId, messageId, direction, limit) => `/api/chat/${encodeURI(orgId)}/${encodeURI(chatId)}/messages-v2/page?id=${messageId}&direction=${direction}&limit=${limit}`;
export const GET_UNREDACTED_MESSAGE = (orgId, messageId, redactionType) => `/api/message/${encodeURI(orgId)}/${encodeURI(messageId)}/${encodeURI(redactionType)}/view-body`;
export const PUT_MESSAGE_NEEDS_ACTION_RESPONSE_ENDPOINT = (messageId, jobId, responseType) => `/api/message/${messageId}/job/${jobId}/needsActionResponse/${responseType}`;

export const GET_CHAT_MEDIA = (chatId) => `/api/chat/${encodeURI(chatId)}/mediamessages`;
export const GET_CHAT_PARTICIPANTS = (chatId) => `/api/chat/${encodeURI(chatId)}/participants`;
export const GET_ALL_CHAT_PARTICIPANTS = (chatId) => `/api/chat/${encodeURI(chatId)}/participants/all`;
export const SEND_MESSAGE_TO_CHAT = (chatId) => `/api/chats/${chatId}/messages`;
export const PUT_CHAT_PARTICIPANT = (chatId) => `/api/chat/${chatId}/enduser`;

// Message Templates
export const MESSAGE_TEMPLATE_ROUTE = '/api/templates';
export const GET_KEYS = '/api/msgtemplate/keys';
export const PIN_MESSAGE_TEMPLATE_V2 = (templateId) => `/api/templates/${templateId}/pinned`;
export const SELECT_MESSAGE_TEMPLATE_V2 = (templateId) => `/api/templates/${templateId}/actions/populate`;
export const QUERY_MESSAGE_TEMPLATES_V2 = (jobId, isTemplateLOB) => (
  jobId ? `/api/templates?jobId=${jobId}&isTemplateLOB=${isTemplateLOB}` : '/api/templates'
);

// Rulesets
export const GET_RULESET = (rulesetType) => `/api/rule-sets/ruleset-type/${rulesetType}`;
export const CREATE_RULESET = '/api/rule-sets';
export const UPDATE_RULESET = (rulesetId) => `/api/rule-sets/${rulesetId}`;

// Analytics
export const ANALYTICS_LOOKER_URL = (dashboardName, timezone) => `/api/analytics/looker/${dashboardName}/${timezone}`;
export const ANALYTICS_LOOK_RESULT = (lookNumber) => `/api/analytics/look/${lookNumber}`;
export const ANALYTICS_IFRAME_LOOK_URL = (caseId, lookNumber, timezone) => `/api/analytics/iframelook/${caseId}/${lookNumber}/${timezone}`;
export const ANALYTICS_CASE_IDS_FROM_LOOK = (lookNumber) => `/api/analytics/look/getCaseIds/${lookNumber}`;
export const ANALYTICS_GET_REPORTS = (organization) => `/api/analytics/${organization}/reports`;
export const ANALYTICS_CW_LOG = '/api/analytics/cw-log';

// Mass Notification
export const GET_MASS_NOTIFICAITIONS = '/api/massNotifications/getMassNotifications';
export const GET_CSV_TEMPLATE = '/api/massNotifications/getCSVTemplate';
export const GET_UPLOAD_CSV_SIGNED_URL = '/api/massNotifications/createMassNotification';
export const GET_ERROR_CSV = '/api/massNotifications/getSignedUrlForErrorReport';
export const DELETE_MASS_NOTIFICATION = '/api/massNotifications/deleteMassNotificationById';
export const SEND_MASS_NOTIFICATION = '/api/massNotifications/sendMassNotification';
export const GET_MASS_NOTIFICATION_TRANSCRIPT = '/api/chat/getpdf';
export const ASSIGN_MASS_NOTIIFICATION = '/api/massNotifications/setMassNotificationAssignedOperator';

// New Media
export const GET_SIGNED_URL_FOR_MEDIA_UPLOAD = (
  ext,
  fileType,
  fileSizeLimit,
) => `/api/newmedia/preSignedPost?ext=${ext}&fileType=${fileType}&fileSizeLimit=${fileSizeLimit}`;
export const GET_SIGNED_URL_FOR_MEDIA_GET = (key) => `/api/newmedia/getSignedURLForGet?key=${key}`;
export const GET_SHORT_CODE_MEDIA = (code) => `api/newmedia/getShortCodeMedia?code=${code}`; // different from format below because we use window.location to prepend
export const REDACT_IMAGE_ENDPOINT = (key) => `/api/newmedia/${key}/redactions`;

// Organizations
export const GET_ORGANIZATION_ROLES = (organizationId) => `/api/organization/${organizationId}/roles`;
export const UPDATE_ORGANIZATION_BRANDING = (organizationId) => `/api/organization/${organizationId}/branding`;
export const ORGANIZATION_SETTING = '/api/user/updateorganizationsettings';
export const UPDATE_ORG_SETTING = (organizationId) => `/api/organization/${organizationId}/settings`;
export const UPDATE_LINE_OF_BUSINESS = (organizationId, lineOfBusinessId) => `/api/organization/${organizationId}/lineOfBusiness/${lineOfBusinessId}`;
export const GET_FULL_ORG_DATA = (organizationId) => `/api/organization/${organizationId}/fullData`;

// Admin routes
export const UPDATE_USER_ROLES = (userId) => `/api/admin/${userId}/roles`;

/**
 * NOTE: When adding endpoints to this file, be sure to prepend each with a '/'. [Ex: '/api/endpoint' not 'api/endpoint']
 * Otherwise when trying to make a call to the endpoint, whatever path may already be in the URL may be added along with
 * your endpoint, to the host URL.
 * */
