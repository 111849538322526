import { GridItem } from '@chakra-ui/react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { updateUserProperties } from '@app/actions/profile';
import usePermissionVerify from '@app/components/elements/hooks/usePermissionVerify';
import {
  CASE_VISIBILITY,
  LINES_OF_BUSINESS_ENABLE,
} from '@app/constants/permissions';
import { CaseVisibilityEnum } from '@app/types/create-case';
import { StateType } from '@app/types/reducer-state';

import AssignUserMenu from './fields/assign-user-menu';
import GenericSelectMenu from './fields/generic-select-menu';
import LineOfBusinessMenu from './fields/line-of-business-menu';

const SharedForm = () => {
  const dispatch = useDispatch();
  const brands = useSelector((state: StateType) => state.organizations.branding);
  const { getValues } = useFormContext();

  const handleBrandChange = (brand: string) => {
    dispatch(updateUserProperties({ brandId: brand }));
  };

  const { hasAccess: isLinesOfBusinessEnabled } = usePermissionVerify(
    { neededPermissions: [LINES_OF_BUSINESS_ENABLE] },
  );

  const { hasAccess: isCaseVisibilityEnabled } = usePermissionVerify(
    { neededPermissions: [CASE_VISIBILITY] },
  );

  const validateAssignedUser = (visibility: string) => {
    const assignedUser = getValues('assignedTo');
    if ([
      CaseVisibilityEnum.privateHidden,
      CaseVisibilityEnum.privateRedacted,
    ].includes(visibility as CaseVisibilityEnum)) {
      return assignedUser !== 'unassigned' || 'Private cases must be assigned to a user';
    }
    return true;
  };

  return (
    <>
      <GridItem>
        <GenericSelectMenu
          id="insurance-brand"
          label="Insurance Brand"
          name="insuranceBrand"
          defaultValue="Choose Insurance Brand"
          options={brands.map((brand) => (
            { id: brand, label: brand, handleSelect: handleBrandChange }
          ))}
          registerOptions={brands.length > 0 ? { required: { value: true, message: 'Insurance Brand is Required' } } : {}}
        />
      </GridItem>
      <GridItem>
        {isLinesOfBusinessEnabled && getValues('insuranceBrand') && (
          <LineOfBusinessMenu />
        )}
      </GridItem>
      <GridItem>
        <AssignUserMenu />
      </GridItem>
      <GridItem>
        {isCaseVisibilityEnabled && (
          <GenericSelectMenu
            id="case-visibility"
            label="Case Visibility"
            name="caseVisibility"
            options={[{ id: CaseVisibilityEnum.public, label: 'Public' },
              { id: CaseVisibilityEnum.privateRedacted, label: 'Private' },
            ]}
            registerOptions={{
              required: {
                value: true,
                message: 'Case Visibility is Required',
              },
              validate: validateAssignedUser,
            }}
          />
        )}
      </GridItem>
    </>
  );
};

export default SharedForm;
