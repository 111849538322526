import { ActiveMessageTemplateState, RuleData, RuleSettingsState } from '@app/components/Settings/IntegrationSettings/integration-settings-types';

export const TOTAL_LOSS = 'TOTAL_LOSS';
export const COPART_DATA = 'COPART_DATA';
export const VERISK_DATA = 'VERISK_DATA';
export const PROPERTY_CLAIM_DATA = 'Property Claim Data';
export const COPART_CLAIM_DATA = 'Copart Data';
export const VERISK_RULESET_TYPE = 'XACT_ANALYSIS';
export const DROPDOWN_LABEL_MARK_ISSUES_COMPLETE = 'Mark Release Issues Complete';

export const COPART_SELLER_PORTAL = 'https://seller.copart.com/lotdisplay/';
export const XACT_PORTAL = (assignmentId: string) => `https://beta.xactanalysis.com/apps/xactanalysis/detail.jsp?ddid=${assignmentId}&xlink=true#d_assignment`;

export const TRUNCATE_TEXT_LENGTH = 250;

const VERISK_ADJUSTER_ASSIGNED = 'xact_analysis.adjuster_assigned';
const VERISK_INSPECTION_SCHEDULED = 'xact_analysis.inspection.appointment_scheduled';
const VERISK_INSPECTION_COMPLETED = 'xact_analysis.inspection.completed';
const VERISK_INSPECTION_QA_APPROVED = 'xact_analysis.inspection.qa_approved';

export const ruleSettingsCopy: Record<string, string> = {
  SUGGEST_TEMPLATE: 'Suggest Template',
  SEND_TEMPLATE: 'Automatically Send',
  SCHEDULE_TEMPLATE: 'Schedule Template',
  OWPIP: 'Remove Belongings',
  VRRLR: 'Verbal Release',
  WRRLR: 'Written Release',
  Shop: 'Vehicle at shop',
  UNCNT: 'Unable to Contact',
  Residence: 'Vehicle at residence',
  RELEASE_ISSUE_CREATED: 'Release Issue Created',
  RELEASE_ISSUE_RESOLVED: 'Release Issue Resolved',
  TRANSTART: 'Assignment Created',
  VEHCHKIN: 'Vehicle Checked In',
  CASE_EVENT_RECEIVED: 'Case Event Received',
  isIndependentAdjuster: 'Independent Adjuster Assigned',
  [VERISK_ADJUSTER_ASSIGNED]: 'Adjuster Assigned',
  [VERISK_INSPECTION_SCHEDULED]: 'Inspection Scheduled',
  [VERISK_INSPECTION_COMPLETED]: 'Inspection Completed',
  [VERISK_INSPECTION_QA_APPROVED]: 'Inspection QA Approved',
};

export const defaultRuleSettingsState: RuleSettingsState = { id: '', rules: {} };

export const defaultActiveMessageTemplateState: ActiveMessageTemplateState = {
  id: '',
  ruleId: '',
  showModal: false,
  body: '',
  displayName: '',
  tokens: [],
  createdAt: '',
  updatedAt: '',
};

export const defaultActiveRuleState: RuleData = {
  ruleName: '',
  enabled: false,
  showModal: false,
  rule: {
    conditions: { all: [] },
    sendType: '',
  },
  messageTemplate: {
    id: '',
    displayName: '',
    body: '',
    tokens: [],
  },
};

export const PROGRESS_STEP_STATUS_IN_PROGRESS = 'INPROGRESS';
export const PROGRESS_STEP_STATUS_COMPLETE = 'COMPLETE';
export const PROGRESS_STEP_STATUS_NOT_STARTED = 'NOTSTARTED';
export const RELEASE_ISSUE_STATE_OPENED = 'Opened';
export const RELEASE_ISSUE_STATE_COPART_ASSIGNED = 'Copart Assigned';
export const RELEASE_ISSUE_STATE_WAITING_FOR_RELEASE = 'Waiting for Release';
export const BLOCKING_ISSUES = ['VRRLR', 'WRRLR', 'OWPIP'];
export const EVENT_TYPES = {
  VEHCHKIN: 'total_loss.vehicle_checked_in',
  RELEASE_COMPLETED: 'total_loss.release_issue.completed',
  RELEASE_UPDATED: 'total_loss.release_issue.updated',
  RELEASE_RESOLVED: 'total_loss.release_issue.resolved',
};
export const RELEASE_STATUS = {
  ACTIVE: 'ACTIVE',
  RESOLVED: 'RESOLVED',
  COMPLETED: 'COMPLETE',
};

// assist events
export const ASSIST_RELEASE_ISSUE_CREATED = 'RELEASE_ISSUE_CREATED';
export const ASSIST_RELEASE_ISSUE_UPDATED = 'RELEASE_ISSUE_UPDATED';
