import {
  Button, Heading, FormControl, FormLabel, Input, VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changePassword, setAuthError } from '@app/actions/auth';
import {
  CHANGE_PASSWORD_TYPED_ERROR, CHANGE_PASSWORD_TOO_SHORT_ERROR,
  CHANGE_PASSWORD_OLD_NEW_MATCH_ERROR, CHANGE_PASSWORD_REPEAT_MATCH_ERROR,
} from '@app/constants/apierrors';
import { PASSWORD_MIN_LENGTH } from '@app/constants/general';
import { StateType } from '@app/types/reducer-state';
import './ChangePassword.less';

const ChangePassword: React.FC = () => {
  const dispatch = useDispatch();
  const {
    error,
    passwordIsChanged,
    pendingRequest,
  } = useSelector((state: StateType) => state.auth);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const handleChange = (update: { [key: string]: string }) => {
    const [key, value] = Object.entries(update)[0];
    if (key === 'oldPassword') setOldPassword(value);
    if (key === 'newPassword') setNewPassword(value);
    if (key === 'repeatPassword') setRepeatPassword(value);
    dispatch(setAuthError(false));
  };

  const handleChangePassword = () => {
    if (!oldPassword.trim() || !newPassword.trim() || !repeatPassword.trim()) {
      dispatch(setAuthError(CHANGE_PASSWORD_TYPED_ERROR));
    } else if (newPassword.length < PASSWORD_MIN_LENGTH) {
      dispatch(setAuthError(CHANGE_PASSWORD_TOO_SHORT_ERROR));
    } else if (newPassword === oldPassword) {
      dispatch(setAuthError(CHANGE_PASSWORD_OLD_NEW_MATCH_ERROR));
    } else if (newPassword !== repeatPassword) {
      dispatch(setAuthError(CHANGE_PASSWORD_REPEAT_MATCH_ERROR));
    } else {
      dispatch(changePassword({ oldPassword, newPassword }));
    }
  };

  const getSavePasswordButton = () => {
    if (pendingRequest) {
      return (
        <Button isDisabled>
          Saving...
        </Button>
      );
    }
    return (
      <Button onClick={handleChangePassword}>
        Change Password
      </Button>
    );
  };

  const getSavePasswordMessage = () => {
    let message = null;

    if (passwordIsChanged) {
      message = (
        <div className="sucsess-change-password-saved">
          Password has been changed!
        </div>
      );
    } else if (error) {
      message = (
        <div className="error-change-password-saved">
          {error}
        </div>
      );
    }
    return message;
  };

  return (
    <div className="change-password">
      <Heading size="md" mb="0">Change Password</Heading>
      {/* this is hack to make Chrome disable autocomplete for password */}
      <form autoComplete="off">
        <input id="username" style={{ display: 'none' }} type="text" name="fakeusernameremembered" />
        <input id="password" style={{ display: 'none' }} type="password" name="fakepasswordremembered" />
        <VStack my={10} gap={10}>
          <FormControl>
            <FormLabel>Old Password (Required)</FormLabel>
            <Input value={oldPassword} onChange={(e) => handleChange({ oldPassword: e.target.value })} type="password" autoComplete="off" required />
          </FormControl>
          <FormControl>
            <FormLabel>New Password (Required)</FormLabel>
            <Input value={newPassword} onChange={(e) => handleChange({ newPassword: e.target.value })} type="password" autoComplete="off" required />
          </FormControl>
          <FormControl>
            <FormLabel>Repeat Password (Required)</FormLabel>
            <Input value={repeatPassword} onChange={(e) => handleChange({ repeatPassword: e.target.value })} type="password" autoComplete="off" required />
          </FormControl>
        </VStack>
      </form>
      <div className="change-password-message-button">
        {getSavePasswordMessage()}
        {getSavePasswordButton()}
      </div>
    </div>
  );
};

export default ChangePassword;
