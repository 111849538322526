import _ from 'lodash';
import {
  activeOperators, commonForm, commonLoading, filterJobsByType,
} from './common';

const selector = (state) => {
  const jobsList = _.get(state, 'jobs.list', []);
  const job = _.get(state, 'jobs');

  const filter = _.get(state, 'ui.filter.case');
  const sort = {
    column: 'createdAt',
    order: 'DESC',
  };

  const policies = filterJobsByType(state, jobsList, 'POLICY');
  const claims = filterJobsByType(state, jobsList, 'CLAIM');
  const jobs = filterJobsByType(state, jobsList);

  return {
    ...commonLoading(state),
    ...commonForm(state.jobs),
    authId: _.get(state, 'auth.user._id'),
    activeQueueId: _.get(state, 'ui.activeQueueId', 'myOpen'),
    requests: _.get(state, 'ui.requests.reassignjob', {}),
    policyForm: state.policies ? { ...commonForm(state.policies) } : {},
    policies,
    claims,
    claimForm: state.claims ? { ...commonForm(state.claims) } : {},
    jobs,
    job,
    sortId: sort && sort.column,
    sort,
    filter,
    token: _.get(state.auth, 'token', ''),
    users: _.get(state, 'users.listById', {}),
    confirmationIsOpen: _.get(state, 'ui.confirmationIsOpen', {}),
    operators: activeOperators(_.get(state, 'users.list', [])),
    organization: _.get(state, 'auth.user.organization'),
    permissions: _.get(state, 'auth.permissions'),
  };
};

export default selector;
