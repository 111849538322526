import {
  FormControl, FormLabel, Input, Select, HStack, RadioGroup, Radio, ButtonGroup, Button,
} from '@chakra-ui/react';
import React from 'react';

interface CreateMassNotificationSchedulingProps {
  createButtonEnabled: boolean;
  handleChangeScheduleEnabled: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleFormInputChange: (
    event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
  ) => void;
  handleClickSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleBackStep: () => void;
  handleNextStep: () => void;
  isScheduleEnabled: boolean;
  minScheduledDatetimeLocalStr: string;
  showErrorMessage: boolean;
  formData: {
    scheduledDatetime: string;
    scheduledTimezone: string;
  };
}

const CreateMassNotificationScheduling: React.FC<CreateMassNotificationSchedulingProps> = ({
  createButtonEnabled,
  handleChangeScheduleEnabled,
  handleFormInputChange,
  handleClickSubmit,
  handleBackStep,
  handleNextStep,
  isScheduleEnabled,
  minScheduledDatetimeLocalStr,
  showErrorMessage,
  formData,
}) => (
  <>
    <div className="modal-container">
      <h3 className="modal-step-title">Step 2: Schedule Notification</h3>
      <p>
        You are able to schedule a notification to be sent out at a future time
        or you can start sending it right away.
      </p>
      <RadioGroup value={isScheduleEnabled ? 'yes' : 'no'}>
        <HStack>
          <Radio
            id="scheduleEnabledYes"
            name="isScheduleEnabled"
            onChange={handleChangeScheduleEnabled}
            type="radio"
            value="yes"
            size="sm"
            mr={0}
          >
            Schedule at a specific time
          </Radio>
          <Radio
            id="scheduleEnabledNo"
            name="isScheduleEnabled"
            onChange={handleChangeScheduleEnabled}
            type="radio"
            value="no"
            size="sm"
            mr={0}
          >
            Create notification now
          </Radio>
        </HStack>
      </RadioGroup>
      {
        isScheduleEnabled ? (
          <>
            <h4 className="modal-section-title">Date/Time to Schedule</h4>
            <p>Select the date and time your notification begins sending:</p>
            <HStack gap={2}>
              <FormControl>
                <FormLabel>Scheduled Send Date</FormLabel>
                <Input
                  type="datetime-local"
                  min={minScheduledDatetimeLocalStr}
                  name="scheduledDatetime"
                  onChange={handleFormInputChange}
                  formNoValidate
                  value={formData.scheduledDatetime}
                  size="sm"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Destination Timezone</FormLabel>
                <Select
                  name="scheduledTimezone"
                  onChange={handleFormInputChange}
                  value={formData.scheduledTimezone}
                  size="sm"
                >
                  <option value="US_EAST">Eastern (US)</option>
                  <option value="US_CENTRAL">Central (US)</option>
                  <option value="US_MOUNTAIN">Mountain (US)</option>
                  <option value="US_PACIFIC">Pacific (US)</option>
                </Select>
              </FormControl>
            </HStack>
          </>
        ) : null
      }
    </div>
    <ButtonGroup className="buttonRow">
      {
        showErrorMessage
          ? <div className="warningText errorText">Failed to create Mass Notification, please try again later!</div>
          : null
      }
      <Button
        onClick={handleBackStep}
        variant="outline"
      >
        Back
      </Button>
      {
        isScheduleEnabled ? (
          <Button
            onClick={handleNextStep}
            isDisabled={
              !createButtonEnabled || !formData.scheduledDatetime || !formData.scheduledTimezone
            }
          >
            Next
          </Button>
        ) : (
          <Button
            onClick={handleClickSubmit}
            disabled={!createButtonEnabled}
          >
            Create Now
          </Button>
        )
      }
    </ButtonGroup>
  </>
);

export default CreateMassNotificationScheduling;
