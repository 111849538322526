import React from 'react';

import AlertDialog from '@app/components/chakra/alert-dialog';

interface SurveyModalProps {
  closeSendSurvey: () => void;
  sendSurvey: () => void;
  isAssignedJob: boolean;
  showSurvey: boolean;
}

const SurveyModal: React.FC<SurveyModalProps> = (props) => {
  const {
    showSurvey,
    closeSendSurvey,
    isAssignedJob,
    sendSurvey,
  } = props;
  const handleSubmit = () => {
    if (isAssignedJob) {
      sendSurvey();
    }
    closeSendSurvey();
  };

  return (
    <AlertDialog
      id="send-survey"
      title="Send Survey?"
      isDialogOpen={showSurvey}
      toggleDialog={closeSendSurvey}
      cancelText="Cancel"
      confirmText="Send"
      onConfirm={handleSubmit}
    >
      This will send the user a Hi Marley satisfaction survey.
    </AlertDialog>
  );
};

export default SurveyModal;
