export const CHAT_SEND_FILE_ERROR_MESSAGE = 'Error posting the file';
export const CLAIM_SAVE_ERROR = 'Saving the Claim';
export const CLAIM_UPDATE_ERROR = 'Updating the Claim';
export const CLAIM_GET_ERROR = 'Loading Claims';
export const JOB_GET_ERROR = 'Loading Jobs';
export const JOB_CREATE_ERROR = 'Creating the Job';
export const PROFILE_UPDATE_IMAGE_ERROR = 'Updating the Profile Image';
export const PROFILE_GET_ERROR = 'Loading the Profile';
export const USER_GET_ERROR = 'Loading Users';
export const USER_GET_PROPERTIES_ERROR = 'Loading Users properties error';
export const CHANGE_PASSWORD_TYPED_ERROR = 'Type old password and new password twice';
export const CHANGE_PASSWORD_TOO_SHORT_ERROR = 'New password should be longer than 5 characters';
export const CHANGE_PASSWORD_OLD_NEW_MATCH_ERROR = 'New password should not match with old password';
export const CHANGE_PASSWORD_REPEAT_MATCH_ERROR = 'Repeat password does not match';
export const CHANGE_PASSWORD_HTTP_ERROR = 'Sorry, cannot change new password, please try later';
export const SEND_WELCOME_MESSAGE_ERROR = 'Sorry, cant sent welcome message.';
export const UREDACTED_MESSAGE_GET_ERROR = 'failed to get unredacted message';
export const REQUEST_PUT_USER_AUTOASSIGN_ERROR_MSG = 'Error updating your Auto Assign status, please try again.';
