import {
  Modal, ModalCloseButton, ModalOverlay, ModalContent, ModalBody, Button, Icon, ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { CopyIcon } from '@himarley/unity';
import React from 'react';
import { useSelector } from 'react-redux';
import stripTags from 'striptags';

import '../MassNotificationVerificationModal/MassNotificationVerificationModal.less';
import '../MassNotificationModal/MassNotificationModal.less';
import './MassNotificationViewModal.less';

const copyToClipboard = (
  message: string,
  setMessageCopied: { (copied: boolean): void; (arg0: boolean): void; },
) => {
  navigator.clipboard.writeText(message)
    .then(() => {
      setMessageCopied(true);
    });
};

const formatMessage = (message: string) => {
  const dollarRegex = /(\s|^)(\$(\S)+)\b/gm;
  const newMessage = stripTags(message);
  return newMessage.replace(dollarRegex, (_, g1, g2) => `<span class="boldFormattedWord">${g1}${g2}</span>`);
};

interface MassNotificationViewModalProps {
  show: boolean;
  hide: () => void;
  activeMassNotification?: {
    Title?: string;
    Message?: string;
  };
  setMessageCopied: (copied: boolean) => void;
}

const MassNotificationViewModal: React.FC<MassNotificationViewModalProps> = ({
  show,
  hide,
  activeMassNotification,
  setMessageCopied,
}) => {
  const copied = useSelector((state) => state.massNotifications.messageCopied);

  return (
    <Modal
      isOpen={show}
      onClose={hide}
    >
      <ModalOverlay />
      <ModalContent className="mass-notification-view-modal">
        <ModalCloseButton />
        <ModalHeader>View Notification</ModalHeader>
        <ModalBody>
          <div className="textDisplay">
            <div className="inputTitle">Title</div>
            <div className="mass-notification-secondary-title">
              {activeMassNotification && activeMassNotification.Title}
            </div>
            <div className="inputTitle">Message</div>
            {
              activeMassNotification
              // eslint-disable-next-line react/no-danger
              && <div className="mass-notification-secondary-title" dangerouslySetInnerHTML={{ __html: formatMessage(activeMassNotification?.Message || '') }} />
            }
          </div>
        </ModalBody>
        {activeMassNotification && (
          <ModalFooter>
            {
              !copied
              && (
                <Button
                  rightIcon={<Icon as={CopyIcon} />}
                  onClick={() => copyToClipboard(activeMassNotification?.Message || '', setMessageCopied)}
                >
                  Copy to clipboard
                </Button>
              )
            }
            {copied
              && (
                <Button>
                  Copied to clipboard!
                </Button>
              )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default MassNotificationViewModal;
