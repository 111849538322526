import React, { createRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import {
  setFilename,
  setCreateButton,
  setModalLoading,
  createMassNotification,
  setSelectedFile,
} from '@app/actions/massNotifications';
import { DatetimeLocalRegExp } from '@app/helpers/datetime';

import CreateMassNotificationInfo from './create-mass-notification-info';
import CreateMassNotificationScheduling from './create-mass-notification-scheduling';
import CreateMassNotificationThrottling from './create-mass-notification-throttling';

const checkCreateButtonEnabled = (
  message: string,
  title: string,
  setCreateBtn: (btnState: { message: string, title: string }) => void,
  dispatch: React.Dispatch<unknown>,
) => {
  // validate inputs maybe?? TODO
  // const isValid = fileName && message && title
  dispatch(setCreateBtn({
    message,
    title,
  }));
};

const NotificationStep = {
  get INFO() {
    return 'INFO';
  },
  get SCHEDULE() {
    return 'SCHEDULE';
  },
  get THROTTLE() {
    return 'THROTTLE';
  },
};

let submitTimer: NodeJS.Timeout;

/**
 * Handles submission of Mass Notification creation form
 * @param {*} messageEle
 * @param {*} titleEle
 * @param {*} setLoad
 * @param {*} setCreateBtn
 * @param {*} createNotification
 * @param {*} onHide
 * @param {*} event
 * @param {Object} scheduledSendOptions object with scheduling params:
 * - scheduledDatetimeLocal: String
 * - destinationTimezoneLocale: String
 */
const handleSubmission = (
  notificationMessage: string,
  notificationTitle: string,
  setLoad: typeof setModalLoading,
  setCreateBtn: typeof setCreateButton,
  createNotification: typeof createMassNotification,
  onHide: () => void,
  event: React.MouseEvent<HTMLButtonElement>,
  scheduledSendOptions: {
    scheduledDatetimeLocal: unknown;
    destinationTimezoneLocale: unknown;
    isScheduleEnabled: unknown;
    isThrottleEnabled: unknown;
    throttleRate: unknown;
  } | undefined,
  dispatch: React.Dispatch<unknown>,
) => {
  event.preventDefault();
  dispatch(setLoad(true));
  dispatch(setCreateBtn({ message: '', title: '' }));
  clearTimeout(submitTimer);
  submitTimer = setTimeout(() => {
    dispatch(
      createNotification(notificationMessage, notificationTitle, onHide, scheduledSendOptions),
    );
  }, 200);
};

interface CreateMassNotificationFormProps {
  onHide: () => void;
}

const CreateMassNotificationForm: React.FC<CreateMassNotificationFormProps> = (
  { onHide },
) => {
  const dispatch = useDispatch();
  const {
    currentFilename,
    createButtonEnabled,
    showErrorMessage,
  } = useSelector((state) => ({
    currentFilename: state?.massNotifications?.currentFilename || '',
    createButtonEnabled: state?.massNotifications?.createButtonEnabled,
    showErrorMessage: state?.massNotifications?.showCreateErrorMessage,
  }));

  const fileInputRef = createRef<HTMLInputElement>();

  /*
    Get min from ISO without seconds, milliseconds, and offset.
    Don't allow any date/time in the past.
  */
  const minScheduledDatetimeLocalStr = new Date().toISOString().slice(0, 16);

  const [stepName, setStepName] = useState(NotificationStep.INFO);
  const [formData, setFormData] = useState({
    notificationMessage: '',
    notificationTitle: '',
    scheduledDatetime: '',
    scheduledTimezone: 'US_EAST',
    throttleRate: 1000,
  });

  const [isScheduleEnabled, setScheduleEnabled] = useState(false);
  const handleChangeScheduleEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation(); // stop form change event bubble
    setScheduleEnabled(event.target.value === 'yes');
  };

  const [isThrottleEnabled, setThrottleEnabled] = useState(false);
  const handleChangeThrottleEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation(); // stop form change event bubble
    setThrottleEnabled(event.target.value === 'yes');
  };

  const handleFormChange = () => {
    checkCreateButtonEnabled(
      formData.notificationMessage,
      formData.notificationTitle,
      setCreateButton,
      dispatch,
    );
  };

  type Event =
    React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>
    | React.ChangeEvent<HTMLSelectElement>;

  const handleFormInputChange = (event: Event) => {
    const { name, value } = event.target;
    if (['throttleRate'].includes(name)) {
      setFormData({ ...formData, [name]: Number(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleFileDrop = (files: FileList | null) => {
    const file = files ? files[0] : null;
    if (!file) return;
    dispatch(setFilename(file.name));
    dispatch(setSelectedFile(file));
    checkCreateButtonEnabled(
      formData.notificationMessage,
      formData.notificationTitle,
      setCreateButton,
      dispatch,
    );
  };

  const handleClickSelectFile = () => fileInputRef.current?.click();

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      dispatch(setFilename(event.currentTarget.files[0].name));
      dispatch(setSelectedFile(event.currentTarget.files[0]));
    }
  };

  const handleClickSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    const scheduledDatetimeLocal = formData.scheduledDatetime
      && DatetimeLocalRegExp.test(formData.scheduledDatetime)
      ? formData.scheduledDatetime
      : null;

    const destinationTimezoneLocale = formData.scheduledTimezone
      ? formData.scheduledTimezone
      : null;

    const scheduledSendOptions = {
      isScheduleEnabled,
      scheduledDatetimeLocal,
      destinationTimezoneLocale,
      isThrottleEnabled,
      throttleRate: formData.throttleRate,
    };

    handleSubmission(
      formData.notificationMessage,
      formData.notificationTitle,
      setModalLoading,
      setCreateButton,
      createMassNotification,
      onHide,
      event,
      scheduledSendOptions,
      dispatch,
    );
  };

  const handleInfoNextStep = () => setStepName('SCHEDULE');
  const handleScheduleBackStep = () => setStepName('INFO');
  const handleScheduleNextStep = () => setStepName('THROTTLE');
  const handleThrottleBackStep = () => setStepName('SCHEDULE');

  if (stepName === NotificationStep.SCHEDULE) {
    return (
      <Form onChange={handleFormChange}>
        <CreateMassNotificationScheduling
          createButtonEnabled={createButtonEnabled}
          handleChangeScheduleEnabled={handleChangeScheduleEnabled}
          handleFormInputChange={handleFormInputChange}
          handleClickSubmit={handleClickSubmit}
          handleBackStep={handleScheduleBackStep}
          handleNextStep={handleScheduleNextStep}
          isScheduleEnabled={isScheduleEnabled}
          minScheduledDatetimeLocalStr={minScheduledDatetimeLocalStr}
          showErrorMessage={showErrorMessage}
          formData={formData}
        />
      </Form>
    );
  }

  if (stepName === NotificationStep.THROTTLE) {
    return (
      <Form onChange={handleFormChange}>
        <CreateMassNotificationThrottling
          createButtonEnabled={createButtonEnabled}
          handleBackStep={handleThrottleBackStep}
          handleChangeThrottleEnabled={handleChangeThrottleEnabled}
          handleClickSubmit={handleClickSubmit}
          handleFormInputChange={handleFormInputChange}
          isScheduleEnabled={isScheduleEnabled}
          isThrottleEnabled={isThrottleEnabled}
          showErrorMessage={showErrorMessage}
          formData={formData}
        />
      </Form>
    );
  }

  return (
    <Form onChange={handleFormChange}>
      <CreateMassNotificationInfo
        currentFilename={currentFilename}
        createButtonEnabled={createButtonEnabled}
        fileInputRef={fileInputRef}
        formData={formData}
        handleClickSelectFile={handleClickSelectFile}
        handleFileDrop={handleFileDrop}
        handleFileInputChange={handleFileInputChange}
        handleFormInputChange={handleFormInputChange}
        handleNextStep={handleInfoNextStep}
      />
    </Form>
  );
};

export default CreateMassNotificationForm;
