/* eslint-disable no-underscore-dangle */
import { buildInboxFilterObject } from '../services/helpers';
import { QUERY_KEYS } from '../services/constants';
import { PAGE_SIZE } from '../constants/general';
import { chunkArray } from './cacheHelpers';
import { buildFullName, buildInboxJob } from '../helpers/inbox.helpers';

export const getInboxJob = (job, cachedJob, authId, permissions) => {
  let inboxJob;
  // get job from cache when isPinned changed instead of building job
  if (typeof job?.isPinned === 'boolean' && job?.isPinned !== cachedJob?.isPinned) {
    inboxJob = { ...cachedJob, isPinned: job?.isPinned };
  } else {
    inboxJob = buildInboxJob(
      {
        ...job,
        isPinned: job?.isPinned ?? cachedJob?.isPinned,
        lastViewedMessage: job?.lastViewedMessage ?? cachedJob?.lastViewedMessage,
        unreadMessageCount: job?.unreadMessageCount ?? cachedJob?.unreadMessageCount,
      },
      authId,
      permissions,
    );
  }
  return inboxJob;
};

export const buildFilters = (store) => {
  const loggedInOperatorId = store?.auth?.user?._id || store.auth.user.id;
  const expandInboxSearchBar = store?.jobs?.expandInboxSearchBar;
  return buildInboxFilterObject(
    {
      view: 'inbox',
      inboxFilters: store?.profile?.properties?.inboxFilters,
      expandInboxSearchBar,
      searchMessages: store?.jobs?.search,
    },
    loggedInOperatorId,
  );
};

/**
 * Collects the data required to process the updating of Customer Names on Cases
 * @param   {object} store redux store
 * @param   {object} customer updated customer object
 * @return {object} Containing all the data needed for the update of the cache
* */
export const collectCustomerCaseCacheData = (store, customer) => {
  const customerId = customer.id || customer._id;
  const customerName = buildFullName(customer.profile);

  const filters = buildFilters(store);
  const unpinnedQueryKey = [QUERY_KEYS.CASES, filters];
  return {
    customerId,
    customerName,
    unpinnedQueryKey,
  };
};

/**
 * Collects the data required to process the modification of a single Case in local cache
 * @param   {object} store redux store
 * @param   {object} queryClient react query client
 * @param   {object} job updated job object
 * @return {object} Containing all the data needed for the update of the cache
* */
export const collectCaseCacheData = (store, queryClient, job) => {
  const permissions = store?.auth?.permissions || [];
  const authId = store?.auth?.user?._id || store?.auth?.user?.id;
  let cachedJob = null;
  const filters = buildFilters(store);
  let userHasScrolled = false;
  const unpinnedQueryKey = [QUERY_KEYS.CASES, filters];
  const inboxJobId = job?._id || job?.id;
  if (!cachedJob) {
    const cachedData = queryClient?.getQueryData(unpinnedQueryKey) || [];
    userHasScrolled = cachedData?.pages?.length > 1;
    cachedJob = cachedData?.pages?.flat().find((c) => c._id === inboxJobId) || null;
  }
  const inboxJob = getInboxJob(job, cachedJob, authId, permissions);
  const groups = store?.groups?.listById;
  const viewedCaseIds = store?.jobs?.viewedChats || new Set([]);

  return {
    groups,
    filters,
    inboxJob,
    cachedJob,
    userHasScrolled,
    unpinnedQueryKey,
    viewedCaseIds,
  };
};

const convertToArray = (input) => (input && !Array.isArray(input) ? [input] : input);

export const buildCasesToSort = (oldData, userHasScrolled) => {
  if (oldData?.pages && !userHasScrolled) {
    return convertToArray(oldData?.pages[0]) || [];
  }
  if (oldData?.pages && userHasScrolled) {
    return oldData?.pages.flat();
  }
  return oldData;
};

export const buildNewCachedData = (oldData, clonedCases, userHasScrolled) => {
  if (oldData?.pages && clonedCases && !userHasScrolled) {
    return { ...oldData, pages: [clonedCases] };
  }
  if (oldData?.pages && clonedCases && userHasScrolled) {
    const pages = chunkArray(clonedCases, PAGE_SIZE);
    return { ...oldData, pages };
  }
  return oldData;
};
