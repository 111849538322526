/* eslint-disable max-len */
import get from 'lodash/get';
import { usersJsonToCSV } from '../helpers/jsonToCSVConvert';
import { encodedUri } from '../helpers/urls';
import { showSnackbar } from './notification';
import { TITLE_ENABLED } from '../constants/permissions';
import { constructFocusOperatorRoleText } from '../helpers/snackBarText';

import {
  GET_BULK_UPLOAD_USERS_CSV_TEMPLATE,
  GET_ORGANIZATION_ROLES as ENDPOINT_GET_ORGANIZATION_ROLES,
  UPDATE_USER as UPDATE_USER_ENDPOINT,
  CREATE_USER,
  CREATE_END_USER,
  DELETE_USER,
  CREATE_USERS,
  CHECK_USER,
  UPDATE_USER_ROLES,
  GET_ITEMS,
  GET_USERS_TO_EXPORT,
  UPDATE_END_USER_OPT_STATUS,
  GET_CHANGE_UNIQUE_IDS_CSV_TEMPLATE,
  UPDATE_OPERATORS_UNIQUE_IDS,
  GET_ACTIVE_OPERATORS_COUNT,
} from '../constants/endpoints';
import {
  NEW_BULK_USER,
  ADD_USER,
  NEW_USER,
  UPDATED_USER_REQUEST_SUCCESS,
  UPDATED_USER_REQUEST_ERROR,
  UPDATED_USER_REQUEST_CLEAN,
  UPDATED_USER,
  GET_ORGANIZATION_ROLES,
  UPDATE_USER_ROLE,
  RESET_EDIT_OBJECT,
  CLEAR_USERS,
  BULK_UPDATE_USER_ROLES,
  UPDATE_LOCK_PROGRESS,
  SET_UPDATE_USER_STATUS,
  UPDATE_USER,
  SET_ACTIVE_OPERATOR_COUNT,
  UPDATE_ACTIVE_OPERATOR_COUNT,
  IS_FETCHING_ACTIVE_OPERATOR_COUNT,
} from '../constants/actions';

import { apiCall, doRequest, validateAuthErrorAndDispatchDeauth } from './api';
import User from '../models/user';
import { CHANGE_UNIQUE_ID } from '../constants/general';

export function checkUser(phone) {
  const args = {
    endpoint: CHECK_USER(phone),
    method: 'GET',
    action: 'MOBILE_TYPE',
    process: ({ body }) => body,
  };
  return apiCall({ args });
}

export const modifiedUser = (data) => ({
  type: UPDATED_USER,
  payload: new User(data),
});

export const bulkUpdateUserRoles = (userRolesMap) => ({
  type: BULK_UPDATE_USER_ROLES,
  payload: userRolesMap,
});

export const clearMobileLookup = () => ({ type: 'CLEAR_MOBILE_LOOKUP' });

export const updateUser = (data) => (dispatch) => {
  const args = {
    endpoint: UPDATE_USER_ENDPOINT,
    method: 'PUT',
    body: data,
    action: UPDATE_USER,
  };
  dispatch(apiCall({ args }));
};

export const setUpdateUserStatus = (status) => (dispatch) => dispatch({
  type: SET_UPDATE_USER_STATUS,
  status,
});

export function cleanUserRequest() {
  return { type: UPDATED_USER_REQUEST_CLEAN };
}

export const getUsersByEmail = (email) => () => {
  const opts = {
    endpoint: GET_ITEMS('OPERATOR', { email }),
    method: 'GET',
  };
  return doRequest(opts);
};

export const getChunkedUsersByEmail = (email, chunkSize = 15) => async () => {
  const chunks = [];
  for (let i = 0; i < email.length; i += chunkSize) {
    chunks.push(email.slice(i, i + chunkSize));
  }
  let users = [];
  for (const chunk of chunks) {
    const result = await doRequest({
      endpoint: GET_ITEMS('OPERATOR', { email: chunk }),
      method: 'GET',
    });
    users = users.concat(result);
  }
  return users;
};

export const deleteUser = (id) => {
  const args = {
    endpoint: DELETE_USER(id),
    action: 'DELETE_USER',
    method: 'delete',
    process: () => ({ id }),
    callback: ({ dispatch }) => {
      dispatch({
        type: UPDATE_ACTIVE_OPERATOR_COUNT,
        payload: { count: -1 },
      });
    },
  };
  return apiCall({ args });
};

export const addUser = (data) => ({ type: ADD_USER, payload: data });

export const addBulkUser = (data) => ({ type: NEW_BULK_USER, payload: data });

export const createUser = (user) => {
  const args = {
    endpoint: CREATE_USER,
    action: NEW_USER,
    method: 'POST',
    body: { ...user },
    process: ({ body }) => new User(body),
    callback: ({ dispatch }) => {
      dispatch({
        type: UPDATE_ACTIVE_OPERATOR_COUNT,
        payload: { count: 1 },
      });
    },
  };
  return apiCall({ args });
};

export const getActiveOperatorCount = () => (dispatch) => {
  dispatch({
    type: IS_FETCHING_ACTIVE_OPERATOR_COUNT,
    payload: { isFetching: true },
  });
  const args = {
    callback: () => {
      dispatch({
        type: IS_FETCHING_ACTIVE_OPERATOR_COUNT,
        payload: { isFetching: false },
      });
    },
    errorCallback: () => {
      dispatch({
        type: IS_FETCHING_ACTIVE_OPERATOR_COUNT,
        payload: { isFetching: false },
      });
    },
    action: SET_ACTIVE_OPERATOR_COUNT,
    endpoint: GET_ACTIVE_OPERATORS_COUNT,
    method: 'GET',
  };
  return dispatch(apiCall({ args }));
};

export const createUsers = (users, isUpdating = false) => {
  const args = {
    action: 'CREATE_BULK_USERS',
    endpoint: CREATE_USERS,
    method: 'POST',
    body: { users, isUpdating },
    process: ({ body }) => body,
    callback: ({ dispatch }) => {
      dispatch(getActiveOperatorCount());
    },
    errorCallback: ({ dispatch }) => {
      dispatch(getActiveOperatorCount());
    },
  };
  return apiCall({ args });
};

export function updateOperatorsUniqueIds(users) {
  const args = {
    action: 'UPDATE_OPERATORS_UNIQUE_IDS',
    endpoint: UPDATE_OPERATORS_UNIQUE_IDS,
    method: 'PUT',
    body: { users },
    process: ({ body }) => body,
  };
  return apiCall({ args });
}

const processNewUser = ({ body }) => new User(body);

export function createEndUser(user, callback) {
  const args = {
    endpoint: CREATE_END_USER,
    action: NEW_USER,
    method: 'POST',
    body: { ...user },
    callback,
    process: processNewUser,
  };
  return apiCall({ args });
}

export const updateEndUserOptStatus = (data) => (dispatch) => {
  const {
    _id, status, timeZone, jobId, isUpdatingLockStatus,
  } = data;
  if (isUpdatingLockStatus) {
    dispatch({
      type: UPDATE_LOCK_PROGRESS,
      payload: { id: _id, inProgress: true },
    });
  }

  const args = {
    endpoint: UPDATE_END_USER_OPT_STATUS(_id),
    method: 'POST',
    action: UPDATED_USER_REQUEST_SUCCESS,
    body: {
      status,
      timeZone,
      source: 'CASE_CREATION',
      reference: jobId,
    },
    callback: () => {
      if (isUpdatingLockStatus) {
        dispatch({
          type: UPDATE_LOCK_PROGRESS,
          payload: { id: _id, inProgress: false },
        });
      }
    },
    errorCallback: ({ error }) => {
      validateAuthErrorAndDispatchDeauth(error, dispatch);
      dispatch({
        type: UPDATED_USER_REQUEST_ERROR,
        payload: 'Error updating end-user opt status. Please try again.',
      });
      if (isUpdatingLockStatus) {
        dispatch({
          type: UPDATE_LOCK_PROGRESS,
          payload: { id: _id, inProgress: false },
        });
      }
    },
  };
  return dispatch(apiCall({ args }));
};

// Load all roles for an organization
export const getOrganizationRoles = () => (dispatch, getStore) => {
  const organizationId = get(getStore(), 'auth.user.organization.name', '');
  const encodedOrgId = encodedUri(
    ENDPOINT_GET_ORGANIZATION_ROLES,
    organizationId,
  );
  const args = {
    endpoint: encodedOrgId,
    action: GET_ORGANIZATION_ROLES,
    method: 'GET',
    process: ({ body }) => body,
  };
  return dispatch(apiCall({ args }));
};

// add a role to a user
export const addUserRole = (user, roleName) => (dispatch) => {
  const args = {
    endpoint: UPDATE_USER_ROLES(user.id),
    action: UPDATE_USER_ROLE,
    method: 'PUT',
    callback: ({ dispatch }) => {
      const snackBarText = constructFocusOperatorRoleText(
        user?.roles || [],
        roleName,
      );
      if (snackBarText) {
        dispatch({ type: 'SNACKBAR_SHOW', payload: { text: snackBarText } });
      }
    },
    body: { roles: [roleName], orgName: get(user, 'organization.name') },
    process: ({ body }) => body,
  };
  return dispatch(apiCall({ args }));
};

// remove a role from a user
export const removeUserRole = (user, roleName) => (dispatch) => {
  const args = {
    endpoint: UPDATE_USER_ROLES(user.id),
    action: UPDATE_USER_ROLE,
    method: 'DELETE',
    body: { roles: [roleName] },
    process: ({ body }) => body,
  };
  return dispatch(apiCall({ args }));
};

export const resetEditObj = (issuingFormType) => ({
  type: RESET_EDIT_OBJECT,
  payload: { issuingFormType },
});

export const clearUsers = () => (dispatch) => dispatch({ type: CLEAR_USERS });

export const clearFormErrors = () => ({ type: 'CLEAR_FORM_ERRORS' });

export const downloadCSVTemplate = (type) => {
  doRequest({
    endpoint:
      type === CHANGE_UNIQUE_ID
        ? GET_CHANGE_UNIQUE_IDS_CSV_TEMPLATE
        : GET_BULK_UPLOAD_USERS_CSV_TEMPLATE,
    responseType: 'blob',
    method: 'GET',
  })
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      const fileName = type === CHANGE_UNIQUE_ID
        ? 'ChangeUniqueIdsTemplate.csv'
        : 'BulkUploadUsersTemplate.csv';
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      return res;
    })
    .catch((err) => {
      console.error('failed to download csv');
      console.error(err);
    });
};

export const getUsersToExport = () => (dispatch, getStore) => {
  const isTitleEnabled = get(getStore(), 'auth.permissions', []).includes(
    TITLE_ENABLED,
  );

  dispatch(
    showSnackbar({
      text: 'Your download should begin shortly',
      isError: false,
    }),
  );
  doRequest({
    endpoint: GET_USERS_TO_EXPORT,
    responseType: 'blob',
    method: 'GET',
  })
    .then((res) => {
      const csv = usersJsonToCSV(res, isTitleEnabled);
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURIComponent(
        csv,
      )}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'Users.csv';
      hiddenElement.click();
      return res;
    })
    .catch(() => {
      dispatch(
        showSnackbar({
          text: 'This action has failed to complete. No file downloaded. Please try again.',
          isError: true,
        }),
      );
    });
};
