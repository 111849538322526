/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
import React, { useCallback, useEffect } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import {
  Avatar, LeaveIcon, EditIcon, CaretLeftIcon,
} from '@himarley/unity';
import { Icon, IconButton, Button as ChakraButton } from '@chakra-ui/react';
import useScreenSize from '@app/components/Hooks/use-screen-size';
import MarleyIcon from '../icons/MarleyIcon';
import Notification from '../Notification/notification';
import {
  setConfigItem, muteAudio, openCreateForm, closeForm,
} from '../../actions/ui';
import { logout } from '../../actions/auth';
import {
  getAutoReplies,
  toggleAutoAssign,
  getUserProfile,
} from '../../actions/profile';
import TooltipInfo from '../elements/Tootip/TooltipInfo';
import SlideToggle from '../SlideToggle/SlideToggle';
import { checkPermissionsContains } from '../../helpers/common';
import { AUTO_ASSIGN_PERMISSIONS } from '../../constants/general';
import './Header.less';
import MuteIcon from '../icons/MuteIcon';
import { ProfileDropdown } from './ProfileDropdown';
import { caseType, userType } from '../../models/marleyTypes';
import { clearAddFormErrors } from '../../actions/common';
import { clearActiveJob } from '../../actions/job';

const Header = (props) => {
  const {
    showProfileConfig,
    setConfigItem,
    permissions,
    toggleAutoAssign,
    logout,
    muteAudio,
    muted,
    getAutoReplies,
    authUserId,
    disableAway,
    profile,
    isAway,
    awayMessage,
  } = props;

  useEffect(() => {
    getAutoReplies(authUserId);
  }, [authUserId]);

  const { onCreateCasePage, viewingJob } = useSelector((state) => ({
    onCreateCasePage: state?.jobs?.form?.isCreate,
    viewingJob: (state.ui?.chatView?.status === 'loading' || state.ui?.chatView?.status === 'loaded'),
  }));
  const dispatch = useDispatch();
  const history = useHistory();
  const handleOpenProfile = useCallback(() => {
    dispatch(getUserProfile(authUserId));
    history.push('/profile');
  }, [dispatch, authUserId, history]);

  const onProfilePage = history?.location?.pathname === '/profile';
  const isAutoAssign = _.get(profile, 'isAutoAssign', false);
  const isSmallScreen = useScreenSize();
  return (
    <header className="app-header">
      { isSmallScreen
        ? (
          <div className="mobile-friendly-header">
            {(onProfilePage || onCreateCasePage || viewingJob) ? (
              <IconButton
                className="profile-page-back-button"
                icon={<Icon as={CaretLeftIcon} />}
                onClick={() => {
                  history.push('/chats');
                  if (onCreateCasePage) {
                    dispatch(closeForm(caseType));
                  } else if (viewingJob) {
                    dispatch(clearActiveJob());
                  }
                }}
              />
            )
              : (
                <ChakraButton
                  variant="unstyled"
                  onClick={() => {
                    handleOpenProfile();
                    setConfigItem('GENERAL_INFO');
                  }}
                >
                  <Avatar
                    id={authUserId}
                    imageUrl={profile?.imageUrl}
                    name={`${profile?.firstName} ${profile?.lastName}`}
                    className="user-profile-photo"
                  />
                </ChakraButton>
              )}
            <MarleyIcon />
            { onProfilePage ? (
              <IconButton
                className="logout-button"
                icon={<Icon as={LeaveIcon} />}
                onClick={() => logout()}
              />
            )
              : (!onCreateCasePage && !viewingJob) ? (
                <IconButton
                  className="create-case-button"
                  icon={<Icon as={EditIcon} />}
                  onClick={() => {
                    dispatch(openCreateForm(caseType));
                    dispatch(clearAddFormErrors(userType.reducerCase()));
                  }}
                />
              ) : <div className="right-header-icon-placeholder" /> }
          </div>
        )
        : (
          <div className="app-header-inner">
            <div className="header-left">
              {process.env.REMOVE_WELCOME_PAGE_ENABLED ? (
                <div className="icon-box">
                  {!showProfileConfig && <MarleyIcon />}
                </div>
              ) : (
                <Link className="icon-box" to="/home">
                  {!showProfileConfig && <MarleyIcon />}
                </Link>
              )}
            </div>
            <Notification />
            <div className="header-profile" to="/profile">
              {checkPermissionsContains(permissions, AUTO_ASSIGN_PERMISSIONS) && (
              <div className="user-auto-assign-block">
                <div className="auto-assign" data-testid="auto-assign-cases">
                  Auto-Assign
                  <TooltipInfo
                    message="Will auto-assign cases from queue"
                    placement="bottom"
                  />
                </div>
                <SlideToggle on={isAutoAssign} onToggle={toggleAutoAssign} testId="auto-assign-btn" />
              </div>
              )}
              <Button
                className="mute-button"
                bsStyle="link"
                onClick={() => muteAudio(!muted)}
              >
                <MuteIcon muted={muted} />
              </Button>
              <ProfileDropdown
                disableAway={disableAway}
                profile={profile}
                authUserId={authUserId}
                awayMessage={awayMessage}
                setConfigItem={setConfigItem}
                isAway={isAway}
                logout={logout}
              />
            </div>
          </div>
        )}
    </header>
  );
};

Header.propTypes = {
  permissions: PropTypes.arrayOf(String),
  setConfigItem: PropTypes.func.isRequired,
  profile: PropTypes.instanceOf(Object).isRequired,
  authUserId: PropTypes.string.isRequired,
  showProfileConfig: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  muteAudio: PropTypes.func.isRequired,
  getAutoReplies: PropTypes.func.isRequired,
  muted: PropTypes.bool,
  toggleAutoAssign: PropTypes.func.isRequired,
  disableAway: PropTypes.bool.isRequired,
  isAway: PropTypes.bool.isRequired,
  awayMessage: PropTypes.instanceOf(Object).isRequired,
};

Header.defaultProps = {
  permissions: [],
  muted: false,
};

Header.contextTypes = {
  router: PropTypes.instanceOf(Object).isRequired,

};

export const mapDispatchToProps = {
  getAutoReplies,
  toggleAutoAssign,
  setConfigItem,
  muteAudio,
  logout,
};

const mapStateToProps = (state) => {
  const awayMessage = _.get(state.auth, 'userAutoReply.generalAutoReplies.list.0', {});
  const isAway = _.get(state.auth, 'userStatus', 'active') === 'away';
  return {
    score: _.get(state.auth, 'score'),
    authUserId: state.auth.user?._id,
    profile: state.auth.user,
    permissions: _.get(state.auth, 'permissions', []),
    showProfileConfig: state.ui && state.ui.showProfileConfig,
    muted: state.ui && state.ui.muted,
    isAway,
    awayMessage,
  };
};

const connectedHeader = connect(mapStateToProps, mapDispatchToProps)(Header);
export { connectedHeader };

export default withRouter(connectedHeader);
