/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Route, Switch, useHistory } from 'react-router-dom';
import _ from 'lodash';
import useScreenSize from '@app/components/Hooks/use-screen-size';
import CreateCaseModal from '@app/components/CreateCase/create-case-modal';
import App from '../App';
import ProfileConfig from '../ProfileConfig/profile-config';
import Dashboard from '../Dashboard';
import CustomCallback from '../CustomCallback/CustomCallback';
import { oktaAuthConfig, oktaSignInConfig, CALLBACK_PATH } from '../LoginWidget/config';
import Login from '../LoginWidget/Login';
import FeatureFlags from '../FeatureFlags/FeatureFlags';

const oktaAuth = new OktaAuth(oktaAuthConfig);

// eslint-disable-next-line react/jsx-filename-extension
const Application = (props) => (<App panel={_.get(props, 'panel')}><Dashboard {...props} /></App>);

export const Routes = () => {
  const history = useHistory();
  const isSmallScreen = useScreenSize();

  const customAuthHandler = () => {
    history.push('/login');
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    let postLoginPage = originalUri;
    if (isSmallScreen) {
      postLoginPage = '/chats';
    }
    history.replace(toRelativeUrl(postLoginPage, window.location.origin));
  };

  return (
    <Security
      className="security"
      oktaAuth={oktaAuth}
      onAuthRequired={isSmallScreen ? null : customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Switch>
        <Route path={CALLBACK_PATH} component={CustomCallback} />
        <Route path="/login" render={() => <Login config={oktaSignInConfig} />} />
        <SecureRoute exact path="/" render={(props) => <Application {...props} />} />
        <SecureRoute exact path="/admin/feature-flags" component={FeatureFlags} />
        <SecureRoute path="/:panelId/ref/:refId" render={(props) => <Application {...props} />} />
        <SecureRoute path="/:panelId/filter/:filterId" render={(props) => <Application {...props} />} />
        <SecureRoute path="/:panelId/:subNavId?" render={(props) => <Application {...props} />} />
        <SecureRoute path="/:panelId/create" component={CreateCaseModal} />
        <SecureRoute path="/case/:jobId?" render={(props) => <Application {...props} />} />
        <Route exact path="/profile" component={ProfileConfig} />
      </Switch>
    </Security>
  );
};
