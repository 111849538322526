import {
  Button, ButtonGroup, Tooltip, Radio, RadioGroup, HStack, Icon, Text,
} from '@chakra-ui/react';
import { InfoCircleIcon } from '@himarley/unity';
import React, { useState } from 'react';

import {
  CONFIRMED_TOTAL_LOSS_STATUS, DENIED_TOTAL_LOSS_STATUS,
} from '@app/constants/jobState';

import styles from './TotalLossPicker.module.less';
import MarleyAssistantMessage from '../MarleyAssistantMessage/MarleyAssistantMessage';

interface TotalLossPickerProps {
  onDismiss: () => void;
  activeJobId: string;
  patchJob: (activeJobId: string, args: { totalLossStatus?: string }) => void;
}

const TotalLossPicker: React.FC<TotalLossPickerProps> = ({
  onDismiss,
  activeJobId,
  patchJob,
}) => {
  const [totalLossStatus, setTotalLossStatus] = useState<string | undefined>();

  const updateTotalLossStatus = () => {
    console.log(activeJobId, totalLossStatus);
    patchJob(activeJobId, { totalLossStatus });
  };

  const tooltip = (
    <>
      <div>Our prediction model identified:</div>
      <div>Towed Away</div>
      <div>Airbags</div>
      <div>Auto Collision</div>
      <div>Flat Tires</div>
      <div>Ditch</div>
      <div>T-boned</div>
    </>
  );

  return (
    <MarleyAssistantMessage>
      <div className={styles.box}>
        <div>
          <div className={styles.title}>Predicted Total Loss</div>
          <div className={styles.body}>
            Based on the information so far this claim might be a total loss.
          </div>
        </div>
      </div>
      <RadioGroup>
        <HStack>
          <Radio
            name="isTotalLossSelection"
            value="totalLoss"
            onChange={() => setTotalLossStatus(CONFIRMED_TOTAL_LOSS_STATUS)}
          >
            Total Loss
          </Radio>
          <Radio
            name="isTotalLossSelection"
            value="notTotalLoss"
            onChange={() => setTotalLossStatus(DENIED_TOTAL_LOSS_STATUS)}
          >
            Not a Total Loss
          </Radio>
        </HStack>
      </RadioGroup>
      <HStack pt={2} justifyContent="flex-start">
        <ButtonGroup size="sm">
          <Button
            variant="outline"
            onClick={onDismiss}
          >
            Hide
          </Button>
          <Button
            onClick={updateTotalLossStatus}
          >
            Update Claim
          </Button>
        </ButtonGroup>
        <Text pl={1}>How do we predict this?</Text>
        <Tooltip label={tooltip} hasArrow>
          <Icon as={InfoCircleIcon} data-testid="prediction-tooltip" />
        </Tooltip>
      </HStack>
    </MarleyAssistantMessage>
  );
};

export default TotalLossPicker;
