import {
  Flex, Spacer, Icon, Grid, GridItem, Heading, Text, Input, HStack, Box,
} from '@chakra-ui/react';
import { AddCircleIcon } from '@himarley/unity';
import React, { useState, useEffect } from 'react';

import Preview from './Preview';

interface SystemMessagePrefixProps {
  autoMessagePrefixText: string;
  setAutoMessagePrefixText: (value: string) => void;
}

const SystemMessagePrefix: React.FC<SystemMessagePrefixProps> = (
  { autoMessagePrefixText = '', setAutoMessagePrefixText },
) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(autoMessagePrefixText.length);
  }, [autoMessagePrefixText]);

  const handlePrefixChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAutoMessagePrefixText(e.target.value);
  };
  const firstPreviewText = `${autoMessagePrefixText}${autoMessagePrefixText && ' | '}Lovable Insurance:`.trim();
  return (
    <HStack gap="10%">
      <Box w="50%">
        <Grid templateRows="repeat(3, 1fr)" templateColumns="repeat(3, 1fr)">
          <GridItem colSpan={3}>
            <Heading as="h1" size="md">
              System Message Prefix
            </Heading>
            <Text mb={2}>
              Displays as the beginning of outgoing SMS text messages sent
              automatically, such as the opt-In welcome message.
            </Text>
          </GridItem>
          <GridItem>
            <Flex>
              <Text color="gray.500" mb={0}>From</Text>
              <Spacer />
              <Text color="gray.500" mb={0}>
                {count}
                /50
              </Text>
            </Flex>
            <Input
              size="sm"
              data-testid="system-prefix-input"
              value={autoMessagePrefixText}
              maxLength={50}
              onChange={handlePrefixChange}
            />
          </GridItem>
          <GridItem justifyContent="center" display="flex" alignItems="center">
            <Icon as={AddCircleIcon} />
          </GridItem>
          <GridItem>
            <Text color="gray.500">Org/Brand Name</Text>
            <Text>$BrandName</Text>
          </GridItem>
          <GridItem colSpan={3}>
            <p>Ex: System Message, Network Message, Automated, etc.</p>
          </GridItem>
        </Grid>
      </Box>
      <Box w="50%">
        <Preview
          firstTextName={firstPreviewText}
          firstTextMessage="Please reply YES to agree to the terms and privacy policy and
                begin messaging."
          secondTextMessage="YES"
        />
      </Box>
    </HStack>
  );
};

export default SystemMessagePrefix;
