import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getiFrameLookUrl } from '@app/actions/analytics';
import Modal from '@app/components/chakra/modal';

import './LargeLookContent.less';

interface LargeLookContentProps {
  lookNumber: string;
  caseId?: string;
  closeLook: () => void;
}

const LargeLookContent: React.FC<LargeLookContentProps> = ({
  lookNumber,
  caseId = '',
  closeLook,
}) => {
  const dispatch = useDispatch();
  const { lookUrls = {} } = useSelector((state) => ({
    lookUrls: state?.analytics?.lookUrls || '',
  })) || {};

  useEffect(() => {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    timezone = timezone.replace(/\//gi, '-');
    dispatch(getiFrameLookUrl(caseId, lookNumber, timezone));
  }, [caseId, dispatch, lookNumber]);

  return (
    <Modal
      size="full"
      isModalOpen
      title=""
      toggleModal={closeLook}
    >
      <div className="large-look-content-container" data-testid="look-modal">
        <iframe
          className="large-look-iframe"
          title="look"
          src={lookUrls[lookNumber]}
          frameBorder="0"
        />
      </div>
    </Modal>
  );
};

export default LargeLookContent;
