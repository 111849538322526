/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import _ from 'lodash';
import FormField from '../../../../../Form/FormField/FormField';
import TimeOutDropdown from '../../../SharedComponents/TimeOutDropdown/time-out-dropdown';

const CreateOfficeHoursMsg = ({ reply, setReply }) => (
  <div className="create-office-hours-msg-container">
    <h2 className="create-office-hours-header">
      During Office Hours Message
    </h2>
    <FormField
      control={(
        <textarea
          data-jest="auto-reply-message-textarea"
          className="auto-reply-message-textarea"
          value={_.get(reply, 'message.during.value', '')}
          placeholder="This message will be automatically sent if you are inactive during regular business hours. (Optional)"
          maxLength={2000}
          onChange={(e) => {
            e.persist();
            setReply({
              ...reply,
              message: {
                ...reply.message,
                during: {
                  type: 'STRING',
                  value: e.target.value,
                },
              },
            });
          }}
        />
        )}
    />
    <div className="ooo-timeout">
      <p>Message will be sent when inactive for more than</p>
      <TimeOutDropdown reply={reply} setReply={setReply} />
    </div>
    <h2 className="create-office-hours-header">After Office Hours Message</h2>
    <FormField
      control={(
        <textarea
          data-jest="auto-reply-message-textarea"
          className="auto-reply-message-textarea"
          value={_.get(reply, 'message.after.value', '')}
          placeholder="This message will be automatically sent after regular business hours."
          maxLength={2000}
          onChange={(e) => {
            e.persist();
            setReply({
              ...reply,
              message: {
                ...reply.message,
                after: {
                  type: 'STRING',
                  value: e.target.value,
                },
              },
            });
          }}
        />
        )}
    />
  </div>
);

export default CreateOfficeHoursMsg;
