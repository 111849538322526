import { Button } from '@chakra-ui/react';
import React from 'react';

import { radioButtonValues } from './bulk-users-upload-modal.helpers';
import { CHANGE_UNIQUE_ID } from '../../../../../constants/general';
import RadioButton from '../../../../elements/RadioButton/RadioButton';
import TooltipInfo from '../../../../elements/Tootip/TooltipInfo';
import ImportCSVFile from '../../../../MassNotifications/CreateMassNotificationForm/import-csv-file';

interface ImportCSVProps {
  fileName: string;
  downloadCSVTemplate: (type: unknown) => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  handleFileDrop: () => void;
  handleClickSelectFile: () => void;
  handleFileInputChange: (event: unknown) => void;
  errorsList: string[];
  handleImportClick: () => void;
  ignoreOrUpdateVal: string;
  setIgnoreOrUpdateVal: (value: string) => void;
  type?: string;
}

const ImportCSV: React.FC<ImportCSVProps> = ({
  fileName,
  downloadCSVTemplate,
  fileInputRef,
  handleFileDrop,
  handleClickSelectFile,
  handleFileInputChange,
  errorsList,
  handleImportClick,
  ignoreOrUpdateVal,
  setIgnoreOrUpdateVal,
  type,
}) => (
  <div className="importCSVWrap">
    <ImportCSVFile
      currentFilename={fileName}
      downloadCSVTemplate={() => downloadCSVTemplate(type)}
      fileInputRef={fileInputRef}
      handleFileDrop={handleFileDrop}
      handleClickSelectFile={handleClickSelectFile}
      handleFileInputChange={handleFileInputChange}
      errorsList={errorsList}
    />
    {
      type === CHANGE_UNIQUE_ID ? null
        : (
          <div data-testid="existingUsersOptions">
            <div className="requirementsWrap">
              <h3>What would you like to do with existing users during an import?</h3>
              <TooltipInfo
                message="You have the option to update or skip existing Hi Marley users when importing. If you need to update existing user data choose the 'Update' option below. NOTE: You cannot undo this action after you import and update users."
                placement="top"
              />
            </div>
            <RadioButton
              currentValue={ignoreOrUpdateVal}
              values={radioButtonValues}
              setCheckedValue={setIgnoreOrUpdateVal}
              isHorizontal
              disabled={false}
            />
          </div>
        )
    }
    <div className="modalFooterWrap">
      <div>
        <div className="requirementsWrap">
          <span>Requirements</span>
          <TooltipInfo
            message="File type must be CSV. File size must be under 50 MB. File must have 10,000 rows or less. Column header names must match the same names as our Sample CSV template."
            placement="top"
          />
        </div>
      </div>
      <Button onClick={handleImportClick}>
        {type === CHANGE_UNIQUE_ID ? 'Update' : 'Import'}
      </Button>
    </div>
  </div>
);

export default ImportCSV;
