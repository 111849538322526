import {
  Box, HStack, Avatar, VStack, Text,
} from '@chakra-ui/react';
import React from 'react';

import { getShortenedEmail } from '@app/helpers/common';
import { User } from '@app/types/reducer-state';

interface UserRowProps {
  option: User;
  size?: 'sm' | 'md';
}

const UserRow: React.FC<UserRowProps> = ({ option, size = 'md' }) => (
  <Box w="100%">
    <HStack gap={3}>
      <Avatar
        size={size === 'md' ? 'sm' : 'xs'}
        id={option.id}
        src={option.imageUrl}
        name={(option.name || option.label || '').replace('Assign to', '').replace('(me)', '').trim()}
        borderRadius={size === 'md' ? 'md' : 'full'}
        bg="marleyRed.500"
        color="white"
      />
      <VStack gap={0} alignItems="flex-start">
        <Text m={0} fontSize={size} fontWeight={size === 'md' ? 'semibold' : 'normal'}>{option.label || option.name}</Text>
        {size === 'md' ? <Text mt={1} color="gray.500" mb={0}>{getShortenedEmail(option.email)}</Text> : ''}
      </VStack>
    </HStack>
  </Box>
);

export default UserRow;
