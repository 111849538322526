import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';

import GenericTextField from './fields/generic-text-field';
import SharedForm from './shared-form';

const GeneralForm = () => (
  <Grid width="100%" templateColumns={{ sm: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))' }} gap={4}>
    <SharedForm />
    <GridItem colSpan={2}>
      <GenericTextField
        label="Reference"
        name="reference"
      />
    </GridItem>
  </Grid>
);

export default GeneralForm;
