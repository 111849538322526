import React, { useMemo, useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import {
  updateSearchResultsCount,
  updateTotalChatCount,
} from '@app/actions/job';
import {
  subscribeToTopics,
  unsubscribeFromTopics,
} from '@app/actions/socket';
import { setGetJobsApiError } from '@app/actions/ui';
import useTopicHandler from '@app/components/elements/hooks/useTopicHandler';
import ErrorBoundary from '@app/components/ErrorBoundary/ErrorBoundary';
import useBroadcastChannel from '@app/components/Hooks/useBroadcastChannel';
import { usePrevious } from '@app/components/Hooks/usePrevious';
import { CHAT_VIEW_TOPIC } from '@app/constants/websocket';
import {
  getAllOperatorsTopics,
  manageInboxOperatorSubscriptions,
} from '@app/helpers/liveUpdates';
import { useCasesQuery } from '@app/services/case';
import { getOperators } from '@app/services/helpers';
import { StateType } from '@app/types/reducer-state';

import styles from './ChatInbox.module.less';
import getUnreadChatsCountTitle from './helpers';
import InboxError from './inbox-error';
import InboxContent from './InboxContent/inbox-content';
import InboxHeader from './InboxHeader/InboxHeader';
import { useSyncFiltersWithQueryParameters } from './useSyncFiltersWithQueryParameters';

const ChatInbox = () => {
  const {
    inboxFilters,
    tenantId,
    search,
    subscribedTopics,
    authId,
    activeChat,
    viewingjob,
  } = useSelector(
    (state: StateType) => ({
      inboxFilters: state?.profile?.properties?.inboxFilters || {},
      authId: state.auth.user._id || state.auth.user.id,
      tenantId: state.auth?.user?.organizationId,
      search: state.jobs?.search,
      fetchedFilterCount: state.profile?.fetchedFilterCount,
      subscribedTopics: state.socket?.subscribedTopics || {},
      activeChat: state.jobs?.activeChat || {},
      viewingjob: (state.ui?.chatView?.status === 'loading' || state.ui?.chatView?.status === 'loaded'),
    }),
    shallowEqual,
  );

  useBroadcastChannel();
  useSyncFiltersWithQueryParameters();
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const casesQuery = useCasesQuery({
    view: 'inbox',
    inboxFilters,
    sort: inboxFilters?.sort,
    order: inboxFilters?.order,
    secondarySort: inboxFilters?.secondarySort,
    secondaryOrder: inboxFilters?.secondaryOrder,
    searchMessages: search,
  });
  const memoizedTopicHandler = useTopicHandler();
  const allOperatorIds = getOperators(
    inboxFilters?.items,
    inboxFilters?.groups,
    inboxFilters?.operatorIds,
    authId,
  );
  const prevOperatorIds = usePrevious(allOperatorIds);
  const prevActiveChat = usePrevious(activeChat) || { id: '' };

  // Active Chat Topic Handling
  useEffect(() => {
    if (activeChat?.id && activeChat?.id !== prevActiveChat?.id) {
      const activeJobTopic = CHAT_VIEW_TOPIC(tenantId, activeChat.id);
      dispatch(subscribeToTopics([activeJobTopic], memoizedTopicHandler));
    }
    if (prevActiveChat?.id && activeChat?.id !== prevActiveChat?.id) {
      const prevActiveJobTopic = CHAT_VIEW_TOPIC(tenantId, prevActiveChat.id);
      dispatch(
        unsubscribeFromTopics([prevActiveJobTopic]),
      );
    }
  }, [
    activeChat?.id,
    prevActiveChat?.id,
    dispatch,
    memoizedTopicHandler,
    tenantId,
  ]);

  // Groups Topic Handling
  useEffect(() => {
    const allTopicArray = getAllOperatorsTopics(tenantId);
    const isAllTopicSet = subscribedTopics[allTopicArray[0] as keyof typeof subscribedTopics];
    const groups = Object.keys(inboxFilters?.groups || {});
    if (groups.length > 0 && !isAllTopicSet) {
      const topics = getAllOperatorsTopics(tenantId);
      dispatch(subscribeToTopics(topics, memoizedTopicHandler));
    }

    if (groups.length === 0 && isAllTopicSet) {
      dispatch(unsubscribeFromTopics(allTopicArray));
    }
  }, [
    inboxFilters?.groups,
    memoizedTopicHandler,
    tenantId,
    dispatch,
    subscribedTopics,
  ]);

  // Operators Topic Handling
  useEffect(() => {
    const groups = Object.keys(inboxFilters?.groups || {});
    // no need to manage operator subscriptions when a group is selected
    // because we are subscribed to the All Operator in that case
    if (groups.length > 0) {
      return;
    }
    const operatorIds = getOperators(
      inboxFilters?.items,
      inboxFilters?.groups,
      inboxFilters?.operatorIds,
      authId,
    );
    const { subscribeTopics, unsubscribeTopics } = manageInboxOperatorSubscriptions(
      tenantId,
      prevOperatorIds,
      operatorIds,
    );
    if (unsubscribeTopics.length > 0) {
      dispatch(unsubscribeFromTopics(unsubscribeTopics));
    }
    if (subscribeTopics.length > 0) {
      dispatch(subscribeToTopics(subscribeTopics, memoizedTopicHandler));
    }
  }, [
    inboxFilters?.items,
    inboxFilters?.operatorIds,
    inboxFilters?.groups,
    prevOperatorIds,
    tenantId,
    authId,
    dispatch,
    memoizedTopicHandler,
  ]);

  useEffect(() => {
    document.title = getUnreadChatsCountTitle(casesQuery?.data?.pages?.flat() || []);
    return () => {
      document.title = 'Marley Chat';
    };
  }, [casesQuery?.data?.pages]);

  const unpinnedChats = useMemo(
    () => casesQuery?.data?.pages?.flat()?.filter((job) => !job?.isPinned) || [],
    [casesQuery?.data?.pages],
  );

  const pinnedChats = useMemo(
    () => casesQuery?.data?.pages?.flat()?.filter((job) => job?.isPinned) || [],
    [casesQuery?.data?.pages],
  );

  useEffect(() => {
    dispatch(updateSearchResultsCount(unpinnedChats?.length));
  }, [dispatch, unpinnedChats?.length]);

  useEffect(() => {
    if (casesQuery?.isError) {
      dispatch(setGetJobsApiError(casesQuery.isError));
    }
  }, [casesQuery?.isError, dispatch]);

  const totalChats = useMemo(
    () => (unpinnedChats?.length || 0) + (search ? 0 : pinnedChats?.length || 0),
    [pinnedChats?.length, search, unpinnedChats?.length],
  );

  useEffect(() => {
    dispatch(
      updateTotalChatCount(totalChats),
    );
  }, [dispatch, pinnedChats.length, search, totalChats, unpinnedChats.length]);

  return (
    <nav className={`${styles.chatInbox} ${viewingjob ? styles.inboxViewingJob : ''}`} data-testid="chat-inbox">
      <InboxHeader
        totalChats={totalChats}
      />
      <section
        className={`${styles.inboxContentAndFooter} ${casesQuery?.isError ? styles.error : ''}`}
        style={{ height: '100vh' }}
        ref={containerRef}
      >
        {casesQuery?.isError ? (
          <InboxError />
        ) : (
          <ErrorBoundary errorMessage={(
            <InboxError text={(
              <>
                <span>Unable to load inbox.</span>
                <span>Please try again later or refresh.</span>
              </>
            )}
            />
          )}
          >
            <InboxContent
              isLoading={casesQuery?.isLoading}
              fetchNextPage={casesQuery?.fetchNextPage}
              isNextPageLoading={casesQuery?.isFetchingNextPage}
              hasNextPage={casesQuery?.hasNextPage || false}
              unpinnedChats={search ? [...pinnedChats, ...unpinnedChats] : unpinnedChats}
              pinnedChats={pinnedChats}
              hasPinnedChats={pinnedChats?.length > 0 && !search}
              containerRef={containerRef}
            />
          </ErrorBoundary>
        )}
      </section>
    </nav>
  );
};

export default ChatInbox;
