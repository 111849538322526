import { useState, useEffect } from 'react';

const useScreenSize = (): boolean => {
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth < 850);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 850);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isSmallScreen;
};

export default useScreenSize;
