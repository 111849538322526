import {
  Icon,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  ListItem,
  UnorderedList,
  Box,
  Flex,
} from '@chakra-ui/react';
import { FolderOpenIcon } from '@himarley/unity';
import React from 'react';
import { FileDrop } from 'react-file-drop';

import '../MassNotificationModal/MassNotificationModal.less';

interface ImportCSVModalProps {
  downloadCSVTemplate: () => void;
  handleFileDrop: (files: FileList | null) => void;
  currentFilename: string;
  handleClickSelectFile: () => void;
  fileInputRef: React.RefObject<HTMLInputElement>;
  handleFileInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorsList?: string[];
}

const ImportCSVModal: React.FC<ImportCSVModalProps> = ({
  downloadCSVTemplate,
  handleFileDrop,
  currentFilename,
  handleClickSelectFile,
  fileInputRef,
  handleFileInputChange,
  errorsList = [],
}) => (
  <span className="mass-notification-view-modal">
    <div className="subText">
      Download a
      {' '}
      <span
        role="link"
        tabIndex={0}
        className="customLink"
        onClick={downloadCSVTemplate}
        onKeyDown={downloadCSVTemplate}
      >
        sample CSV template
      </span>
      {' '}
      to see an example of the format required.
    </div>
    <div
      className={`${errorsList?.length > 0
        ? 'errorContainer fileUploadContainer flexCenter'
        : 'fileUploadContainer flexCenter'}`}
    >
      <div className="csvContainer flexCenter">
        <FileDrop onDrop={handleFileDrop}>null</FileDrop>
        <div className="csvUploadIconAndFileContainer">
          <div className="folderIcon flexCenter">
            <Icon color="gray.200" as={FolderOpenIcon} />
          </div>
          <div
            className={`csvFilename ${currentFilename ? '' : 'beforeDrop'}`}
          >
            {currentFilename || 'Drag and Drop'}
          </div>
        </div>
        <div className="fileStatusMessage green" />
      </div>
      <div className="uploadBtnContainer flexCenter">
        <div className="separator" />
        <div
          role="link"
          tabIndex={0}
          className="uploadFileBtn flexCenter"
          onClick={handleClickSelectFile}
          onKeyDown={handleClickSelectFile}
        >
          Select File
        </div>
      </div>
    </div>
    <input
      type="file"
      ref={fileInputRef}
      className="finalFileInput"
      onChange={handleFileInputChange}
    />
    {errorsList?.length > 0 && (
      <Alert status="error" mb={6} pr={4}>
        <Box flex="1">
          <Flex justify="space-between" align="flex-start">
            <AlertTitle>Errors found while importing users</AlertTitle>
            <AlertIcon mr={0} />
          </Flex>
          <AlertDescription>
            <Box maxHeight="200px" overflowY="auto">
              <UnorderedList pt={2} mb={0}>
                {errorsList.map((errorText) => (
                  <ListItem>{errorText}</ListItem>
                ))}
              </UnorderedList>
            </Box>
          </AlertDescription>
        </Box>
      </Alert>
    )}
  </span>
);

export default ImportCSVModal;
