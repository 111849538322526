/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get, includes } from 'lodash';
import Menu from '@app/components/chakra/menu';

import {
  MoreIcon,
  UploadIcon,
  DownloadIcon,
  UserIcon,
  Ghosts,
  CalendarIcon,
  AtoZIcon,
  ZtoAIcon,
  AddUserIcon,
  AddCircleIcon,
  CheckmarkFilledIcon,
  TrashIcon,
  EditIcon,
} from '@himarley/unity';
import {
  Button, Icon, IconButton, Box,
} from '@chakra-ui/react';
import AssignRoleActionMenu from '@app/components/ActionMenu/assign-role-action-menu';
import Modal, { useModal } from '@app/components/chakra/modal';
import { operatorType } from '../../../../models/marleyTypes';
import Panel from '../../../Panel/Panel';
// Elements
import TableRow from '../../../elements/table/TableRow/TableRow'; // eslint-disable-line
import TableCell from '../../../elements/table/TableCell/TableCell';
import { SortTable } from '../../../elements/table/SortTable/SortTable';
import TableHeader from '../../../elements/table/SortTable/TableHeader/TableHeader';
import DisabledBanner from '../../../elements/Banner/DisabledBanner/DisabledBanner';

import UserPhoto2 from '../../../UserPhoto2/UserPhoto2';
import EnterOperator from '../../../EnterOperator/EnterOperator';
import SearchBar from '../../../SearchBar/SearchBar';
import ResetPassword from '../../../confirmationModals/ResetPassword/ResetPassword'; // eslint-disable-line
import DeleteUser from '../../../confirmationModals/DeleteUser/DeleteUser'; // eslint-disable-line
import selector from '../../../../selectors/usermgmt';
import { formatPhoneNumber } from '../../../../helpers/format';
import { isPasswordlessAuthSession } from '../../../../helpers/common';
import {
  createUsers,
  updateOperatorsUniqueIds,
  getOrganizationRoles,
  downloadCSVTemplate,
  getUsersToExport,
  getActiveOperatorCount,
} from '../../../../actions/users';
import { setConfigItem } from '../../../../actions/ui';
import { showSnackbar } from '../../../../actions/notification';
import {
  getUserProfile,
  getListOfTitles,
  persistUserProperties,
} from '../../../../actions/profile';
import withLazyLoad from '../../../withLazyLoad/withLazyLoad';
import withLoading from '../../../HigherOrderComponents/withLoading';
import './UserMgmt.less';
import BulkUsersUploadModal from './BulkUsersUpload/BulkUsersUploadModal';
import { CHANGE_UNIQUE_ID } from '../../../../constants/general';

const columns = [
  {
    id: 'user-photo',
    sort: false,
  },
  {
    id: 'name-table-format',
    label: 'Name',
    sort: false,
    location: 'nameTableFormat',
    secondaryLocation: 'title',
  },
  {
    id: 'last-login-time',
    label: 'Last Login',
    sort: false,
    location: 'lastLoginTime',
    numericSort: true,
  },
  {
    id: 'phone-number',
    label: 'Phone Number',
    sort: false,
    location: ['displayPhoneNumber', 'contactNumber'],
    format: ({ value }) => <div>{value ? formatPhoneNumber(value) : ''}</div>, // eslint-disable-line
    numericSort: true,
  },
  {
    id: 'okta-user-id',
    label: 'Unique Id',
    sort: false,
    location: 'oktaUserId',
    copyEnabled: true,
  },
  {
    id: 'email',
    label: 'Email',
    sort: false,
    location: 'email',
    copyEnabled: true,
  },
  {
    id: 'role',
    label: 'Role',
  },
  {
    id: 'actions',
    label: 'Actions',
  },
];

const sortMenuItems = [
  {
    id: 'name-a-z',
    label: 'Name (A-Z)',
    leftIcon: AtoZIcon,
    sort: {
      order: 'ASC',
      column: 'profile.firstName',
    },
  },
  {
    id: 'name-z-a',
    label: 'Name (Z-A)',
    leftIcon: ZtoAIcon,
    sort: {
      order: 'DESC',
      column: 'profile.firstName',
    },
  },
  {
    id: 'last-login-newest',
    label: 'Last Login (Newest)',
    leftIcon: CalendarIcon,
    sort: {
      order: 'DESC',
      column: 'lastLoginTime',
    },
  },
  {
    id: 'last-login-oldest',
    label: 'Last Login (Oldest)',
    leftIcon: CalendarIcon,
    sort: {
      order: 'ASC',
      column: 'lastLoginTime',
    },
  },
  {
    id: 'added-user-newest',
    label: 'Added User (Newest)',
    leftIcon: AddUserIcon,
    sort: {
      order: 'DESC',
      column: 'createdAt',
    },
  },
  {
    id: 'added-user-oldest',
    label: 'Added User (Oldest)',
    leftIcon: AddUserIcon,
    sort: {
      order: 'ASC',
      column: 'createdAt',
    },
  },
];

const UserMgmt = ({
  createUsers,
  updateOperatorsUniqueIds,
  isLoading,
  increaseDataSet,
  auth,
  getOrganizationRoles,
  getUserProfile,
  modifyQuery,
  loadedItems,
  requestCompleted,
  showSnackbar,
  getListOfTitles,
  getUsersToExport: getUsersToExportProp,
  setConfigItem,
  activeOperatorCount,
  getActiveOperatorCount: getActiveOperatorCountProp,
  isFetchingActiveOperatorCount,
  persistUserProperties: persistUserPropertiesProp,
  userManageSettings,
}) => {
  const [sorting, setSorting] = useState({});
  const { isModalOpen, toggleModal } = useModal();

  const getSelectedItemIndex = useMemo(
    () => {
      const foundIndex = sortMenuItems.findIndex(
        (item) => item?.sort?.order === userManageSettings?.order
          && item?.sort?.column === userManageSettings?.column,
      );
      const selectionIndex = foundIndex === -1 ? 0 : foundIndex;
      const { column, order } = sortMenuItems[selectionIndex].sort;
      setSorting(sortMenuItems[selectionIndex].sort || {});
      modifyQuery({ searchText: '' }, order, column);
      return selectionIndex;
    },
    [modifyQuery, userManageSettings],
  );

  const [isBulkUploadModalShown, setIsBulkUploadModalShown] = useState(false);
  const [currentSortIndex, setCurrentSortIndex] = useState(
    getSelectedItemIndex > 0 ? getSelectedItemIndex : 0,
  );
  const [modalType, setModalType] = useState();
  const [searchText, setSearchText] = useState('');

  const history = useHistory();
  const handleOpenProfile = useCallback(
    () => history.push('/profile'),
    [history],
  );

  useEffect(() => {
    getOrganizationRoles();
  }, []);

  useEffect(() => {
    getListOfTitles();
  }, []);

  useEffect(() => {
    if (requestCompleted) {
      toggleModal(false);
    }
  }, [requestCompleted]);

  useEffect(() => {
    getActiveOperatorCountProp();
  }, [getActiveOperatorCountProp]);

  const onSearchTextChange = useCallback(
    (e) => {
      const searchTextVal = get(e, 'target.value');
      const { order, column } = sorting || {};
      modifyQuery({ searchText: searchTextVal }, order, column);
      setSearchText(searchTextVal);
    },
    [modifyQuery, sorting],
  );
  const passwordlessAuthSession = isPasswordlessAuthSession(auth);
  const nonFederatedUserActions = [
    !passwordlessAuthSession && {
      id: 'resetpassword',
      label: 'Reset Password',
      confirmation: true,
    },
    {
      id: 'userdelete',
      label: 'Delete User',
      confirmation: true,
      leftIcon: TrashIcon,
    },
  ].filter(Boolean);

  const getActionsList = useMemo(() => {
    const authUserRoles = auth?.user?.roles;
    const actionList = includes(authUserRoles, 'ADMIN')
      ? [
        {
          id: 'editOperator',
          label: 'Edit Profile',
          confirmation: false,
          action: (userId) => {
            getUserProfile(userId);
            handleOpenProfile();
            setConfigItem('GENERAL_INFO');
          },
          leftIcon: EditIcon,
        },
        ...nonFederatedUserActions,
      ]
      : nonFederatedUserActions;

    return actionList;
  }, [
    auth?.user?.roles,
    nonFederatedUserActions,
    getUserProfile,
    handleOpenProfile,
    setConfigItem,
  ]);

  const userColumns = columns?.map((col) => (
    <TableHeader key={col.id || col.label} {...col} tableId="user" />
  ));

  const jit = get(auth, 'user.organization.jit', false);

  const toggleImportCSVModal = useCallback(
    (val, type) => {
      setIsBulkUploadModalShown(val);
      setModalType(type);
    },
    [setIsBulkUploadModalShown],
  );

  const extendedItemSize = 72;

  const handleSortSelection = useCallback(
    (item, index) => {
      const { order, column } = item?.sort || {};
      modifyQuery({ searchText }, order, column);
      setSorting(item?.sort || {});
      persistUserPropertiesProp({
        userManageSettings: item?.sort,
      });
      setCurrentSortIndex(index);
    },
    [modifyQuery, persistUserPropertiesProp, searchText],
  );

  const renderSortDropdown = useMemo(
    () => (
      <Menu
        id="user-sort"
        options={sortMenuItems.map((item, index) => ({
          ...item,
          index,
          onClick: () => handleSortSelection(item, index),
          rightIcon: currentSortIndex === index ? CheckmarkFilledIcon : null,
        }))}
        defaultLabel={sortMenuItems[currentSortIndex]?.label}
      />
    ),
    [currentSortIndex, handleSortSelection],
  );

  const renderPanel = useMemo(
    () => (
      <Panel
        className="user-mgmt-page"
        title={(
          <div className="title-actions-wrap">
            <div>Users</div>
            <div className="user-actions">
              {jit && (
                <DisabledBanner
                  includesLink
                  linkRef="https://himarley.zendesk.com/hc/en-us/articles/1500009297302-Auto-provisioning-Capabilities-and-Limitations-"
                  linkText="Just-In-Time (JIT) Provisioning"
                  bannerText="&nbsp;has been setup for your organization."
                />
              )}
              {!jit && (
                <Button
                  leftIcon={<Icon as={AddCircleIcon} />}
                  onClick={toggleModal}
                >
                  Add User
                </Button>
              )}
              <Menu
                id="user-actions"
                menuButtonProps={{
                  icon: MoreIcon,
                  as: IconButton,
                  variant: 'ghost',
                }}
                sx={{ fontSize: 'unset !important' }}
                options={[
                  {
                    id: 'upload',
                    label: <Box fontSize="md">Import Users</Box>,
                    leftIcon: UploadIcon,
                    onClick: () => toggleImportCSVModal(true),
                  },
                  {
                    id: 'export',
                    label: <Box fontSize="md">Export Users</Box>,
                    leftIcon: DownloadIcon,
                    onClick: () => getUsersToExportProp(),
                  },
                  {
                    id: 'change-password',
                    label: <Box fontSize="md">Change Unique User IDs</Box>,
                    leftIcon: UserIcon,
                    onClick: () => toggleImportCSVModal(true, CHANGE_UNIQUE_ID),
                  },
                ]}
              />
            </div>
          </div>
        )}
        header={(
          <div className="count-search-bar">
            <p data-testid="active-operator-count">
              {isFetchingActiveOperatorCount === true ? (
                <Ghosts numItems={1} />
              ) : (
                `${activeOperatorCount} User${
                  activeOperatorCount !== 1 ? 's' : ''
                }`
              )}
            </p>
            <div className="searchSortWrap">
              <div className="searchExportWrap">
                <span>Search</span>
                <SearchBar
                  placeholder="Search Users..."
                  onChange={onSearchTextChange}
                />
              </div>
              <div className="usersSortWrap">
                <span>Sort</span>
                {renderSortDropdown}
              </div>
            </div>
          </div>
        )}
      >
        <ResetPassword />
        <DeleteUser />

        <SortTable
          label="users"
          columns={userColumns}
          increaseDataSet={increaseDataSet}
          isLoading={isLoading}
          extendedItemSize={extendedItemSize}
          rows={loadedItems.map((user) => (
            <TableRow
              id="user"
              key={user.id}
              itemId={user.id}
              actions={getActionsList}
              columns={columns.slice(1, columns.length - 2)}
              item={user}
              header={(
                <TableCell id="user-photo">
                  <UserPhoto2
                    id={user.id}
                    imageUrl={user.imageUrl}
                    name={user.name}
                  />
                </TableCell>
              )}
              useNewActionMenu
            >
              <TableCell id="role">
                <AssignRoleActionMenu user={{ ...user }} />
              </TableCell>
            </TableRow>
          ))}
        />
      </Panel>
    ),
    [
      jit,
      toggleModal,
      isFetchingActiveOperatorCount,
      activeOperatorCount,
      onSearchTextChange,
      renderSortDropdown,
      userColumns,
      increaseDataSet,
      isLoading,
      loadedItems,
      toggleImportCSVModal,
      getUsersToExportProp,
      getActionsList,
    ],
  );

  return (
    <>
      {isBulkUploadModalShown && (
        <BulkUsersUploadModal
          type={modalType}
          createUsers={createUsers}
          updateOperatorsUniqueIds={updateOperatorsUniqueIds}
          downloadCSVTemplate={downloadCSVTemplate}
          show={isBulkUploadModalShown}
          onClose={() => toggleImportCSVModal(false)}
          showSnackbar={showSnackbar}
          modifyQuery={modifyQuery}
        />
      )}
      {renderPanel}
      <Modal
        title="Create Operator"
        toggleModal={toggleModal}
        isModalOpen={isModalOpen}
      >
        <EnterOperator />
      </Modal>
    </>
  );
};

UserMgmt.propTypes = {
  getOrganizationRoles: PropTypes.func.isRequired,
  increaseDataSet: PropTypes.func.isRequired,
  loadedItems: PropTypes.arrayOf(Object),
  modifyQuery: PropTypes.func.isRequired,
  requestCompleted: PropTypes.bool.isRequired,
  modifiedUserID: PropTypes.string.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  createUsers: PropTypes.func.isRequired,
  updateOperatorsUniqueIds: PropTypes.func.isRequired,
  getListOfTitles: PropTypes.func,
  setConfigItem: PropTypes.func,
  isLoading: PropTypes.bool,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      roles: PropTypes.string,
    }),
  }).isRequired,
  showSnackbar: PropTypes.bool,
  getUsersToExport: PropTypes.func,
  getActiveOperatorCount: PropTypes.func.isRequired,
  activeOperatorCount: PropTypes.string.isRequired,
  isFetchingActiveOperatorCount: PropTypes.bool.isRequired,
  persistUserProperties: PropTypes.func.isRequired,
  userManageSettings: PropTypes.shape({
    order: PropTypes.string,
    column: PropTypes.string,
  }).isRequired,
};

UserMgmt.defaultProps = {
  loadedItems: [],
  getListOfTitles: () => {},
  setConfigItem: () => {},
  getUsersToExport: () => {},
  isLoading: false,
  showSnackbar: false,
};

export const mapStateToProps = (state) => ({
  ...selector(state, columns),
  type: operatorType,
  sort: get(state, 'ui.sort.user'),
  auth: get(state, 'auth'),
});

export const mapDispatchToProps = {
  createUsers,
  updateOperatorsUniqueIds,
  getOrganizationRoles,
  getUserProfile,
  showSnackbar,
  getUsersToExport,
  getListOfTitles,
  setConfigItem,
  getActiveOperatorCount,
  persistUserProperties,
};

const UserMgmtWithLoading = withLoading(UserMgmt, { type: operatorType });

const LoadedUsers = withLazyLoad(UserMgmtWithLoading, {
  type: operatorType,
  listLocation: 'users',
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadedUsers);
