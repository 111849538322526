import React, {
  useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSecureRequest from '../../../hooks/secure-request';
import secureRequest from '../../../helpers/secure-request';
import MessageTemplateModal from './MessageTemplateModal';
import {
  searchMessageTemplatesByText,
  templateStateFactory,
  toggleMessageTemplatePin,
  selectQueryPath,
  TEMPLATE_TAB_LOB,
  TEMPLATE_TAB_ORG,
} from './template-helpers';
import { PIN_MESSAGE_TEMPLATE_V2, SELECT_MESSAGE_TEMPLATE_V2 } from '../../../constants/endpoints';
import { templatesSlice } from '../../../slices/templates';
import { setTemplateTrayIsOpen } from '../../../actions/ui';
import useMountTransition from './TransitionWrapper';

const MessageTemplateModalContainer = () => {
  const { selectTemplate } = templatesSlice.actions;
  const dispatch = useDispatch();

  const {
    activeJob, isMounted,
  } = useSelector((state) => ({
    isMounted: !!state.ui?.templateTrayIsOpen,
    activeJob: {
      // eslint-disable-next-line no-underscore-dangle
      id: state.jobs?.activeJob?._id,
      lineOfBusiness: state.jobs?.activeJob?.lineOfBusiness || null,
    },
  }));
  const [activeTab, setActiveTab] = useState(TEMPLATE_TAB_LOB);
  const { requestState, parsedBody } = useSecureRequest(
    selectQueryPath(activeJob, activeTab, isMounted),
    { method: 'GET' },
  );
  const [messageTemplates, setMessageTemplates] = useState(templateStateFactory());
  const [filteredMessageTemplates, setFilteredMessageTemplates] = useState(templateStateFactory());
  const [searchTextValue, setSearchTextValue] = useState('');

  const hasTransitionedIn = useMountTransition(isMounted, 1000);

  useEffect(() => {
    if (requestState === 'success') {
      const updatedMessageTemplatesState = templateStateFactory();
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(parsedBody)) {
        updatedMessageTemplatesState[key] = parsedBody[key];
      }
      setMessageTemplates(updatedMessageTemplatesState);
      setFilteredMessageTemplates(updatedMessageTemplatesState);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestState, parsedBody]);

  useEffect(() => {
    // clear search text if tab is changed
    setSearchTextValue('');
  }, [activeTab]);

  const onPinButtonClick = (templateId, pin = true) => async () => {
    // 1. fetch pinned templates
    const response = await secureRequest(PIN_MESSAGE_TEMPLATE_V2(templateId), {
      method: pin ? 'POST' : 'DELETE',
    });
    if (response.ok) {
      // 2. update list item of template in local state
      const updatedState = toggleMessageTemplatePin(templateId, messageTemplates, pin);
      const updatedFilteredState = toggleMessageTemplatePin(
        templateId,
        filteredMessageTemplates,
        pin,
      );
      setMessageTemplates(updatedState);
      setFilteredMessageTemplates(updatedFilteredState);
    }
  };

  const onSearchTextChange = (value) => {
    setSearchTextValue(value);
    const filteredTemplatesList = searchMessageTemplatesByText(messageTemplates, value);
    return setFilteredMessageTemplates(filteredTemplatesList);
  };

  // triggered when clicking outside of slider and on close button
  const onModalClose = () => {
    dispatch(setTemplateTrayIsOpen(false));
  };

  const onTemplateSelect = async ({ id, category }) => {
    const body = { jobId: activeJob.id };
    if (activeTab === TEMPLATE_TAB_ORG) {
      body.lineOfBusinessId = null;
    }
    if (category) body.category = category;
    const response = await secureRequest(SELECT_MESSAGE_TEMPLATE_V2(id), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const json = await response.json();
    const { populatedTemplate } = json;
    dispatch(selectTemplate({ text: populatedTemplate }));
    dispatch(setTemplateTrayIsOpen(false));
  };
  return (
    <MessageTemplateModal
      onModalClose={onModalClose}
      modal={{
        hasTransitionedIn,
        isMounted,
      }}
      requestState={requestState}
      messageTemplates={filteredMessageTemplates}
      setMessageTemplates={setMessageTemplates}
      onSearchTextChange={onSearchTextChange}
      searchTextValue={searchTextValue}
      onPinButtonClick={onPinButtonClick}
      onTemplateSelect={onTemplateSelect}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      activeJob={activeJob}
    />
  );
};

export default MessageTemplateModalContainer;
