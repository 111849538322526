import {
  Modal,
  ModalOverlay,
  ModalContent,
  Button,
  ModalBody,
  ModalFooter,
  Text,
  ModalCloseButton,
  ButtonGroup,
} from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';

interface MassNotificationDeleteVerifyModalProps {
  show: boolean;
  toggleShow: (id?: string) => void;
  deleteNotification: (id: string) => void;
  activeMassNotification: unknown;
}

const MassNotificationDeleteVerifyModal: React.FC<MassNotificationDeleteVerifyModalProps> = ({
  show,
  toggleShow,
  deleteNotification,
  activeMassNotification,
}) => (
  <Modal
    onClose={toggleShow}
    data-jest="mass-notification-modal"
    isOpen={show}
  >
    <ModalOverlay />
    <ModalContent>
      <ModalCloseButton />
      <ModalBody mt={6}>
        <Text textAlign="center" fontSize="xl">
          Are you sure you want to delete this mass notification?
        </Text>
      </ModalBody>
      <ModalFooter alignContent="center">
        <ButtonGroup>
          <Button onClick={() => toggleShow()} variant="outline">Cancel</Button>
          <Button
            onClick={() => {
              deleteNotification(_.get(activeMassNotification, 'id') || '');
              toggleShow();
            }}
          >
            Yes
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default MassNotificationDeleteVerifyModal;
