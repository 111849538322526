import {
  Alert, CloseButton, AlertTitle, AlertDescription, Box,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { closeNotification } from '../../actions/notification';

const NOTIFICATION_TIMEOUT = 10000;

const Notification = () => {
  const dispatch = useDispatch();
  const notification = useSelector((state) => state.notification?.notification);

  useEffect(() => {
    if (notification && !notification.persistent) {
      const notificationTimeout = setTimeout(() => {
        dispatch(closeNotification(notification));
      }, NOTIFICATION_TIMEOUT);

      return () => clearTimeout(notificationTimeout);
    }
    return undefined;
  }, [dispatch, notification]);

  const splitNotification = (notification?.text ?? '').split('%l');
  const links = notification?.links ?? [];
  const linksComponent = links.map(({ label, url }) => (
    <a href={url} target="_blank" rel="noreferrer">{label}</a>
  ));

  const notificationText: Array<string | React.JSX.Element> = [];
  splitNotification.forEach((n) => {
    notificationText.push(n);
    const LinksComponent = linksComponent.shift();
    if (LinksComponent) notificationText.push(LinksComponent);
  });

  return (
    <Box mt={1}>
      {notification && notification.show && (
      <Alert status={notification.type || 'error'}>
        <AlertTitle>
          {notificationText}
        </AlertTitle>
        <AlertDescription>
          {notification.explanation && `${notification.explanation}`}
        </AlertDescription>
        {notification.canClose && (
          <CloseButton position="absolute" right={1} top={2} onClick={() => dispatch(closeNotification(notification))} />
        )}
      </Alert>
      )}
    </Box>
  );
};

export default Notification;
