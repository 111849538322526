import {
  Input,
  Box,
  Flex,
  Portal,
  Text,
  Icon,
  InputLeftElement,
  InputGroup,
  FormControl,
  FormLabel,
  HStack,
} from '@chakra-ui/react';
import { AddCircleIcon } from '@himarley/unity';
import { useQuery } from '@tanstack/react-query';
import React, {
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { formatPhoneNumberV2, cleanPhone } from '@app//helpers/format';
import { QUERY_END_USERS } from '@app/constants/endpoints';
import { postData } from '@app/hooks/react-query-helpers';
import { type Contact, type IFormInput } from '@app/types/create-case';

interface ContactSearchMenuProps {
  setSelectedContact: Dispatch<SetStateAction<Contact | null>>;
  setIsNewContact: Dispatch<SetStateAction<boolean>>;
}

const ContactSearchMenu: React.FC<ContactSearchMenuProps> = (
  { setSelectedContact, setIsNewContact },
) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { setValue } = useFormContext<IFormInput>();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const phone = queryParams.get('phone');

  useEffect(() => {
    if (phone) {
      setSearchValue(formatPhoneNumberV2(phone));
    }
  }, [phone, setValue]);

  const boxRef = useRef<HTMLDivElement | null>(null);
  const inputBoxRef = useRef<HTMLInputElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const rawPhoneNumber = cleanPhone(searchValue);
  const shouldQueryPhoneNumber = rawPhoneNumber.length > 7;

  const phoneNumberQuery = useQuery({
    queryKey: ['phoneNumbers', rawPhoneNumber],
    queryFn: () => postData(QUERY_END_USERS, {
      search: { phoneNumber: rawPhoneNumber },
    }),
    enabled: shouldQueryPhoneNumber,
  });

  const { data: users } = phoneNumberQuery?.data || {};

  useEffect(() => {
    if (shouldQueryPhoneNumber && users) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }, [shouldQueryPhoneNumber, users]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchValue(formatPhoneNumberV2(value));
    if (cleanPhone(value).length > 7) {
      setIsMenuOpen(true);
    }
  };

  const handleCreateNewContact = () => {
    setIsNewContact(true);
    setValue('phoneNumber', searchValue, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setIsMenuOpen(false);
  };

  const userOptions = useMemo(() => {
    const handleSelect = (user: Contact) => {
      Object.entries({
        id: user.id,
        phoneNumber: user.phoneNumber,
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        email: user.email || '',
      }).forEach(([field, value]) => setValue(field as keyof IFormInput, value || '', {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }));

      // Update parent state first
      setSelectedContact(user);

      // Then update local state
      setSelectedOption(formatPhoneNumberV2(user.phoneNumber));
      setSearchValue('');
      setIsMenuOpen(false);

      // Finally ensure we're not in new contact mode
      setIsNewContact(false);
    };

    const options = [];
    if (users?.length) {
      options.push(
        ...users.map((user: Contact) => ({
          id: user.id,
          label: (
            <Box>
              <Text fontSize="md">
                {user.firstName}
                {' '}
                {user.lastName}
              </Text>
              <Text color="gray.500" fontSize="sm">
                {formatPhoneNumberV2(user.phoneNumber)}
              </Text>
            </Box>
          ),
          onClick: () => handleSelect(user),
        })),
      );
    }

    return options;
  }, [users, setSelectedContact, setIsNewContact, setValue]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <Box ref={boxRef} w="100%" position="relative">
      <Flex>
        <Box ref={inputBoxRef} w="100%">
          <FormControl>
            <FormLabel>Mobile Number</FormLabel>
            <InputGroup>
              <InputLeftElement>+1</InputLeftElement>
              <Input
                ref={inputRef}
                value={selectedOption || searchValue}
                onChange={handleSearchChange}
                onFocus={() => {
                  if (shouldQueryPhoneNumber) {
                    setIsMenuOpen(true);
                  }
                }}
                autoComplete="off"
              />
            </InputGroup>
          </FormControl>
        </Box>
        {isMenuOpen && (
          <Portal>
            <Box
              position="fixed"
              zIndex={2000}
              bg="white"
              borderRadius="md"
              boxShadow="lg"
              mt={1}
              width={inputBoxRef.current?.getBoundingClientRect().width}
              style={{
                top: inputBoxRef.current
                  ? inputBoxRef.current.getBoundingClientRect().bottom
                    + window.scrollY
                  : 0,
                left: inputBoxRef.current
                  ? inputBoxRef.current.getBoundingClientRect().left
                    + window.scrollX
                  : 0,
              }}
            >
              {userOptions.map((option) => (
                <Box
                  key={option.id}
                  onClick={option.onClick}
                  p={2}
                  _hover={{ bg: 'gray.50', borderRadius: 'md' }}
                  cursor="pointer"
                >
                  {option.label}
                </Box>
              ))}
              {!userOptions.length && shouldQueryPhoneNumber && (
                <Box
                  onClick={handleCreateNewContact}
                  p={2}
                  _hover={{ bg: 'gray.50', borderRadius: 'md' }}
                  cursor="pointer"
                >
                  <HStack gap={1}>
                    <Icon as={AddCircleIcon} />
                    <Text>Create a new Contact</Text>
                  </HStack>
                </Box>
              )}
            </Box>
          </Portal>
        )}
      </Flex>
    </Box>
  );
};

export default ContactSearchMenu;
