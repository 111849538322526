import {
  FormControl, FormLabel, Input, Radio, RadioGroup, HStack, Button, ButtonGroup,
} from '@chakra-ui/react';
import React from 'react';

interface CreateMassNotificationThrottlingProps {
  createButtonEnabled: boolean;
  handleBackStep: () => void;
  handleChangeThrottleEnabled: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleFormInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isScheduleEnabled: boolean;
  isThrottleEnabled: boolean;
  showErrorMessage: boolean;
  formData: {
    throttleRate: number;
  };
}

const CreateMassNotificationThrottling: React.FC<CreateMassNotificationThrottlingProps> = ({
  createButtonEnabled,
  handleBackStep,
  handleChangeThrottleEnabled,
  handleClickSubmit,
  handleFormInputChange,
  isScheduleEnabled,
  isThrottleEnabled,
  showErrorMessage,
  formData,
}) => (
  <>
    <div className="modal-container">
      <h3 className="modal-step-title">Step 3: Message Rate</h3>
      <p>
        You can set a rate at which messages are sent out hourly or you can
        send the notification in full.
      </p>
      <RadioGroup value={isThrottleEnabled ? 'yes' : 'no'}>
        <HStack>
          <Radio
            id="throttleEnabledYes"
            checked={isThrottleEnabled}
            name="isThrottleEnabled"
            onChange={handleChangeThrottleEnabled}
            type="radio"
            value="yes"
            size="sm"
            mr={0}
          >
            Set an hourly message rate
          </Radio>
          <Radio
            id="throttleEnabledNo"
            checked={!isThrottleEnabled}
            name="isThrottleEnabled"
            onChange={handleChangeThrottleEnabled}
            type="radio"
            value="no"
            size="sm"
            mr={0}
          >
            Send notification in full
          </Radio>
        </HStack>
      </RadioGroup>
      {
        isThrottleEnabled ? (
          <>
            <h4 className="modal-section-title">Set Message Rate</h4>
            <p>Set the maximum number of messages to be sent out each hour.</p>
            <div className="notification-scheduled-container">
              <FormControl>
                <FormLabel>Hourly Message Rate</FormLabel>
                <Input
                  type="number"
                  min={1000}
                  name="throttleRate"
                  onChange={handleFormInputChange}
                  value={formData.throttleRate}
                  size="sm"
                />
              </FormControl>
            </div>
            <h5 className="modal-section-subtitle">Active Sending Hours</h5>
            <p className="modal-section-disclaimer">
              Messages will only be sent between 9:00 AM and 8:00 PM in the destination timezone.
              Message sending will pause outside of these hours and will resume the following day.
            </p>
          </>
        ) : null
      }
    </div>
    <ButtonGroup className="buttonRow">
      {
        showErrorMessage
          ? <div className="warningText errorText">Failed to create Mass Notification, please try again later!</div>
          : null
      }
      <Button
        onClick={handleBackStep}
        variant="outline"
      >
        Back
      </Button>
      <Button
        onClick={handleClickSubmit}
        isDisabled={
          !createButtonEnabled
          || !isScheduleEnabled
          || (isThrottleEnabled && formData.throttleRate < 1000)
        }
      >
        Create Now
      </Button>
    </ButtonGroup>
  </>
);

export default CreateMassNotificationThrottling;
