import Papa from 'papaparse';

export const jsonToCSV = (json, delimiter, columns) => Papa.unparse(json, {
  delimiter,
  columns,
});

export const usersJsonToCSV = (users, isTitleEnabled) => {
  const usersFormatted = users.map((user) => ({
    'Unique Id': user.oktaUserId,
    'First Name': user?.profile?.firstName,
    'Last Name': user?.profile?.lastName,
    Title: user?.profile?.title,
    'Last Login': user?.lastLoginTime,
    'Phone Number': user?.profile?.phoneNumber,
    'Office Line': user?.profile?.contactNumber,
    Email: user?.email,
    Roles: user?.roles?.join(', '),
    'Group Members': user?.groups?.join('; '),
    'Group Leads': user?.groupLeads?.join('; '),
  }));
  const columns = [
    'Unique Id',
    'First Name',
    'Last Name',
    ...isTitleEnabled ? ['Title'] : [],
    'Last Login',
    'Phone Number',
    'Office Line',
    'Email',
    'Roles',
    'Group Members',
    'Group Leads',
  ];
  const delimiter = ',';
  return jsonToCSV(usersFormatted, delimiter, columns);
};
