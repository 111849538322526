/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { OptInStatus } from '@app/constants/opt-in-status';
import {
  clearActiveJob,
  cleanWelcomeEmailRequest,
  resendWelcomeMail,
  sendSurvey,
  cleanSendSurvey,
  jobPutRequest as jobPutRequestAction,
  endActiveWorkflow,
} from '../../../actions/job';
import { toggleConfirmation as toggleConfirmationAction } from '../../../actions/ui';
import { usePrevious } from '../../Hooks/usePrevious';

import ChatHeader from './ChatHeader/chat-header';
import ChatBanner from './ChatBanner/chat-banner';
import MainChatModule from './ChatModule/MainChatModule';
import SendEmailConfirmation from '../../confirmationModals/SendEmailConfirmation/SendEmailConfirmation'; // eslint-disable-line
import selector from './selector';

import './Chat.less';
import {
  getChatParticipants,
  getScheduledMessages,
} from '../../../actions/chat';
import { updateEndUserOptStatus } from '../../../actions/users';

const Chat = (props) => {
  const {
    recipient,
    jobId,
    isInChatBotMode,
    // eslint-disable-next-line react/prop-types
    activeComponent,
    job,
    jobPutRequest,
    isLocked,
    isBlocked,
    isNotActivated,
    toggleConfirmation,
    endActiveWorkflow: endActiveWorkflowFn,
    updateEndUserOptStatus: updateEndUserOptStatusFn,
    lockingUserProgress,
    chatId,
    isInFNOLFlow,
    messages,
    getScheduledMessages: getScheduledMessagesFn,
    isEndingWorkflow,
  } = props;

  const ref = useRef(null);

  useEffect(() => {
    if (chatId) {
      getScheduledMessagesFn(chatId);
    }
  }, [getScheduledMessagesFn, chatId]);

  // If you happen to be looking at a chat when a scheduled message goes out on that chat
  const prevMessages = usePrevious(messages);
  useEffect(() => {
    if (chatId && prevMessages && !isEqual(prevMessages, messages)) {
      getScheduledMessagesFn(chatId);
    }
  }, [chatId, messages, prevMessages]);

  useEffect(() => {
    if (chatId) getChatParticipants(chatId);
  }, [chatId]);

  const showBanner = isLocked || isBlocked || isInChatBotMode || isNotActivated || isInFNOLFlow;
  const isUnlockingUser = lockingUserProgress[recipient?.id || recipient?._id] === true;

  const handleUnlock = () => {
    updateEndUserOptStatusFn({
      _id: recipient?.id || recipient?._id,
      jobId,
      chatId,
      status: OptInStatus.OptedIn,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      isUpdatingLockStatus: true,
    });
  };

  const handleEndActiveWorkflow = () => {
    endActiveWorkflowFn({
      jobId,
    });
  };

  return (
    <div ref={ref} className="chatComponent chatComponent-loaded">
      <ChatHeader />
      {showBanner && (
        <ChatBanner
          jobId={jobId}
          toggleConfirmation={toggleConfirmation}
          isInChatBotMode={isInChatBotMode}
          isNotActivated={isNotActivated}
          isLocked={isLocked}
          isBlocked={isBlocked}
          job={job}
          updateJob={jobPutRequest}
          updateEndUserOptStatus={handleUnlock}
          isUnlockingUser={isUnlockingUser}
          isInFNOLFlow={isInFNOLFlow}
        />
      )}
      <SendEmailConfirmation id="case" />
      <MainChatModule
        getScheduledMessages={getScheduledMessages}
        activeChatId={chatId}
        activeComponent={activeComponent}
        disabled={showBanner}
        isInChatBotMode={isInChatBotMode}
        isLocked={isLocked}
        endActiveWorkflow={handleEndActiveWorkflow}
        isInFNOLFlow={isInFNOLFlow}
        updateEndUserOptStatus={handleUnlock}
        isUnlockingUser={isUnlockingUser}
        chatRef={ref}
        isEndingWorkflow={isEndingWorkflow}
      />
    </div>
  );
};

Chat.propTypes = {
  jobId: PropTypes.string,
  chatId: PropTypes.string.isRequired,
  recipient: PropTypes.instanceOf(Object),
  messages: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isInChatBotMode: PropTypes.bool,
  job: PropTypes.instanceOf(Object).isRequired,
  jobPutRequest: PropTypes.func.isRequired,
  endActiveWorkflow: PropTypes.func,
  updateEndUserOptStatus: PropTypes.func,
  lockingUserProgress: PropTypes.shape({}),
  isInFNOLFlow: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  isBlocked: PropTypes.bool.isRequired,
  isNotActivated: PropTypes.bool.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
  getScheduledMessages: PropTypes.func.isRequired,
  isEndingWorkflow: PropTypes.bool.isRequired,
};

Chat.defaultProps = {
  jobId: undefined,
  messages: [],
  recipient: {},
  isInChatBotMode: false,
  endActiveWorkflow: () => { },
  updateEndUserOptStatus: () => { },
  lockingUserProgress: {},
};

const mapStateToProps = (state) => selector(state);
const mapDispatchToProps = {
  resendWelcomeMail,
  cleanWelcomeEmailRequest,
  sendSurvey,
  cleanSendSurvey,
  clearActiveJob,
  jobPutRequest: jobPutRequestAction,
  toggleConfirmation: toggleConfirmationAction,
  getChatParticipants,
  getScheduledMessages,
  endActiveWorkflow,
  updateEndUserOptStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
