import { getAccessToken } from './localStorage';

const secureRequest = async (url, options) => fetch(url, {
  ...options,
  headers: {
    ...options.headers,
    Authorization: (await getAccessToken()),
  },
  ...((options.body && typeof options.body === 'object') && { body: JSON.stringify(options.body) }),
});

export default secureRequest;
