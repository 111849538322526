import _ from 'lodash';
import React, { useState } from 'react';

import Menu from '@app/components/chakra/menu';

import { detectTimezone, timeZoneOptions } from '../Utils';

interface Reply {
  sendCriteria: {
    timeZone: string;
  };
}

interface TimezoneDropdownProps {
  reply: Reply;
  setReply: (r: Reply) => void;
}

const TimezoneDropdown: React.FC<TimezoneDropdownProps> = ({ reply, setReply }) => {
  const [timezone, setTimezone] = useState(
    _.has(reply, 'sendCriteria.timeZone')
      ? reply.sendCriteria.timeZone
      : 'EST/EDT',
  );
  const [defaultTimezone] = useState(
    detectTimezone()?.label,
  );
  const [isTimezoneDropdownOpen, setIsTimezoneDropdownOpen] = useState(false);

  const handleSelectTimeZone = (e: string) => {
    setTimezone(e);
    setIsTimezoneDropdownOpen(!isTimezoneDropdownOpen);
    setReply({
      ...reply,
      sendCriteria: {
        ...reply.sendCriteria,
        timeZone: e,
      },
    });
  };

  return (
    <div className="timezone-row">
      <Menu
        id="timezone-dropdown"
        options={timeZoneOptions}
        selectedId={timezone}
        defaultLabel={defaultTimezone}
        handleSelect={handleSelectTimeZone}
      />
    </div>
  );
};

export default TimezoneDropdown;
