import {
  Flex, Box, Spacer, Button, Icon,
} from '@chakra-ui/react';
import {
  SearchBar, AddCircleIcon, EditIcon, CheckmarksIcon, ConversationIcon,
} from '@himarley/unity';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';

import {
  setEditItem as setEditItemAction, setViewItem, lazyLoad, getItems,
} from '@app/actions/common';
import { subscribeToTopics as subscribeToTopicsAction, unsubscribeFromTopics as unsubscribeFromTopicsAction } from '@app/actions/socket';
import { sortItems as sortItemsAction, openCreateForm as openCreateFormAction, closeForm as closeFormAction } from '@app/actions/ui';
import CloseClaimConfirmation from '@app/components/confirmationModals/CloseClaimConfirmation/CloseClaimConfirmation';
// eslint-disable-next-line import/no-named-as-default
import SendEmailConfirmation from '@app/components/confirmationModals/SendEmailConfirmation/SendEmailConfirmation';
import SendSurveyConfirmation from '@app/components/confirmationModals/SendSurveyConfirmation/SendSurveyConfirmation';
import CreateCaseButton from '@app/components/CreateCase/create-case-button';
import DisabledBanner from '@app/components/elements/Banner/DisabledBanner/DisabledBanner';
import useTopicHandler from '@app/components/elements/hooks/useTopicHandler';
// eslint-disable-next-line import/no-named-as-default
import SortTable from '@app/components/elements/table/SortTable/SortTable';
import TableHeader from '@app/components/elements/table/SortTable/TableHeader/TableHeader';
import withLoading from '@app/components/HigherOrderComponents/withLoading';
import Panel from '@app/components/Panel/Panel';
import withLazyLoad from '@app/components/withLazyLoad/withLazyLoad';
import { DISABLE_CREATE_CASE } from '@app/constants/permissions';
import { formatDate } from '@app/helpers/datetime';
import { formatPhoneNumber } from '@app/helpers/format';
import { getAllOperatorsTopics } from '@app/helpers/liveUpdates';
import useSearchBar from '@app/hooks/search-bar';
import { policyType, userType, caseType } from '@app/models/marleyTypes';
import selector from '@app/selectors/case';
import { StateType } from '@app/types/reducer-state';

// eslint-disable-next-line import/no-named-as-default
import PolicyForm from './EnterPolicy/PolicyForm';
import PolicyRow from './policy-row';

import './Policies.less';

interface Value {
  effectiveDate: string;
  expirationDate: string;
}

export const columns = [
  { label: 'Policy Number', sort: false, location: 'metadata.038d0c94-d346-11ea-87d0-0242ac130003' },
  {
    label: 'Name', sort: false, location: ['companyName', 'customer.nameTableFormat'], sortLocation: ['companyName', 'customer.nameTableFormat'],
  },
  {
    label: 'Phone Number',
    sort: false,
    location: 'customer.phoneNumber',
    format: ({ value }: { value: Value }) => (
      <div>{formatPhoneNumber(value)}</div>
    ),
    numericSort: true,
  },
  {
    label: 'Deductible', sort: false, location: 'metadata.4c7a89a2-8ea1-4984-b5d4-43a05c1a4983', numericSort: true,
  },
  // find how the policy object is being traversed
  {
    label: 'Contract Term',
    sort: false,
    location: 'contractTerm',
    format: ({ value }: { value: Value }) => (_.get(value, 'effectiveDate', false) && _.get(value, 'expirationDate', false) ? (
      <div>
        {formatDate(value.effectiveDate, 'just-date')}
        {' '}
        -
        {' '}
        {formatDate(value.expirationDate, 'just-date')}
      </div>
    ) : (<div />)), // correct but it doesn't understand how to process the array.
  },
  {
    label: 'Date Created',
    sort: false,
    location: 'createdAt',
    format: ({ value }: { value: Value }) => (<div>{value && formatDate(value, 'just-date')}</div>), // eslint-disable-line
    numericSort: true,
    sortType: 'date',
  },
  {
    id: 'assigned', label: 'Assigned', class: 'table-col-assigned', sort: false, location: 'operator', sortLocation: 'operatorSortValue',
  },
  { label: 'Actions', id: 'actions' },
];

const modalTitle = (type: { title: () => string }, mode: string) => {
  const title = type.title();
  if (mode === 'create') {
    return `Create ${title}`;
  } if (mode === 'edit') {
    return `Edit ${title}`;
  }
  return `${title} Information`;
};

export const mapDispatchToProps = {
  closeForm: closeFormAction,
  openCreateForm: openCreateFormAction,
  setEditItem: setEditItemAction,
  setViewItem,
  sortItems: sortItemsAction,
  lazyLoad,
  getItems,
  subscribeToTopics: subscribeToTopicsAction,
  unsubscribeFromTopics: unsubscribeFromTopicsAction,
};

export const mapStateToProps = (state: StateType) => selector(state);

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type PoliciesProps = PropsFromRedux & {
  form: { mode: string };
  operators: { id: string; label: string }[];
  policies: unknown[];
  users: unknown;
  organization: unknown;
  permissions: string[];
  isLoading: boolean;
  increaseDataSet: () => void;
};

const Policies: React.FC<PoliciesProps> = ({
  isLoading,
  sortItems,
  policies = [],
  setEditItem,
  increaseDataSet,
  openCreateForm,
  closeForm,
  form = { mode: 'isCreating' },
  subscribeToTopics,
  unsubscribeFromTopics,
  organization,
  permissions,
}) => {
  const memoizedTopicHandler = useTopicHandler();
  const [searchFilter, setSearchFilter] = useSearchBar(caseType.id());

  useEffect(() => {
    sortItems(caseType.stateLocation(), 'dateCreated', 'DESC');
    subscribeToTopics(getAllOperatorsTopics(organization._id), memoizedTopicHandler);
    return () => {
      unsubscribeFromTopics(getAllOperatorsTopics(organization._id));
    };
  }, []);

  const policyRows = columns && columns.map((col) => (
    <TableHeader
      tableId="policies"
      key={col.id || col.label}
      type={undefined}
      className={undefined}
      numericSort={col.numericSort}
      sortLocation={col.sortLocation}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...col}
    />
  ));

  const isCreationDisabled = permissions.find((v: string) => v === DISABLE_CREATE_CASE);
  const showModal = form && (form.isEditing
    || form.isCreating
    || form.isViewing);

  const createCaseButton = process.env.CREATE_CASE_FORM_REFACTOR_ENABLED
    ? <CreateCaseButton buttonText="Create Policy" /> : (
      <Button
        leftIcon={<Icon as={AddCircleIcon} />}
        onClick={() => openCreateForm(caseType)}
      >
        Create Policy
      </Button>
    );

  return (
    <Panel
      className="policies-page"
      header={(
        <Flex>
          <Box>
            {isCreationDisabled
              ? (
                <DisabledBanner
                  bannerText="Creating a policy is unavailable for your organization. Please go to your claims management system to create a new policy."
                  includesLink={undefined}
                  linkRef={undefined}
                  linkText={undefined}
                />
              )
              : createCaseButton}
          </Box>
          <Spacer />
          <Box>
            <SearchBar
              id="policies"
              className="policies-search-bar"
              placeholder="Search policies"
              value={searchFilter}
              onValueChange={setSearchFilter}
              onClear={() => setSearchFilter('')}
            />
          </Box>
        </Flex>
      )}
      title="Policies"
      titleFooter={undefined}
      titleNotification={undefined}
      right={undefined}
    >
      <Modal
        show={showModal}
        dialogClassName="policies-page-modal"
        onHide={
          () => {
            closeForm(caseType);
            closeForm(userType);
          }
        }
      >
        <div className="header-modal">
          <div data-testid="create-policy-modal" className="header">{modalTitle(policyType, _.get(form, 'mode'))}</div>
          <button
            className="cls-btn"
            type="button"
            aria-label="Close"
            onClick={
              () => {
                closeForm(caseType);
                closeForm(userType);
              }
            }
          />
        </div>
        <div className="body-modal">
          <PolicyForm />
        </div>
      </Modal>

      <SendEmailConfirmation id="job" />
      <CloseClaimConfirmation />
      <SendSurveyConfirmation id="job" />

      <SortTable
        id="policy"
        label="policies"
        columns={policyRows}
        increaseDataSet={increaseDataSet}
        isLoading={isLoading}
        rows={policies && policies.map((policy) => {
          const actions = [
            {
              id: 'edit',
              label: 'Edit Policy',
              action: (id: string) => {
                setEditItem(caseType, id);
                const foundPolicy = policies.find((p) => p.id === id) || {};
                if (foundPolicy?.customer) setEditItem(userType, foundPolicy?.customer);
                setEditItem(policyType, id);
              },
              leftIcon: EditIcon,
            }, {
              id: 'survey',
              label: 'Send Survey',
              confirmation: true,
              leftIcon: CheckmarksIcon,
              isDisabled: !policy.customer?.verified,
            }, {
              id: 'resendwelcome',
              label: 'Resend Opt-In Text',
              confirmation: true,
              leftIcon: ConversationIcon,
              isDisabled: policy.customer?.verified,
            },
          ];
          return (
            <PolicyRow
              key={policy.id}
              item={policy}
              actions={actions}
              columns={columns}
            />
          );
        })}
      />
    </Panel>
  );
};

export { Policies };

const PoliciesWithLoading = withLoading(Policies, { type: caseType });
const LoadedPolicies = withLazyLoad(
  PoliciesWithLoading,
  {
    type: caseType,
    listLocation: 'jobs',
    defaultSort: {
      column: 'createdAt',
    },
    query: {
      caseTypeId: ['11f43fa7-df90-4da2-92b9-caae0d215323', 'badf9200-a0e0-48ae-ae7c-c51c97e9f14b'],
    },
  },
);

export default connector(LoadedPolicies);
