/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ChatModule from './chat-module';

const MainChatModule = ({
  activeChatId,
  disabled,
  isInFNOLFlow,
  isInChatBotMode,
  getScheduledMessages,
  isLocked,
  endActiveWorkflow,
  updateEndUserOptStatus,
  isUnlockingUser,
  chatRef,
  isEndingWorkflow,
}) => {
  useEffect(() => {
    if (getScheduledMessages && activeChatId) getScheduledMessages(activeChatId);
  }, [activeChatId, getScheduledMessages]);
  return (
    <ChatModule
      disabled={disabled}
      chatLocation="activeChat"
      isInChatBotMode={isInChatBotMode}
      isLocked={isLocked}
      endActiveWorkflow={endActiveWorkflow}
      isInFNOLFlow={isInFNOLFlow}
      updateEndUserOptStatus={updateEndUserOptStatus}
      isUnlockingUser={isUnlockingUser}
      chatRef={chatRef}
      isEndingWorkflow={isEndingWorkflow}
    />
  );
};
MainChatModule.propTypes = {
  activeChatId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isInChatBotMode: PropTypes.bool.isRequired,
  isInFNOLFlow: PropTypes.bool.isRequired,
  getScheduledMessages: PropTypes.func.isRequired,
  isLocked: PropTypes.bool.isRequired,
  endActiveWorkflow: PropTypes.func.isRequired,
  updateEndUserOptStatus: PropTypes.func.isRequired,
  isUnlockingUser: PropTypes.bool.isRequired,
  chatRef: PropTypes.instanceOf(Object).isRequired,
  isEndingWorkflow: PropTypes.bool.isRequired,
};

export default MainChatModule;
