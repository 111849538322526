import React from 'react';
import { useSelector } from 'react-redux';

import LobIcon from '@app/components/Settings/CompanyHierarchy/lob-icon';
import UserPhoto2 from '@app/components/UserPhoto2/UserPhoto2';
import { capitalizeEachWord } from '@app/helpers/format';
import { StateType } from '@app/types/reducer-state';

import './ChatHeader.less';

import chatModuleSelector from '../selector';

const ChatHeader = () => {
  const { assignedOperator } = useSelector(chatModuleSelector);

  const {
    jobNumber,
    lineOfBusiness,
    recipient,
  } = useSelector(
    (state: StateType) => ({
      jobNumber: state?.jobs?.activeJob?.displayId || '',
      lineOfBusiness: state?.jobs?.activeJob?.lineOfBusiness,
      recipient: state?.jobs?.activeJob?.customer || {},
    }),
  );

  return (
    <div className="chat-component-header">
      <div className="chat-component-inner">
        <div className="chat-component-avatar">
          {lineOfBusiness?.subType ? (
            <LobIcon
              name={lineOfBusiness?.subType}
              className="line-of-business-icon"
            />
          ) : (
            <UserPhoto2
              id={recipient.id}
              imageUrl={recipient.imageUrl}
              name={recipient.name}
              className="header-user-photo"
              isRecipient
              isCustomer={false}
              isFinishedLoading
              testId="chat-header-user-photo"
            />
          )}
        </div>
        <div className="chat-component-text-wrap">
          <div className="chat-component-text-name" data-testid="chat-component-text-name">{recipient.name}</div>
          <div
            className="chat-component-text-id"
            data-testid="chat-component-text-id"
          >
            {lineOfBusiness?.displayName
              ? `${capitalizeEachWord(lineOfBusiness?.displayName)}:`
              : 'ID'}
            {' '}
            {jobNumber}
            {' '}
            -
            {' '}
            <span className="chat-component-assigned-header" data-testid="assignedOperatorName">
              {assignedOperator
                ? `Assigned to ${assignedOperator}`
                : 'Unassigned'}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
