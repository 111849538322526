import React, { useState } from 'react';

import Menu from '@app/components/chakra/menu';

import './TimeOutDropdown.less';

interface Reply {
  sendCriteria: {
    minutesInactiveThreshold: number;
  };
}

interface TimeOutDropdownProps {
  reply: Reply;
  setReply: (r: Reply) => void;
}

const TimeOutDropdown: React.FC<TimeOutDropdownProps> = ({ reply, setReply }) => {
  const [timeout, setTimeoutValue] = useState(reply?.sendCriteria?.minutesInactiveThreshold.toString() || '120');
  const [isTimeoutDropdownOpen, setIsTimeoutDropdownOpen] = useState(false);
  const timeoutOptions = [
    { id: '120', label: '2 hours' },
    { id: '90', label: '1.5 hours' },
    { id: '60', label: '1 hour' },
    { id: '45', label: '45 minutes' },
    { id: '30', label: '30 minutes' },
    { id: '20', label: '20 minutes' },
    { id: '15', label: '15 minutes' },
    { id: '10', label: '10 minutes' },
    { id: '1', label: '1 minute' },
  ];

  const handleSelectTimeOut = (e: string) => {
    setTimeoutValue(e);
    setIsTimeoutDropdownOpen(!isTimeoutDropdownOpen);
    setReply({
      ...reply,
      sendCriteria: {
        ...reply.sendCriteria,
        minutesInactiveThreshold: parseInt(e, 10),
      },
    });
  };

  return (
    <div className="timeout-container">
      <Menu
        id="timeout-dropdown"
        options={timeoutOptions}
        selectedId={timeout}
        handleSelect={handleSelectTimeOut}
      />
    </div>
  );
};

export default TimeOutDropdown;
