import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Spinner,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  hideMassNotificationModal,
} from '@app/actions/massNotifications';

import './MassNotificationModal.less';

import CreateMassNotificationForm from '../CreateMassNotificationForm/create-mass-notification-form';

interface MassNotificationModalProps {
  show: boolean;
  onHide: () => void;
}

const MassNotificationModal: React.FC<MassNotificationModalProps> = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const modalLoading = useSelector((state) => state.massNotifications.modalLoading);

  const handleClickHideModal = () => {
    onHide();
    dispatch(hideMassNotificationModal());
  };

  return (
    <Modal
      isOpen={show}
      onClose={handleClickHideModal}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent className="mass-notification-creation-modal">
        <ModalHeader>
          New Notification
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <CreateMassNotificationForm onHide={onHide} />
          <div className={`marleySpinner flexCenter ${modalLoading ? '' : 'hidden'}`}>
            <Spinner
              size="xl"
              emptyColor="gray.50"
              speed="0.75s"
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MassNotificationModal;
