// created in order to avoid circular dependency error

export const OptInStatus = {
  PendingOptIn: 'PENDING_OPT_IN', // verified = false, blocked false, locked false
  OptedIn: 'OPTED_IN', // verified = true, blocked false, locked false
  Unsubscribed: 'UNSUBSCRIBED', // blocked = true
  LockedOut: 'LOCKED_OUT', // locked = true
};

export const userOptInStatus = (endUser: {
  blocked: boolean;
  locked: boolean;
  verified: boolean;
}) => {
  if (endUser.blocked) {
    return OptInStatus.Unsubscribed;
  }
  if (endUser.locked) {
    return OptInStatus.LockedOut;
  }
  // verified = true, blocked false, locked false
  if (endUser.verified) {
    return OptInStatus.OptedIn;
  }
  // verified = false, blocked false, locked false
  return OptInStatus.PendingOptIn;
};
