import React from 'react';

import Modal, { useModal } from '@app/components/chakra/modal';

import OfficeHoursModal from './OfficeHoursModal/OfficeHoursModal';
import ReplyActions from '../SharedComponents/ReplyRowActions/ReplyActions';
import ReplyMessage from '../SharedComponents/ReplyRowMessage/ReplyMessage';
import { type Reply } from '../types';

interface OfficeHoursRowProps {
  userId: string;
  reply: Reply;
}

const OfficeHoursRow: React.FC<OfficeHoursRowProps> = ({ userId, reply }) => {
  const { isModalOpen, toggleModal } = useModal();

  return (
    <>
      <div className="auto-reply-row">
        <ReplyActions openEditModal={toggleModal} userId={userId} reply={reply} />
        <ReplyMessage message={reply?.message?.after?.value ?? ''} />
      </div>
      {isModalOpen ? (
        <Modal
          className="create-ooo-modal"
          data-test="create-ooo-modal"
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
        >
          <OfficeHoursModal
            pageProps={{ reply, userId }}
            toggleModal={toggleModal}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default OfficeHoursRow;
