import { createAsyncThunk } from '@reduxjs/toolkit';

import { COMPLETE_RELEASE_ISSUES } from '../constants/endpoints';
import secureRequest from '../helpers/secure-request';

const completeReleaseIssues = createAsyncThunk(
  'jobs/completeReleaseIssues',
  async ({ jobId, data }: { jobId: string; data: unknown }) => {
    const args = {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    };
    const response = await secureRequest(COMPLETE_RELEASE_ISSUES(jobId), args);
    if (response.ok) {
      const json = await response.json();
      return json;
    }

    throw new Error('Failed to complete release issues');
  },
);

export default completeReleaseIssues;
