import { Button, Icon } from '@chakra-ui/react';
import { AddCircleIcon } from '@himarley/unity';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import CreateCaseModal from './create-case-modal';

interface CreateCaseButtonProps {
  buttonText?: string;
  size?: 'sm' | 'md' | 'lg';
}

const CreateCaseButton = ({ buttonText, size = 'md' }: CreateCaseButtonProps) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      <Button
        data-testid="create-case-button"
        leftIcon={<Icon as={AddCircleIcon} />}
        size={size}
        onClick={() => {
          history.push(`${location.pathname}/create`);
        }}
      >
        {buttonText || 'Create Case'}
      </Button>
      {location.pathname.includes('create') && (
        <CreateCaseModal />
      )}
    </>
  );
};

export default CreateCaseButton;
