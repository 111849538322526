/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Menu, MenuButton, MenuList, Box, VStack, Text, Input, MenuItem, Button,
  Flex,
} from '@chakra-ui/react';
import { CheckmarkIcon, CloseIcon } from '@himarley/unity';
import classnames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';

import searchableDropdownStyles from './searchable-dropdown.module.less';

interface SearchableDropdownProps {
  items?: Array<{ id: string; label?: string, avatar?: React.ReactNode }>;
  selectedItemsMap: Map<string, string>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Map<string, string>>>;
  handleSearch: (searchText: string) => void;
  disabled?: boolean;
  defaultLabel: string;
  entityLabel: string;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  items,
  selectedItemsMap,
  setSelectedItems,
  handleSearch,
  disabled,
  defaultLabel,
  entityLabel,
}) => {
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      handleSearch(searchText);
    }, 500); // half a second delay

    return () => {
      clearTimeout(handler);
    };
  }, [searchText, handleSearch]);

  const handleSelectItem = (item: { id: string; label?: string }) => {
    const newSelectedItems = new Map(selectedItemsMap);
    newSelectedItems.has(item.id)
      ? newSelectedItems.delete(item.id)
      : newSelectedItems.set(item.id, item.label ?? '');
    setSelectedItems(newSelectedItems);
  };

  const getDisplayLabel = () => {
    const selectedCount = selectedItemsMap.size;

    if (selectedCount === 0) {
      return defaultLabel;
    } if (selectedCount === 1) {
      return Array.from(selectedItemsMap.values())[0];
    }
    return `${selectedCount} ${entityLabel} Selected`;
  };

  const getButtonClass = () => {
    if (disabled) {
      return searchableDropdownStyles.disabledTriggerButton;
    }
    return selectedItemsMap.size === 0
      ? searchableDropdownStyles.unselectedTriggerButton
      : searchableDropdownStyles.selectedTriggerButton;
  };

  const handleClearSelection = () => {
    // Clear all selected items
    setSelectedItems(new Map());
  };

  const menuButtonClasses = classnames(searchableDropdownStyles.name, {
    [searchableDropdownStyles.disabledLabel]: disabled,
    [searchableDropdownStyles.unselectedLabel]: !disabled && selectedItemsMap.size === 0,
    [searchableDropdownStyles.selectedLabel]: !disabled && selectedItemsMap.size > 0,
  });

  return (
    <Menu closeOnSelect={false}>
      <Flex
        className={getButtonClass()}
      >
        <MenuButton
          as={Text}
          flex="1"
          cursor="pointer"
          disabled={disabled}
          className={menuButtonClasses}
        >
          {getDisplayLabel()}
        </MenuButton>
        {selectedItemsMap.size !== 0 ? (
          <Button
            onClick={handleClearSelection}
            disabled={disabled}
            bg="transparent"
            border="none"
            boxShadow="none"
            cursor="pointer"
            height="auto"
            sx={{
              _hover: {
                bg: 'transparent',
              },
            }}
          >
            <CloseIcon />
          </Button>
        ) : null}
      </Flex>
      <MenuList width="304px">
        <Box p={2}>
          <Input
            ref={inputRef}
            placeholder="Search..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            autoFocus
          />
        </Box>
        <Box
          maxH="50vh"
          overflowY="auto"
        >
          <VStack
            align="start"
            spacing={1}
          >
            {items?.map((item) => (
              <MenuItem
                key={item.id}
                onClick={() => handleSelectItem(item)}
                icon={React.isValidElement(item.avatar) ? item.avatar : undefined}
                isFocusable={false} // Prevent automatic focus
              >
                <Flex justify="space-between" align="center" width="100%">
                  {item.label}
                  {selectedItemsMap.has(item.id) && <CheckmarkIcon />}
                </Flex>
              </MenuItem>
            ))}
          </VStack>
        </Box>
      </MenuList>
    </Menu>
  );
};

export default SearchableDropdown;
