import {
  FormLabel,
  FormControl,
  Icon,
} from '@chakra-ui/react';
import { CloseIcon } from '@himarley/unity';
import React from 'react';
import DatePicker from 'react-bootstrap-date-picker';
import { useFormContext, type RegisterOptions } from 'react-hook-form';

import { type IFormInput } from '@app/types/create-case';

// Create a styles object for the FormControl wrapper
// This DatePicker is still bootstrap, which I am planning on replacing very soon,
// so this is a temporary fix to get the styling looking good and chakra-y.
const formControlStyles = {
  '&:hover .form-control': {
    borderColor: 'gray.300',
  },
  '.form-control': {
    zIndex: 'unset',
    borderColor: 'gray.200',
    transition: 'all 0.2s',
    '&:focus': {
      borderColor: 'blue.500',
      borderWidth: '2px',
      boxShadow: '0 0 0 1px blue.500',
      zIndex: 1,
    },
    '&::placeholder': {
      color: 'gray.500',
    },
  },
  '.input-group-addon': {
    background: 'transparent',
    padding: '0 8px',
    button: {
      background: 'transparent',
      padding: '0',
      height: '24px',
      width: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    svg: {
      width: '16px',
      height: '16px',
    },
  },
  '.input-group': {
    borderColor: 'gray.200',
    transition: 'all 0.2s',
  },
};

interface DateFieldProps {
  name: keyof IFormInput;
  label: string;
  dateLimit?: 'past' | 'future';
  registerOptions?: RegisterOptions<IFormInput>,
}

const DateField = ({
  registerOptions,
  name,
  label,
  dateLimit = 'past',
}: DateFieldProps) => {
  const {
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<IFormInput>();

  register(name, registerOptions);

  const isRequired = registerOptions?.required;

  const handleChange = (value: string) => {
    setValue(name, value, { shouldValidate: true });
    trigger(name);
  };

  return (
    <FormControl
      id={name}
      isInvalid={!!errors[name]}
      sx={formControlStyles}
    >
      <FormLabel
        size="sm"
        cursor="default"
      >
        {`${label}${isRequired ? ' (Required)' : ''}`}
      </FormLabel>
      <DatePicker
        value={getValues(name)}
        calendarPlacement="top"
        style={{
          boxShadow: 'none',
          height: '40px',
        }}
        maxDate={dateLimit === 'past' ? new Date().toString() : undefined}
        onChange={handleChange}
        clearButtonElement={<Icon as={CloseIcon} />}
        showClearButton
      />
    </FormControl>
  );
};

export default DateField;
