import { defineStyleConfig } from '@chakra-ui/react';

export const linkTheme = defineStyleConfig({
  baseStyle: {
    textDecoration: 'underline',
    color: 'blue.500',
  },
});

export default linkTheme;
