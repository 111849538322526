import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';

import DateField from './fields/date-field';
import GenericTextField from './fields/generic-text-field';
import SharedForm from './shared-form';

const ClaimForm = () => (
  <Grid width="100%" templateColumns={{ sm: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))' }} gap={4}>
    <SharedForm />
    <GridItem>
      <GenericTextField
        label="Claim Number"
        name="claimNumber"
        registerOptions={{ required: { value: true, message: 'Claim Number is Required' } }}
      />
    </GridItem>
    <GridItem>
      <GenericTextField
        label="Claim Status"
        name="claimStatus"
      />
    </GridItem>
    <GridItem colSpan={2}>
      <GenericTextField
        label="Policy Number"
        name="policyNumber"
      />
    </GridItem>
    <GridItem>
      <DateField
        name="dateOfLoss"
        label="Date of Loss"
      />
    </GridItem>
    <GridItem>
      <GenericTextField
        label="Deductible"
        name="deductible"
        leftElement="$"
      />
    </GridItem>
  </Grid>
);

export default ClaimForm;
