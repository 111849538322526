import { formatPhoneNumberV2 } from '../../helpers/format';

import {
  COPART_SELLER_PORTAL,
  COPART_DATA,
  VERISK_DATA,
  XACT_PORTAL,
} from '../../constants/integration-constants';

const formatTotalLossAddress = (addressData) => {
  const {
    companyName = '',
    yardName = '',
    contactPhone = '',
    yardPhoneNumber = '',
    addressLine1 = '',
    addressLine2 = '',
    city = '',
    state = '',
    zipCode = '',
    type = '',
  } = addressData;
  const name = companyName || yardName;
  const addressPhone = contactPhone || yardPhoneNumber;
  const streetAddress = `${addressLine1} ${addressLine2}`;
  const cityStateZip = `${city}, ${state && state.concat(' ')}${zipCode}`;
  const locationType = type;
  return {
    ...locationType && { locationType: `Vehicle at ${locationType}` },
    name,
    fullAddress: `${streetAddress}, ${cityStateZip}`,
    addressPhone: formatPhoneNumberV2(addressPhone),
  };
};

const buildCopartData = (totalLossData) => {
  const {
    vehicleLocation = {},
    yardLocation = {},
    ownerDetails = {},
    vehicleDetails = {},
    stockNumber = '',
    titleExpressUrl = '',
  } = totalLossData;

  const { firstName = '', lastName = '', phoneNumber = '' } = ownerDetails;
  const {
    year = '',
    color = '',
    model = '',
    make = '',
    licensePlateNumber = '',
  } = vehicleDetails;

  const ownerInfo = {
    fullName: `${firstName} ${lastName}`,
    phoneNumber: formatPhoneNumberV2(phoneNumber),
  };

  const vehicleInfo = {
    makeDetail: `${make} ${model} ${year} ${color} ${licensePlateNumber}`,
  };

  const vehicleLocationInfo = formatTotalLossAddress(vehicleLocation);
  const yardLocationInfo = formatTotalLossAddress(yardLocation);

  const sellerPortalLink = {
    link: {
      url: `${COPART_SELLER_PORTAL}${stockNumber}`,
      text: 'Copart Seller Portal',
    },
  };

  const titleExpressLink = {
    link: {
      url: titleExpressUrl,
      text: 'Title Express Portal',
    },
  };
  const ownerInfoData = Object.entries(ownerDetails).length && ownerInfo;
  const vehicleInfoData = Object.entries(vehicleDetails).length && vehicleInfo;
  const vehicleLocationData = Object.entries(vehicleLocation).length && vehicleLocationInfo;
  const yardLocationData = Object.entries(yardLocation).length && yardLocationInfo;
  const sellerPortalLinkData = stockNumber && sellerPortalLink;
  const titleExpressLinkData = titleExpressUrl && titleExpressLink;

  const copartDataSet = [
    sellerPortalLinkData && {
      title: 'Seller Portal',
      key: 'sellerPortalLink',
      data: sellerPortalLink,
    },
    titleExpressLinkData && {
      title: 'Title Express',
      key: 'titleExpressLink',
      data: titleExpressLink,
    },
    ownerInfoData && {
      title: 'Vehicle Owner',
      key: 'ownerInfo',
      data: ownerInfo,
    },
    vehicleInfoData && {
      title: 'Vehicle Info',
      key: 'vehicleInfo',
      data: vehicleInfoData,
    },
    vehicleLocationData && {
      title: 'Vehicle Location',
      key: 'vehicleLocation',
      data: vehicleLocationData,
    },
    yardLocationData && {
      title: 'Yard Info',
      key: 'yardLocation',
      data: yardLocationData,
    },
  ].filter(Boolean);
  return copartDataSet;
};

const getAssignedParties = (adjuster, isIndependentAdjuster) => {
  if (!Object.entries(adjuster).length) return null;
  const { name, email, phoneNumber } = adjuster;
  const title = isIndependentAdjuster ? 'Field Adjuster' : 'Desk Adjuster';
  return {
    title,
    name,
    email,
    phoneNumber: formatPhoneNumberV2(phoneNumber),
  };
};

const buildVeriskData = (veriskData) => {
  const {
    inspectionAddress = '',
    isIndependentAdjuster = false,
    originalAssignmentId,
    adjuster = {},
    damageType = '',
  } = veriskData;
  const xactPoratlLink = {
    link: {
      url: XACT_PORTAL(originalAssignmentId),
      text: 'XactAnalysis Assignment',
    },
  };
  const assignedParties = getAssignedParties(adjuster, isIndependentAdjuster);
  const veriskDataSet = [
    {
      title: 'XactAnalysis',
      key: 'xactAnalysis',
      data: xactPoratlLink,
    },
    inspectionAddress && {
      title: 'Site Location',
      key: 'siteLocation',
      data: { inspectionAddress },
    },
    damageType && {
      title: 'Type of Loss',
      key: 'typeOfLoss',
      data: { damageType },
    },
    assignedParties && {
      title: 'Assigned Parties',
      key: 'assignedParties',
      data: assignedParties,
    },
  ].filter(Boolean);
  return veriskDataSet;
};

const buildTrayData = (data, type) => {
  switch (type) {
    case COPART_DATA:
      return buildCopartData(data);
    case VERISK_DATA:
      return buildVeriskData(data);
    default:
      return null;
  }
};

export default buildTrayData;
