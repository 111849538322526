import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { sendSurvey } from '../../../actions/job';
import { toggleConfirmation } from '../../../actions/ui';

import ConfirmationModal from "../ConfirmationModal/ConfirmationModal"; // eslint-disable-line
import { pluralize } from '../../../helpers/common';
import './SendSurveyConfirmation.less';

const defaultState = { selectedId: 'marley' };

class SendSurveyConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  handleSubmit() {
    const { item, sendSurvey: sendSurveyFn, operatorId } = this.props;
    if (operatorId && operatorId !== 'unassigned') {
      sendSurveyFn(item);
    }
  }

  render() {
    const {
      insuredId, toggleConfirmation: toggleConfirmationFn,
    } = this.props;

    const sendButtonStates = {
      all: {
        className: 'confirmationButton submitConfirmation',
      },
      preRequest: {
        type: 'primary',
        onClick: () => {
          if (insuredId) {
            this.handleSubmit();
          }
        },
        text: 'Send',
      },
      requesting: {
        type: 'secondary',
        onClick: () => {},
        text: 'Sending...',
      },
    };

    const cancelButtonStates = {
      all: {
        className: 'confirmationButton closeConfirmation',
      },
      preRequest: {
        type: 'default',
        text: 'Cancel',
      },
      requesting: {
        type: 'default',
        text: 'Cancel',
      },
    };

    return (
      <ConfirmationModal
        className="send-survey-confirmation"
        id="survey"
        title="Send Survey?"
        successText="Survey sent!"
        open={() => {
          if (insuredId) {
            this.handleSubmit();
          }
          this.setState(defaultState);
          toggleConfirmationFn({
            id: 'survey',
            isOpen: false,
          });
        }}
        openButtonStates={sendButtonStates}
        cancelButtonStates={cancelButtonStates}
      >
        This will send the user a Hi Marley satisfaction survey.
      </ConfirmationModal>
    );
  }
}

SendSurveyConfirmation.propTypes = {
  sendSurvey: PropTypes.func.isRequired,
  item: PropTypes.shape({}).isRequired,
  insuredId: PropTypes.string.isRequired,
  operatorId: PropTypes.string.isRequired,
  toggleConfirmation: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.id === 'case' ? 'job' : ownProps.id;

  const listName = 'listById';
  const process = _.get(state, 'ui.requests.sendSurvey', {});

  const selectedId = _.get(state, 'ui.confirmationIsOpen.survey.selectedId');
  const item = _.get(state, `${pluralize(id)}.${listName}.${selectedId}`, {});
  const caseTypeId = _.get(item, 'caseTypeId');
  const caseType = (state.jobs?.caseTypes || []).find(
    (ct) => ct.id === caseTypeId,
  );

  const props = {
    item,
    caseType,
    insuredId: _.get(item, 'customer.id'),
    accountId: _.get(item, 'accountId'),
    jobId: _.get(item, 'jobId') || _.get(item, 'id'),
    operatorId: _.get(item, 'operatorIds.0'),
    process,
  };

  return props;
};

export default connect(mapStateToProps, { sendSurvey, toggleConfirmation })(
  SendSurveyConfirmation,
);
